import '../css/loader.css';
import logo from '../assets/logo_fav.png';

const LoaderSingle = () => { 
    return (
        <div className="div-loader" >
            <img className='loader' src={logo} />
        </div>
    )
}

export default LoaderSingle;