import { Paper, Typography, Button } from '@mui/material';
import FormDynamic from './FormDynamic';
import DataListDynamic from '../components/DataListDynamic';
import { useProviderBilling, useProvider } from '../hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { sortData } from '../plugins/utils';

const Form = ({ providerBillingsData, providerID, cancel }) => {
	const ID = providerBillingsData?.id;
	const {
		formInputs: inputs,
		createProviderBillings,
		updateProviderBillings,
	} = useProviderBilling({ ID });

	const { resetList } = useProvider({});

	const formProps = {
		validateFunction: () => {},
		inputs,
		defaultValues: {
			name: '',
			rut: '',
			address: '',
			telephon: '',
			email: '',
			giro: '',
			region: '',
			commune: '',
		},
		cancelFunction: cancel,
	};

	const formPropsNew = {
		...formProps,
		submitFunction: data => {
			const toSend = { ...data, provider: providerID };
			createProviderBillings(toSend).then(() => {
				/* Se refresca proveedor */
				resetList();
				cancel();
			});
		},
		buttonText: 'Crear',
	};

	const formPropsEdit = {
		...formProps,
		submitFunction: data => {
			const toSend = { ...data, provider: providerID };
			updateProviderBillings(ID, toSend).then(() => {
				/* Se refresca proveedor */
				resetList();
				cancel();
			});
		},
		defaultValues: providerBillingsData,
		buttonText: 'Actualizar',
	};

	if (ID && formPropsEdit?.defaultValues?.id)
		return <FormDynamic cancelFunction={cancel} {...formPropsEdit} />;
	if (!ID) return <FormDynamic cancelFunction={cancel} {...formPropsNew} />;

	return 'Cargando datos';
};

/* Configurando responsividad de la tabla. Siempre trabajar en base a 11 porque 1 está destinado al boton eliminar. */
const widthCells = { description: { xs: 10 } };
const rowTitle = {
	description: (
		<Typography variant="subtitle2" component="div">
			Detalles
		</Typography>
	),
};

const ProviderDetailsBankData = ({ providerData }) => {
	const [providerBillings, setProviderBillings] = useState('');
	const { resetList } = useProvider({});
	const { deleteProviderBillings } = useProviderBilling({ ID: 'new' }); //Se coloca new para no se ejecute la solicitud de un listado, ni se ejecute la lectura de un data bancario
	const cancelForm = () => setProviderBillings('');


	const rowsData = [{
		id: providerData.provider_billing_information?.id,
		description: (
			<>
				RUT: {providerData.provider_billing_information?.rut}
				<br />
				Nombre / Razón Social: {providerData.provider_billing_information?.name}
				<br />
				Giro: {providerData.provider_billing_information?.giro}
				<br />
				Región: {providerData.provider_billing_information?.region}
				<br />
				Comuna: {providerData.provider_billing_information?.commune}
				<br />
				Dirección: {providerData.provider_billing_information?.address}
				<br />
				Email: {providerData.provider_billing_information?.email}
				<br />
				Teléfono: {providerData.provider_billing_information?.telephon}
			</>
		),
		inactive: false,
		onClick: () => setProviderBillings(providerData.provider_billing_information),
		}];

	return (
		<Paper variant="outlined" sx={{ padding: '16px' }}>
			{providerBillings === 'new' ? (
				<>
					<Typography variant="h6" component="h2">
						Asignando datos de facturación
					</Typography>
					<Form cancel={cancelForm} providerID={providerData.id} />
				</>
			) : !['', 'new'].includes(providerBillings) ? (
				<>
					<Typography variant="h6" component="h2">
						Actualizando datos de facturación
					</Typography>
					<Form
						cancel={cancelForm}
						providerID={providerData.id}
						providerBillingsData={providerBillings}
					/>
				</>
			) : (
				<>
					<Typography variant="h6" component="h2">
						Datos de facturación
					</Typography>
					{!providerData.provider_billing_information?.id &&(
					<Button
						type="button"
						fullWidth
						variant="outlined"
						sx={{ mt: '16px', mb: 2 }}
						onClick={() => setProviderBillings('new')}
					>
						Agregar
					</Button>)}
					{providerData.provider_billing_information?.id && (
						<DataListDynamic
							{...{ rowTitle, rowsData, widthCells }}
							deleteFunction={null}
						/>
					)}
				</>
			)}
		</Paper>
	);
};

export default ProviderDetailsBankData;
