import lodash from 'lodash';
import api from '../../plugins/api';
import { mat, asyncMac } from '../utils';

/* Datos generales de configuración */
const asyncConfigurations = mat('configurations');
const [setPending, setFulfilled, setError] = asyncMac(asyncConfigurations);

/* Listado de los data especifica */
const fetchGeneralDataList = info => async (dispatch, getState) => {
	/* Obtiene todas los datos en especifico */
	const query = `?_limit=${info.limit}&_start=${info.start}&_sort=date:desc`;

	dispatch(setPending());
	const state = getState();

	/* Obtiene todos los datos pasados por URL */
	return api()
		.get(`/${info.endpoint}/${query}`)
		.then(({ data }) => {
			const configurations = state.configurations.entity;
			configurations[info.nameKey] = data;
			dispatch(setFulfilled(configurations));
		})
		.catch(error => dispatch(setError(error)));
};

/* Agregar dato */
const pushGeneralData = form => async (dispatch, getState) => {
	const state = getState();
	dispatch(setPending());
	const nameKey = lodash.camelCase(form.endpoint);

	return api()
		.post(`/${form.endpoint}`, form)
		.then(({ data }) => {
			const configurations = state.configurations.entity;

			/* Marca para uso de estilos */
			data.new = true;
			/* Agregando al inicio los recursos */
			configurations[nameKey] = [data].concat(configurations[nameKey]);
			dispatch(setFulfilled(configurations));
		})
		.catch(error => {
			dispatch(setError(error));
		});
};

/* Elimando una item de instancia definida */
const deleteGeneralData = info => async (dispatch, getState) => {
	const state = getState();
	const nameKey = lodash.camelCase(info.endpoint);
	dispatch(setPending());
	return api()
		.delete(`/${info.endpoint}/${info.id}`)
		.then(() => {
			const configurations = state.configurations.entity;
			configurations[nameKey] = configurations[nameKey].filter(
				item => item.id !== info.id
			);
			dispatch(setFulfilled(configurations));
		})
		.catch(error => dispatch(setError(error)));
};

/* Actualizar un item de la instancia */
const putGeneralData = form => async (dispatch, getState) => {
	const state = getState();
	const nameKey = lodash.camelCase(form.endpoint);

	dispatch(setPending());
	return api()
		.put(`/${form.endpoint}/${form.id}`, form)
		.then(({ data }) => {
			const configurations = state.configurations.entity;
			configurations[nameKey] = configurations[nameKey].map(item =>
				item.id === form.id ? data : item
			);
			dispatch(setFulfilled(configurations));
		})
		.catch(error => dispatch(setError(error)));
};

const configurationsOptions = {
	fetchGeneralDataList,
	pushGeneralData,
	deleteGeneralData,
	putGeneralData,
};

export default configurationsOptions;
