import { useMenu } from '../hooks';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
	Paper,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	FormControl,
	TextField
} from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

const status = {
	active: 'Activo',
	inactive: 'Inactivo',
};

const NegotiationDetails = ({ negotiationData }) => {
	const { goTo } = useMenu();

	/* Lista de REGIONES Y COMUNAS */
	const regions = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations?.entity?.countries[0]?.data?.map(
				regions => ({
					name: regions.name,
					value: regions.id,
				})
			);
			list = JSON.parse(JSON.stringify(list));
			return list;
		}

		return [];
	});

	const communes = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations.entity.countries?.[0].data.reduce(
				(communesOptions, region) => {
					const options = region.communes.map(commune => ({
						name: commune.name,
						value: commune.id,
						dependence: {
							name: 'region',
							value: region.id,
						}, // Este campo tiene un padre y los valores dependen de ese padre
					}));

					return [...communesOptions, ...options];
				},
				[]
			);
			list = JSON.parse(JSON.stringify(list));
			return list;
		}
		return [];
	});

	return (
		<Paper variant="outlined">
			<List>
				<ListItem>
					<ListItemText
						primary={
							<Typography variant="h6" component="h2">
								Detalles de la negociación
							</Typography>
						}
					/>
					<ListItemIcon>
						<IconButton
							onClick={() =>
								goTo(`/admin/negotiations/${negotiationData.id}/edit`)
							}
						>
							<EditOutlined />
						</IconButton>
					</ListItemIcon>
				</ListItem>
				<ListItem>
					Nombre: {negotiationData.name}
				</ListItem>
				<ListItem>
					Formato de evento: {negotiationData.event_formats?.name}
				</ListItem>
				<ListItem>
					Tipo de evento: {negotiationData.event_types?.name}
				</ListItem>
				<ListItem>
					Región: {regions?.find(element => element.value===negotiationData.region)?.name}
				</ListItem>
				<ListItem>
					Comuna: {communes?.find(element => element.value===negotiationData.commune)?.name}
				</ListItem>
				<ListItem>
					Asistentes: {negotiationData.attendees}
				</ListItem>
				<ListItem>
					Fecha de inicio: {moment.parseZone(negotiationData.date_start).format('DD-MM-YYYY')}
				</ListItem>
				<ListItem>
					Fecha de culminacion: {moment.parseZone(negotiationData.date_end).format('DD-MM-YYYY')}
				</ListItem>
				<ListItem>
					Servicios: 
					{negotiationData.services_selected?.map((service)=>(
						<><br/>{service.name+" ("+service.quantity+")"}</>
					))}
				</ListItem>
				<ListItem sx={{whiteSpace: "pre-line"}}>
					Detalles: {negotiationData.requirements}
				</ListItem>
				<ListItem sx={{whiteSpace: "pre-line"}}>
					Comentarios: {negotiationData.comments}
				</ListItem>
			</List>
		</Paper>
	);
};

export default NegotiationDetails;
