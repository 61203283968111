import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Paper } from '@mui/material';
import { useMenu, useService } from '../hooks';
import loading from '../assets/loading-pulse.svg';
import ServiceDetails from '../components/ServiceDetails';
import ProviderDetails from '../components/ProviderDetails';
import Breadcrumbs from '../components/Breadcrumbs';
import ServiceForm from '../components/ServiceForm';

const ServiceSummary = ({ edit }) => {
	const params = useParams();
	/* ID pasado por URL del servicio */
	const ID = params.id;

	const { goTo } = useMenu();

	const {
		serviceData,
		formInputs: inputs,
		createService,
		updateService,
		requestStatus: status,
		resetList,
	} = useService({ ID });

	/* Set proveedor */
	const [provider, setProvider] = useState('');

	/* Set comuna */
	const [commune, setCommune] = useState('');

	const [assignTo, setAssignTo] = useState('commune');

	useEffect(() => {
		/* Si se tiene el id del proveedor se setea */
		const idProvider = serviceData.provider?.id;
		if (idProvider) {
			setProvider(idProvider);
			setAssignTo('provider');
		}
	}, [serviceData]);

	const formProps = {
		validateFunction: () => {},
		inputs,
		cancelFunction: () => goTo('/admin/services'),
	};

	const formPropsNew = {
		...formProps,
		submitFunction: data => {
			if (!provider.length && !commune.id)
				alert('Falta el elegir o al proveedor o la comuna');
			let toSend = null;

			if (provider.length) toSend = { ...data, provider };

			if (commune.id) toSend = { ...data, commune };

			createService(toSend).then(() => {
				/* Se refresca la lista con el dato nuevo */
				resetList();
				goTo('/admin/services');
			});
		},
		defaultValues: {
			name: '',
			description: '',
			price: '',
			price_provider: '',
			minimum: '',
			maximum: '',
			service_types: null,
			status: 'inactive',
		},
		buttonText: 'Crear',
	};

	const formPropsEdit = {
		...formProps,
		submitFunction: data => {
			if (!provider.length && !commune.id)
				alert('Falta el elegir o al proveedor o la comuna');
			let toSend = null;

			if (provider.length) toSend = { ...data, provider };

			if (commune.id) toSend = { ...data, commune };

			updateService(ID, toSend).then(() => goTo(`/services/${ID}`));
		},
		defaultValues: {
			...serviceData,
			service_types: serviceData.service_types?.id,
		},
		buttonText: 'Actualizar',
		cancelFunction: () => goTo(`/services/${ID}`),
	};

	const onClick = () => goTo('/admin/services');
	const links = [{ name: 'Lista de servicios', onClick }];

	const linksDetails = [...links, { name: 'Detalles' }];
	const linksNew = [...links, { name: 'Crear servicio' }];

	const onClickEdit = () => goTo(`/admin/services/${serviceData.id}`);
	const linksEdit = [
		...links,
		{ name: 'Detalles', onClick: onClickEdit },
		{ name: `Actualizando a ${serviceData.name}` },
	];

	return ID === 'new' ? (
		<>
			<Breadcrumbs links={linksNew} />
			{status?.loading === 'pending' && (
				<img
					src={loading}
					width="50px"
					style={{ margin: 'auto', display: 'block' }}
					height="50px"
					alt="Cargando ..."
				/>
			)}
			<ServiceForm
				serviceData={serviceData}
				setCommune={setCommune}
				setProvider={setProvider}
				assignTo={assignTo}
				setAssignTo={setAssignTo}
				{...formPropsNew}
			/>
		</>
	) : edit && serviceData.id ? (
		<>
			<Breadcrumbs links={linksEdit} />
			{status?.loading === 'pending' && (
				<img
					src={loading}
					width="50px"
					style={{ margin: 'auto', display: 'block' }}
					height="50px"
					alt="Cargando ..."
				/>
			)}
			<ServiceForm
				serviceData={serviceData}
				setCommune={setCommune}
				setProvider={setProvider}
				assignTo={assignTo}
				setAssignTo={setAssignTo}
				{...formPropsEdit}
			/>
		</>
	) : serviceData.id ? (
		<>
			<Breadcrumbs links={linksDetails} />
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12}>
					<Typography variant="h2" component="h1">
						{serviceData.name}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<ServiceDetails {...{ serviceData }} />
				</Grid>
				<Grid item xs={12} sm={8}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							{serviceData.provider && (
								<Paper variant="outlined">
									<ProviderDetails
										providerData={serviceData.provider}
									/>
								</Paper>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	) : (
		<img
			src={loading}
			style={{ margin: 'auto', display: 'block' }}
			width="50px"
			height="50px"
			alt="Cargando ..."
		/>
	);
};

export default ServiceSummary;
