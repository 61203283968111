import { Container, Grid, Typography, Paper, Divider } from '@mui/material';

/* Grafica */
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement
);

const options = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top',
		},
		title: {
			display: true,
			text: 'Cotizaciones - Conversión',
		},
	},
};

const optionsLine = {
	...options,
	...{
		plugins: {
			title: {
				text: 'Tipos de eventos - Frecuencia',
			},
		},
	},
};

const labels = ['Noviembre', 'Diciembre', 'Enero', 'Febrero', 'Marzo', 'Abril'];

const data = {
	labels,
	datasets: [
		{
			label: 'Recibidas',
			data: [70, 260, 603, 626, 612, 718],
			backgroundColor: 'rgba(255, 99, 99, 0.7)',
		},
		{
			label: 'Concretadas',
			data: [10, 98, 210, 250, 321, 449],
			backgroundColor: 'rgba(53, 53, 235, 0.7)',
		},
	],
};

const dataLine = {
	labels,
	datasets: [
		{
			label: 'Celebraciones',
			data: [12, 23, 15, 30, 20, 11],
			backgroundColor: 'rgba(255, 99, 99, 0.7)',
		},
		{
			label: 'Ferias y exhibición',
			data: [10, 22, 21, 25, 21, 34],
			backgroundColor: 'rgba(53, 53, 235, 0.7)',
		},
		{
			label: 'Infantiles',
			data: [40, 53, 55, 60, 69, 65],
			backgroundColor: 'rgba(50, 200, 50, 0.7)',
		},
		{
			label: 'Matrimonios',
			data: [30, 22, 25, 26, 29, 35],
			backgroundColor: 'rgba(200, 200, 50, 0.7)',
		},
		{
			label: 'Reuniones',
			data: [55, 48, 65, 76, 59, 73],
			backgroundColor: 'rgba(50, 200, 200, 0.7)',
		},
		{
			label: 'Seminarios y Congresos',
			data: [88, 65, 95, 86, 79, 93],
			backgroundColor: 'rgba(200, 50, 200, 0.7)',
		},
	],
};
/* Fin Grafica */

const DashBoard = () => {
	return (
		<Container className="users-list" maxWidth="xl" sx={{ mb: 4 }}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Divider
						sx={{
							mt: 2,
						}}
					/>
				</Grid>
				<Grid item xs={12} lg={6} xl={4}>
					<Paper sx={{ p: '8px' }}>
						<Bar options={options} data={data} />
					</Paper>
				</Grid>
				<Grid item xs={12} lg={6} xl={4}>
					<Paper sx={{ p: '8px' }}>
						<Line options={optionsLine} data={dataLine} />
					</Paper>
				</Grid>
				<Grid item xs={12} lg={6} xl={4}>
					<Paper sx={{ p: '8px' }}>
						<Bar options={options} data={data} />
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
};

export default DashBoard;
