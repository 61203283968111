const inputs = ({ providerCategories, providerTypes }) => [
	{
		type: 'text',
		label: 'Buscar',
		name: 'keyword',
		placeholder: 'Ej: Buscar por nombre, dirección, descripcion, telephone',
	},
	{
		type: 'text',
		label: 'Comuna',
		name: 'commune',
		placeholder: 'Ej: vitacura',
	},
	{
		type: 'select',
		label: 'Estatus',
		name: 'status',
		required: true,
		options: [
			{ name: 'Activo', value: 'active' },
			{ name: 'Inactivo', value: 'inactive' },
		],
	},
];

export default inputs;
