import useList from './useList';

/* Definicion de los campos para el formulario de datos bancarios */
import formInputs from './providerBankDetail/inputs';

import actions from '../redux/action';
const removeProviderBankDetails = actions.deleteProviderBankDetails;
const pushProviderBankDetails = actions.pushProviderBankDetails;
const putProviderBankDetails = actions.putProviderBankDetails;

const useProviderBankDetail = ({ ID }) => {
	const {
		createEntity: createProviderBankDetails,
		updateEntity: updateProviderBankDetails,
		deleteEntity: deleteProviderBankDetails,
	} = useList({
		statusFn: () => {},
		entitiesFn: () => {},
		totalFn: () => {},
		push: pushProviderBankDetails,
		put: putProviderBankDetails,
		remove: removeProviderBankDetails,
		ID,
	});

	return {
		createProviderBankDetails,
		updateProviderBankDetails,
		deleteProviderBankDetails,
		formInputs,
	};
};

export default useProviderBankDetail;
