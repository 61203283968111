import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

const PER_PAGE = parseInt(process.env.REACT_APP_PER_PAGE);

/* Hook para la creacion de vistas basicas con su paginador y filtro de busqueda */

const useList = ({
	statusFn,
	entitiesFn,
	totalFn,
	queryFilter,
	clear,
	fetchTotal,
	fetch,
	read,
	push,
	put,
	remove,
	ID,
}) => {
	const dispatch = useDispatch();

	/* Estatus de la solicitud */
	const requestStatus = useSelector(statusFn);

	/* Limpiar el state */
	const resetList = () => dispatch(clear());

	/* Paginador */
	const [start, setStart] = useState(PER_PAGE);

	/* Filtro */
	const [query, setQuery] = useState('');

	/* Filtrar busqueda */
	const customEntityList = filter => {
		/* Reinicio el contador de pagina */
		setStart(PER_PAGE);

		let queryString = queryFilter(filter);

		setQuery(queryString);

		/* Reinicia resultados */
		dispatch(clear());
	};

	/* Lista de proveedores */
	const entityList = useSelector(entitiesFn);
	const totalEntities = useSelector(totalFn);

	/* Cargar más */
	const loadMore = () => {
		if (start < totalEntities) {
			dispatch(fetch({ start, query }));

			/* Incrementa el contador con la constante total por pagina */
			setStart(start + PER_PAGE);
		}
	};

	/* Cargar lista */
	useEffect(() => {
		/* Para que solo se ejecute solo si se cumple la condicion */
		if (totalEntities === 0 && !ID) {
			dispatch(fetch({ query })).then(() => {
				dispatch(fetchTotal({ query }));
			});
		}

		/* Si tiene ID distinto a new */
		if (totalEntities === 0 && ID && ID !== 'new') dispatch(read(ID));

		return () => {
			if (totalEntities === 1 && ID && ID !== 'new') dispatch(clear());
		};
	}, [dispatch, totalEntities, ID, query, clear, fetch, fetchTotal, read]);

	/* Leer un proveedor segun el ID pasado por la URL como parametro */
	const reduceList = (data, item) => (item.id === ID ? item : data);
	const entityData = entityList?.reduce(reduceList, {});

	/* Leer un proveedor */
	const readEntity = entityID => dispatch(read(entityID));

	/* Crear nuevo proveedor */
	const createEntity = data => dispatch(push(data));

	const updateEntity = (id, data) => dispatch(put(id, data));

	/* Eliminar proveedor */
	const deleteEntity = id => dispatch(remove(id));

	return {
		entityList,
		customEntityList,
		totalEntities,
		resetList,
		loadMore,
		readEntity,
		createEntity,
		updateEntity,
		deleteEntity,
		requestStatus,
		entityData,
	};
};

export default useList;
