import { useDispatch, useSelector } from 'react-redux';
import {ArrowBack, ArrowForward, Close} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import api from '../../plugins/api';
import loading from '../../assets/loading-pulse.svg';
import { useForm, Controller } from 'react-hook-form';
import { useUser } from "../../hooks";
import * as React from 'react';
import {
	Container,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Typography,
	Button,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Divider,
	Box,
	LinearProgress,
	TextField,
	Stack,
	Card,
	CardContent,
	CardActions,
	CardActionArea,
	IconButton,
	Modal,
	Alert,
	Fab,
	Popper,
	Fade,
	Paper
} from '@mui/material';
import { printMoney } from '../../plugins/utils';
import moment from 'moment';
import FormDynamic from '../../components/FormDynamic';
import DataListDynamic from '../../components/DataListDynamic';
import { sortData } from '../../plugins/utils';
import { useEffect, useState, useRef  } from 'react';
import { useProvider } from '../../hooks';

import NegotiationResultSelected from '../../components/NegotiationResultSelected';
import PNavBar from '../../components/public/PNavBar';
import PQuotationForm from '../../components/public/PQuotationForm';
import PQuotationCardsProviders from '../../components/public/PQuotationCardsProviders';
import Loader from '../../components/Loader';
import actions from '../../redux/action';
import configurations from '../../redux/actions/configurations';

import usePNavBar from '../../hooks/usePNavBar';

const PClientLogin = () => {
	/*CONCEDERLE ACCESO A LA API A LOS CLIENTES*/

	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const user = useSelector(({ user }) => user);

	const [showLoader, setShowLoader] = useState(true);
	const [emailClient, setEmailClient] = useState("");
	const [client, setClient] = useState(null);
	const [negotiationData,setNegotiationData] = useState({});
	const [modalAOpen, setModalAOpen] = useState(false);
	const handleMAOpen = () => setModalAOpen(true);
	const handleMAClose = () => setModalAOpen(false);
	const [modalBOpen, setModalBOpen] = useState(false);
	const handleMBOpen = () => setModalBOpen(true);
	const handleMBClose = () => setModalBOpen(false);
	const [negotiationID,setNegotiationID] = useState(null);

	const {goTo} = usePNavBar();

	const localClient= JSON.parse(localStorage.getItem("client"));

	useEffect(() => {
		if(localClient){
			if(localClient.user.email){
				goTo("/cuenta/eventos");
			}
		}
		setShowLoader(false);
	}, []);
	

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const scrollToBottom = () => {
		window.scrollTo({
		  top: document.documentElement.scrollHeight,
		  behavior: 'smooth',
		});
	  };

	const boxModalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
  		maxWidth: '700px',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		pt:8
	  };


	const {register, handleSubmit} = useForm();
	const [emailConfirmError,setEmailConfirmError] = useState(false);
	const [clientConfirmEdit,setClientConfirmEdit] = useState(false);

	const submite1 = (dataForm) => {
		setShowLoader(true);
		if(dataForm.email.replace(/ /g, "")===dataForm.email_confirm.replace(/ /g, "")){
			const s1Email = dataForm.email.replace(/ /g, "").toLowerCase();
			api()
			.get(`/user-infos/`)
			.then( ( {data} ) => {
				const s1UserByEmail = data.filter(item => (item.user ? item.user.email : "") === s1Email);
				if(s1UserByEmail[0]){
					setClient(s1UserByEmail[0]);
					localStorage.setItem("client", JSON.stringify(s1UserByEmail[0]));
					setEmailConfirmError(false);
					setShowLoader(false);
					goTo("/cuenta/eventos");
				}else{
					setEmailClient(s1Email);
					setEmailConfirmError(false);
					setShowLoader(false);
				}
			});
		}else{
			setShowLoader(false);
			setEmailConfirmError(true);
		}
        
    };
	return (
		<Container>
			<Loader showLoader={showLoader}/>
		<PNavBar/>
		<Container className="clientQuotation" maxWidth="xl" component="main" sx={{ mb: 4}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box sx={{ width: '100%', mb: "20px" }}>
						<Typography variant="h4" gutterBottom component="div" color="primary">
							Acceso cliente
						</Typography>
						<LinearProgress sx={{height:"2px"}} variant="determinate" value={100} color="secondary" />
					</Box>
					<Grid container justifyContent={"center"} spacing={2}>
						<Grid item xs={11} sm={5} lg={3}>
						<form
							className="form-email"
							onSubmit={handleSubmit(submite1)}
							style={{ width: '100%' }}
						>
						<Stack
							spacing={{ xs: 1, sm: 2, md: 4 }}
						>
							<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
								<TextField
									label="Email"
									id="email"
									name="email"
									variant="standard"
									required
									
									{...register("email")}
								></TextField>
							</FormControl>
							<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
								<TextField
									label="Repetir Email"
									id="email_confirm"
									name="email_confirm"
									variant="standard"
									required
									{...register("email_confirm")}
								></TextField>
								{emailConfirmError && <Alert sx={{mt:2}} severity="error">Los emails no son identicos</Alert>}
							</FormControl>
						</Stack>
						<Stack sx={{mt:"25px", mb:"25px"}} spacing={2}
							direction="row"
							alignItems="center"
							justifyContent="center"
						>
							<Button
								type="submit"
								endIcon={<ArrowForward />}
								variant="contained"
								color="secondary"
							>
								{"CONTINUAR"}
							</Button>
						</Stack>
						</form>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
		</Container>
		
	);

};

export default PClientLogin;