import budgets from './actions/budgets';
import configurations from './actions/configurations';
import configurationsOptions from './actions/configurationsOptions';
import menuOptions from './actions/menuOptions';
import negotiations from './actions/negotiations';
import notifications from './actions/notifications';
import providerBankDetails from './actions/providerBankDetails';
import providerBillings from './actions/providerBillings';
import providerContacts from './actions/providerContacts';
import providers from './actions/providers';
import services from './actions/services';
import sessions from './actions/sessions';
import user from './actions/user';
import workspaces from './actions/workspaces';

const actions = {
	...budgets,
	...configurations,
	...configurationsOptions,
	...menuOptions,
	...negotiations,
	...notifications,
	...services,
	...providerContacts,
	...providerBankDetails,
	...providerBillings,
	...providers,
	...sessions,
	...user,
	...workspaces,
};

export default actions;
