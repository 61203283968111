import {
	Container,
	Grid,
	Typography,
	Divider,
	SpeedDial,
	Button,
	Box
} from '@mui/material';
import AlertDialog from '../components/AlertDialog';
import { AddOutlined } from '@mui/icons-material';
import { DataGrid,GridActionsCellItem , GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import {Edit,Delete} from '@mui/icons-material';
import moment from 'moment';
import loading from '../assets/loading-pulse.svg';
import { styled } from '@mui/material/styles';

import { useNegotiation, useMenu } from '../hooks';

import { useState } from 'react';
import { useEffect } from 'react';

import { sortData } from '../plugins/utils';

const alertMessage =
	' Estimado Administrador, recuerde que una vez eliminada la negociacion se perderan sus datos y los relacionados a ella excepto los datos del cliente.';

const StyledGridOverlay = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
}));

function CustomNoRowsOverlay() {
	return (
	  <StyledGridOverlay>
		<img
			src={loading}
			width="50px"
			style={{ margin: 'auto', display: 'block' }}
			height="50px"
			alt="Cargando ..."
		/>
	  </StyledGridOverlay>
	);
}

const rowTitle = {
	name: (
		<Typography variant="subtitle2" component="div">
			Nombre
		</Typography>
	),
	customer: (
		<Typography variant="subtitle2" component="div">
			Cliente
		</Typography>
	),
	seller: (
		<Typography variant="subtitle2" component="div">
			Vendedor
		</Typography>
	),
	event_types: (
		<Typography variant="subtitle2" component="div">
			Tipo evento
		</Typography>
	),
	event_formats: (
		<Typography variant="subtitle2" component="div">
			Formato evento
		</Typography>
	),
};

const NegotiationList = () => {
	const {
		negotiationList,
		totalNegotiations,
		deleteNegotiation,
		requestStatus,
		loadMore,
		filterInputs,
		customNegotiationList,
	} = useNegotiation({});
	const { goTo } = useMenu();

	const [itemToDelete, setItemToDelete] = useState(null);
	const [rowsData, setRowsData] = useState([]);

	useEffect(() => {
		/* SORTING PROVIDERS BY UPDATE TIME */
		sortData(negotiationList, 'createdAt','desc');

		/* FILTERING PROVIDERS REMOVING DUPLICATE */
		var newArrayNegotiations = [];
		negotiationList?.filter(n => n.type === "query").map((item) => {
			let index = newArrayNegotiations.findIndex(object => {
				return object.id === item.id;
			})
			if(index===-1){
				newArrayNegotiations.push(item);
			}
			
		});

		/* SET NEW PROVIDER DATA TO DATAGRID */
		setRowsData(newArrayNegotiations?.map((item) => ({
			id: item.id,
			name: item.name,
			customer: item.customer ? item.customer?.email : "ERROR DE CLIENTE",
			event_types: item.event_types?.name,
			event_formats: item.event_formats?.name,
			inactive: item.status === 'inactive',
			createdAt: moment.parseZone(item.createdAt).format("DD/MM/YYYY"),
			date_start: moment.parseZone(item.date_start).format("DD/MM/YYYY"),
		})));
	}, [negotiationList]);

	const columns = [
		{ 
			field: 'actions', 
			headerName: 'Acciones', 
			type: 'actions',
			width: 120,
			description: 'Acciones a realizar por cada fila',
			getActions: (params) => [
				<GridActionsCellItem
				icon={<Edit color="primary" />}
				label="Edit"
				onClick={() => goTo(`/admin/negotiations/${params.id}`)}
				/>,
				<GridActionsCellItem
				icon={<Delete color="secondary" />}
				label="Delete"
				onClick={() => {console.log(params);setItemToDelete(params)}}
				/>,
			],
		},
		{ field: 'name', headerName: 'Nombre', width: 300 },
		{ field: 'createdAt', type:'date', headerName: 'Ingreso',description: 'Indica la fecha en que se generó la solicitud', width: 100 },
		{ field: 'customer', headerName: 'Cliente', width: 150},
		{ field: 'event_types', headerName: 'Tipo evento', width: 150},
		{ field: 'event_formats', headerName: 'Formato evento', width: 120},
	];

	return (
		<Container className="negotiation-list" maxWidth="xl" sx={{ mb: 4 }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" component="h1">
						Lista de consultas
					</Typography>
					<Divider sx={{ mt: 2 }} />
				</Grid>
				<Grid item xs={12} sx={{height:"520px"}}>
				<DataGrid
					components={{
						NoRowsOverlay: CustomNoRowsOverlay,
				  	}}
					disableSelectionOnClick 
					rows={rowsData}
					columns={columns}
					pageSize={7}
					rowsPerPageOptions={[7]}
				/>
				</Grid>
			</Grid>

			<AlertDialog
				title={`Eliminar registro`}
				msg={
					alertMessage
						? `${itemToDelete?.row?.name}: ${alertMessage}`
						: `Debes confirmar que deseas eliminar: "${itemToDelete?.row?.name}" de la lista.`
				}
				fnToDo={{
					action: () => {
						const id = itemToDelete.id;
						deleteNegotiation(id);
						setItemToDelete(null);
					},
					closeWindow: () => {
						/* Se reinicia todo */
						setItemToDelete(null);
					},
				}}
				active={itemToDelete?.id ? true : false}
			/>
		</Container>
	);
};

export default NegotiationList;
