/* Componente para la paginas de configuraciones */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	Box,
	List,
	ListItem,
	ListItemText,
	IconButton,
	Typography,
} from '@mui/material';
import { DeleteOutlined, StarBorderOutlined } from '@mui/icons-material';
import AlertDialog from './AlertDialog';
import loading from '../assets/loading-pulse.svg';

const INACTIVE = {
	borderLeftColor: 'rgb(244 67 54)',
	borderLeftWidth: '5px',
	borderLeftStyle: 'solid',
};
const NORMAL = {
	mb: '16px',
	backgroundColor: 'secondary.light',
	borderRadius: '10px',
	'&:hover': {
		backgroundColor: 'secondary.lightover',
	},
	cursor: 'pointer',
};
const editName = item => {
	if (item.default === 'active')
		return (
			<>
				{item.name}{' '}
				<StarBorderOutlined fontSize="12px" color="yellow" />
			</>
		);
	return <>{item.name}</>;
};

const ConfigDataList = ({
	list,
	getDataItem,
	endpoint,
	deleteFunction,
	status,
	title,
	subtitle,
}) => {
	const dispatch = useDispatch();
	const [itemToDelete, setItemToDelete] = useState(null);

	return (
		<Box>
			{title && (
				<Box>
					<Typography variant="subtitle1" component="h2">
						{title}
					</Typography>
					<Typography variant="body2" component="p" color="secondary">
						{subtitle}
					</Typography>
				</Box>
			)}

			{status?.loading === 'pending' && (
				<img
					src={loading}
					width="50px"
					style={{ margin: 'auto', display: 'block' }}
					height="50px"
					alt="Cargando ..."
				/>
			)}
			<List>
				{list.data.map((element, index) => (
					<Box
						key={index}
						sx={
							element.status === 'inactive'
								? { ...NORMAL, ...INACTIVE }
								: NORMAL
						}
					>
						<ListItem>
							<ListItemText
								primary={editName(element)}
								secondary={element.description}
								onClick={() => getDataItem(element)}
							/>
							<IconButton
								onClick={() => setItemToDelete(element)}
							>
								<DeleteOutlined />
							</IconButton>
						</ListItem>
					</Box>
				))}
			</List>
			<AlertDialog
				title={`Eliminar Tipo de servicio`}
				msg={`Debes confirmar que deseas eliminar: "${itemToDelete?.name}" de la lista.`}
				fnToDo={{
					action: () => {
						const id = itemToDelete.id;
						dispatch(deleteFunction({ id, endpoint }));
						setItemToDelete(null);
					},
					closeWindow: () => {
						/* Se reinicia todo */
						setItemToDelete(null);
					},
				}}
				active={itemToDelete?.id ? true : false}
			/>
		</Box>
	);
};

export default ConfigDataList;
