/* Configutaciones del menu */

const notificationSet = data => async dispatch => {
	dispatch({ type: 'notification/set', payload: data });
};

const notificationReset = () => async dispatch => {
	dispatch({ type: 'notification/reset' });
};

const fnNotification = {
	notificationSet,
	notificationReset,
};

export default fnNotification;
