import { useParams } from 'react-router-dom';
import FormDynamic from '../components/FormDynamic';
import { useMenu, useUser } from '../hooks';
import loading from '../assets/loading-pulse.svg';
import UserDetailsPersonal from '../components/UserDetailsPersonal';
import Breadcrumbs from '../components/Breadcrumbs';

const UserDetails = ({ edit, resetPassword }) => {
	const params = useParams();
	/* ID pasado por URL del usuario */
	const id = params.id;

	const { goTo } = useMenu();

	const {
		userData,
		userDataForForm,
		inputs,
		createUser,
		updateUser,
		requestStatus: status,
	} = useUser();

	const formProps = {
		validateFunction: () => {},
		inputs,
		cancelFunction: () => goTo('/admin/users'),
	};

	const formPropsNew = {
		...formProps,
		submitFunction: data => createUser(data).then(() => goTo('/admin/users')),
		defaultValues: {
			name: '',
			lastname: '',
			telephone: '',
			company: '',
			role: '',
			email: '',
			password: '',
		},
		buttonText: 'Crear',
	};

	const formPropsEdit = {
		...formProps,
		inputs: inputs.filter(x => x.name !== 'password'),
		submitFunction: data =>
			updateUser(id, data).then(() => goTo(`/admin/users/${id}`)),
		defaultValues: userDataForForm,
		buttonText: 'Actualizar',
		cancelFunction: () => goTo(`/admin/users/${id}`),
	};

	const formPropsReset = {
		...formPropsEdit,
		inputs: inputs.filter(x => x.name === 'password'),
		defaultValues: { id: userData.id, password: '' },
		buttonText: 'Actualizar contraseña',
	};

	const onClick = () => goTo('/admin/users');
	const links = [{ name: 'Lista de usuarios', onClick }];

	const linksDetails = [...links, { name: 'Detalles' }];
	const linksNew = [...links, { name: 'Crear usuario' }];

	const onClickEdit = () => goTo(`/admin/users/${userData.id}`);
	const linksEdit = [
		...links,
		{ name: 'Detalles', onClick: onClickEdit },
		{ name: `Actualizando a ${userData.name}` },
	];

	return id === 'new' ? (
		<>
			<Breadcrumbs links={linksNew} />
			{status?.loading === 'pending' && (
				<img
					src={loading}
					width="50px"
					style={{ margin: 'auto', display: 'block' }}
					height="50px"
					alt="Cargando ..."
				/>
			)}
			<FormDynamic {...formPropsNew} />
		</>
	) : userDataForForm.role && edit ? (
		<>
			<Breadcrumbs links={linksEdit} />
			{status?.loading === 'pending' && (
				<img
					src={loading}
					width="50px"
					style={{ margin: 'auto', display: 'block' }}
					height="50px"
					alt="Cargando ..."
				/>
			)}
			<FormDynamic {...formPropsEdit} />
		</>
	) : userData.id && resetPassword ? (
		<>
			<Breadcrumbs links={linksEdit} />
			<FormDynamic {...formPropsReset} />
		</>
	) : userData.id ? (
		<>
			<Breadcrumbs links={linksDetails} />
			<UserDetailsPersonal {...{ userData }} />
		</>
	) : (
		<img
			src={loading}
			style={{ margin: 'auto', display: 'block' }}
			width="50px"
			height="50px"
			alt="Cargando ..."
		/>
	);
};

export default UserDetails;
