import { Paper, Typography, Button, Box, FormControl, TextField,Grid, IconButton, Modal, Stack, InputLabel, 
		Link, Select, MenuItem, Alert , Divider, List, ListItemButton, ListItemText, CardContent, Card, Backdrop} 
from '@mui/material';
import {Edit,Check,Close,Delete,Info} from '@mui/icons-material';
import DataListDynamic from '../components/DataListDynamic';
import { useBudget, useNegotiation, useProvider, useService, useWorkspace } from '../hooks';
import { useState, useEffect } from 'react';
import { printMoney } from '../plugins/utils';
import loading from '../assets/loading-pulse.svg';
import api from '../plugins/api';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { sortData } from '../plugins/utils';
import { useRef } from 'react';
import AlertDialog from '../components/AlertDialog';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
	  backgroundColor: theme.palette.common.white,
	  color: 'rgba(0, 0, 0, 0.87)',
	  boxShadow: theme.shadows[1],
	  fontSize: 16,
	},
  }));

const BudgetInfo = ({budgetEdit,servicesProviders,workspacesProviders,setBudgetEdit, providerList, setModalAOpen,setModalBOpen,recalculateBudget}) => {
	const [editCotizacionName, setEditCotizacionName] = useState(false);
	const [editCotizacionService, setEditCotizacionService] = useState(null);
	const [deleteCotizacionServiceItem, setDeleteCotizacionServiceItem] = useState(null);
	const [editCotizacionWorkspace, setEditCotizacionWorkspace] = useState(null);
	const [deleteCotizacionWorkspaceItem, setDeleteCotizacionWorkspaceItem] = useState(null);

	const rol = useSelector(({ user }) => {
		const text = user?.entity?.role?.type;
		return text;
	});
	
	const {register:registerName, handleSubmit:handleSName, reset:resetName} = useForm();
	const submitName = (dataForm) => {
		let budgetEditing = {};
		Object.assign(budgetEditing, budgetEdit);
		budgetEditing.name = dataForm.name;
		setBudgetEdit(budgetEditing);
		setEditCotizacionName(null);
	};

	const {register:registerService, handleSubmit:handleSService, reset:resetService} = useForm();
	const submitService = (dataForm) => {
		let budgetEditing = {};
		Object.assign(budgetEditing, budgetEdit);
		const index = dataForm.index;
		budgetEditing.services[parseInt(index)].name = dataForm.name;
		if(dataForm.provider_name){
			budgetEditing.services[parseInt(index)].provider_name = dataForm.provider_name;
		}
		budgetEditing.services[parseInt(index)].quantity = dataForm.quantity;
		budgetEditing.services[parseInt(index)].price = parseInt(dataForm.price);
		budgetEditing.services[parseInt(index)].price_provider = parseInt(dataForm.price_provider);
		budgetEditing.services[parseInt(index)].description = dataForm.description;
		recalculateBudget(budgetEditing);
		setEditCotizacionService(null);
	};

	const deleteCotizacionService = (index) =>{
		let budgetEditing = {};
		Object.assign(budgetEditing, budgetEdit);
		budgetEditing.services.splice(index, 1);
		recalculateBudget(budgetEditing);
	}

	const {register:registerWorkspace, handleSubmit:handleSWorkspace, reset:resetWorkspace} = useForm();
	const submitWorkspace = (dataForm) => {
		let budgetEditing = {};
		Object.assign(budgetEditing, budgetEdit);
		const index = dataForm.index;
		budgetEditing.workspaces[parseInt(index)].name = dataForm.name;
		budgetEditing.workspaces[parseInt(index)].quantity = dataForm.quantity;
		budgetEditing.workspaces[parseInt(index)].price = dataForm.price;
		budgetEditing.workspaces[parseInt(index)].price_provider = dataForm.price_provider;
		budgetEditing.workspaces[parseInt(index)].description = dataForm.description;
		recalculateBudget(budgetEditing);
		setEditCotizacionWorkspace(null);
	};

	const deleteCotizacionWorkspace = (index) =>{
		let budgetEditing = {};
		Object.assign(budgetEditing, budgetEdit);
		budgetEditing.workspaces.splice(index, 1);
		recalculateBudget(budgetEditing);
	}

	const addOtherService = ()=>{
		let budgetDataObj = {};
		Object.assign(budgetDataObj, budgetEdit);
		budgetDataObj.services.push({
			name: "",
			description: "",
			price: 0,
			price_provider: 0,
			quantity: 0,
			minimum:1,
			maximum:999,
			provider:"other_services",
			provider_name:""
		});
		recalculateBudget(budgetDataObj);
	}	

	return (
		
		<>{budgetEdit && <>
			{editCotizacionName ?
			(<form onSubmit={handleSName(submitName)}
					style={{ width: '100%' }}>
				<Grid container justifyContent={"center"} spacing={1} sx={{mb:2}}>
					<Grid item xs={11}>
						<FormControl fullWidth variant="outlined" sx={{alignContent:"center", mb:2}}>
						<TextField
							label="Nombre cotizacion"
							id="name_budget"
							name="name_budget"
							variant="outlined"
							required
							defaultValue={budgetEdit.name ? budgetEdit.name : ""}
							{...registerName("name")}
						></TextField>
						</FormControl>
					</Grid>
					<Grid item xs={1}>
						<IconButton type="submit">
							<Check />
						</IconButton>
					</Grid>
				</Grid>
			</form>) : 
			(<Typography variant="h6" sx={{mb:2}}>Nombre cotización: {budgetEdit.name ? budgetEdit.name : ""}
				<IconButton aria-label="Editar" color="secondary" onClick={()=> {resetName();setEditCotizacionName(true)}} size="small"><Edit fontSize="small"/></IconButton>
			</Typography>)}
			<Typography variant="h5" color="primary" component="h2">Servicios 
				<Link
				component="button"
				variant="h6"
				onClick={() => setModalAOpen(true)}
				sx={{ml:2}}
				color="secondary"
				>
				Agregar
				</Link>
			</Typography>
			{servicesProviders?.length===1 &&
				<Card>
					<CardContent
						sx={{textAlign:"center", p:4} }
					>
						<Typography>No hay servicios</Typography>
					</CardContent>
				</Card>
			}
			{servicesProviders?.map((provider) => {
				if(provider){
				const provider_name = providerList?.find(element => element.id === provider)?.name;
				return (
					<>
					<Typography variant="h6">
						{provider_name ? provider_name : "Otros servicios"}
						{!provider_name &&  
							<Link
							component="button"
							variant="h6"
							onClick={addOtherService}
							sx={{ml:2}}
							color="secondary"
							>
							Agregar
							</Link>
						}
					</Typography>

					{!provider_name && budgetEdit.services?.findIndex(element =>element.provider===provider)===-1 &&
						<Card>
							<CardContent
								sx={{textAlign:"center", p:4} }
							>
								<Typography>No hay servicios</Typography>
							</CardContent>
						</Card>
					}

					{budgetEdit.services.findIndex(element =>element.provider===provider)>=0 &&
						budgetEdit.services?.map((service,index) => {
						if(service.provider === provider){
						return (
						<Paper variant="outlined" sx={{ padding: '16px' , mb:2}}>
							{editCotizacionService===index ? (
								<form
									onSubmit={handleSService(submitService)}
									style={{ width: '100%' }}
								>
									<Grid container justifyContent={"center"} spacing={1}>
									<Grid item xs={11}>
										<Grid container justifyContent={"flex-start"} spacing={2}>
											<input type="hidden" value={index} {...registerService("index")}/>
											{!provider_name &&
											<Grid item xs={12} md={5}>
												<FormControl fullWidth variant="outlined" sx={{alignContent:"center"}}>
													<TextField
														label="Proveedor"
														variant="outlined"
														required
														defaultValue={budgetEdit.services[editCotizacionService].provider_name ? budgetEdit.services[editCotizacionService].provider_name : ""}
														{...registerService("provider_name")}
													></TextField>
												</FormControl>
											</Grid>}
											<Grid item xs={12} md={7}>
												<FormControl fullWidth variant="outlined" sx={{alignContent:"center"}}>
													<TextField
														label="Nombre"
														variant="outlined"
														required 
														defaultValue={budgetEdit.services[editCotizacionService].name ? budgetEdit.services[editCotizacionService].name : ""}
														{...registerService("name")}
													></TextField>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth variant="outlined" sx={{alignContent:"center"}}>
													<TextField
														type="number"
														label="Cantidad / Dias"
														variant="outlined"
														required
														defaultValue={budgetEdit.services[editCotizacionService].quantity ? budgetEdit.services[editCotizacionService].quantity : ""}
														{...registerService("quantity")}
													></TextField>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth variant="outlined" sx={{alignContent:"center"}}>
													<TextField
														label="Precio"
														type="number"
														defaultValue={budgetEdit.services[editCotizacionService].price ? budgetEdit.services[editCotizacionService].price : "0"}
														variant="outlined"
														required
														{...registerService("price")}
													></TextField>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth variant="outlined" sx={{alignContent:"center"}}>
													<TextField
														label="Precio S&E"
														type="number"
														variant="outlined"
														defaultValue={budgetEdit.services[editCotizacionService].price_provider ? budgetEdit.services[editCotizacionService].price_provider : ""}
														required
														{...registerService("price_provider")}
													></TextField>
												</FormControl>
											</Grid>
											<Grid item xs={12}>
												<FormControl fullWidth variant="outlined" sx={{alignContent:"center"}}>
													<TextField
														multiline
														rows={2}
														label="Detalles"
														variant="outlined"
														defaultValue={budgetEdit.services[editCotizacionService].description ? budgetEdit.services[editCotizacionService].description : ""}
														
														{...registerService("description")}
													></TextField>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={1}>
										<IconButton type="submit" aria-label={"Editar "+index} color="secondary" size="small"><Check fontSize="small"/></IconButton>
									</Grid>
									</Grid>
								</form>
							):(
								<Grid container justifyContent={"center"} spacing={1}>
									<Grid item xs={11}>
										<Grid container justifyContent={"flex-start"} spacing={2}>
											{!provider_name &&
											<Grid item xs={12} sm={5}>
											<Typography variant="body1">Proveedor: {service.provider_name ? service.provider_name : ""}</Typography>
											</Grid>}
											<Grid item xs={12} sm={7}>
											<Typography variant="body1">Nombre: {service.name ? service.name : ""}</Typography>
											</Grid>
											<Grid item xs={12} sm={4}>
											<Typography variant="body1">Cantidad / Dias: {service.quantity ? service.quantity : "0"}</Typography>
											</Grid>
											<Grid item xs={12} sm={4}>
											<Typography variant="body1">Precio: {service.price ? printMoney(service.price) : "$0"}</Typography>
											</Grid>
											<Grid item xs={12} sm={4}>
											<Typography variant="body1">Precio S&E: {service.price_provider ? printMoney(service.price_provider) : "$0"}</Typography>
											</Grid>
											<Grid item xs={12}>
											<Typography variant="body1">Total sin IVA: {service.price > 0 && service.quantity > 0 ? printMoney((service.price*service.quantity)) : "$0"}</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={1}>
										<Stack spacing={1}>
											<IconButton aria-label={"Editar "+index} color="secondary" onClick={()=> {resetService();setEditCotizacionService(index)}} size="small"><Edit fontSize="small"/></IconButton>
											<IconButton aria-label={"Eliminar "+index} color="secondary" onClick={()=> setDeleteCotizacionServiceItem({id:index,name:service.name})} size="small"><Delete fontSize="small"/></IconButton>
											<LightTooltip title={service.description}><IconButton color="secondary" size="small"><Info fontSize="small"/></IconButton></LightTooltip>
										</Stack>
									</Grid>
								</Grid>
							)}
						</Paper>
						)
						}
					})}
					</>
				)}
			})}

			<AlertDialog
				title={`Eliminar servicio`}
				msg={
					`Debes confirmar que deseas eliminar: "${deleteCotizacionServiceItem?.name}" de la lista.`
				}
				fnToDo={{
					action: () => {
						const id = deleteCotizacionServiceItem.id;
						deleteCotizacionService(id);
						setDeleteCotizacionServiceItem(null);
					},
					closeWindow: () => {
						/* Se reinicia todo */
						setDeleteCotizacionServiceItem(null);
					},
				}}
				active={deleteCotizacionServiceItem?.id >= 0 ? true : false}
			/>
			
			<Typography variant="h5" color="primary" sx={{mt:2}}>
				Salones
				<Link
				component="button"
				variant="h6"
				onClick={() => setModalBOpen(true)}
				sx={{ml:2}}
				color="secondary"
				>
				Agregar
				</Link>
			</Typography>
			{workspacesProviders?.length < 1 &&
				<Card>
					<CardContent
						sx={{textAlign:"center", p:4} }
					>
						<Typography>No hay salones</Typography>
					</CardContent>
				</Card>
			}
			{workspacesProviders?.map((provider) => {
				return (
					<>
					<Typography variant="h6">{providerList?.find(element => element.id === provider)?.name}</Typography>
					{ budgetEdit.workspaces?.map((workspace,index) => {
						console.log(index);
						if(workspace.provider === provider){
						return (
						<Paper variant="outlined" sx={{ padding: '16px' , mb:2}}>
						{editCotizacionWorkspace===index ? (
							<form
								onSubmit={handleSWorkspace(submitWorkspace)}
								style={{ width: '100%' }}
							>
								<Grid container justifyContent={"center"} spacing={1}>
								<Grid item xs={11}>
									<Grid container justifyContent={"flex-start"} spacing={2}>
										<input type="hidden" value={index} {...registerWorkspace("index")}/>
										<Grid item xs={12} md={7}>
											<FormControl fullWidth variant="outlined" sx={{alignContent:"center"}}>
												<TextField
													label="Nombre"
													variant="outlined"
													required 
													defaultValue={budgetEdit.workspaces[editCotizacionWorkspace].name ? budgetEdit.workspaces[editCotizacionWorkspace].name : ""}
													{...registerWorkspace("name")}
												></TextField>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth variant="outlined" sx={{alignContent:"center"}}>
												<TextField
													type="number"
													label="Cantidad / Dias"
													variant="outlined"
													required
													defaultValue={budgetEdit.workspaces[editCotizacionWorkspace].quantity ? budgetEdit.workspaces[editCotizacionWorkspace].quantity : ""}
													{...registerWorkspace("quantity")}
												></TextField>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth variant="outlined" sx={{alignContent:"center"}}>
												<TextField
													label="Precio"
													type="number"
													defaultValue={budgetEdit.workspaces[editCotizacionWorkspace].price ? budgetEdit.workspaces[editCotizacionWorkspace].price : "0"}
													variant="outlined"
													required
													{...registerWorkspace("price")}
												></TextField>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth variant="outlined" sx={{alignContent:"center"}}>
												<TextField
													label="Precio S&E"
													type="number"
													variant="outlined"
													defaultValue={budgetEdit.workspaces[editCotizacionWorkspace].price_provider ? budgetEdit.workspaces[editCotizacionWorkspace].price_provider : ""}
													required
													{...registerWorkspace("price_provider")}
												></TextField>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<FormControl fullWidth variant="outlined" sx={{alignContent:"center"}}>
												<TextField
													multiline
													rows={2}
													label="Detalles"
													variant="outlined"
													defaultValue={budgetEdit.workspaces[editCotizacionWorkspace].description ? budgetEdit.workspaces[editCotizacionWorkspace].description : ""}
													
													{...registerWorkspace("description")}
												></TextField>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={1}>
									<IconButton type="submit" aria-label={"Editar "+index} color="secondary" size="small"><Check fontSize="small"/></IconButton>
								</Grid>
								</Grid>
							</form>
						):(
							<Grid container justifyContent={"center"} spacing={1}>
								<Grid item xs={11}>
									<Grid container justifyContent={"flex-start"} spacing={2}>
										<Grid item xs={12} sm={7}>
											<Typography variant="body1">Nombre: {workspace.name ? workspace.name : ""}</Typography>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography variant="body1">Cantidad / Dias: {workspace.quantity ? workspace.quantity : "0"}</Typography>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Typography variant="body1">Precio: {workspace.price ? printMoney(workspace.price) : "$0"}</Typography>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Typography variant="body1">Precio S&E: {workspace.price_provider ? printMoney(workspace.price_provider) : "$0"}</Typography>
										</Grid>
										<Grid item xs={12}>
											<Typography variant="body1">Total sin IVA: {workspace.price > 0 && workspace.quantity > 0 ? printMoney((workspace.price*workspace.quantity)) : "$0"}</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={1}>
									<IconButton aria-label={"Editar "+index} color="secondary" onClick={()=> {resetWorkspace();setEditCotizacionWorkspace(index)}} size="small"><Edit fontSize="small"/></IconButton>
									<IconButton aria-label={"Eliminar "+index} color="secondary" onClick={()=> setDeleteCotizacionWorkspaceItem({id:index,name:workspace.name})} size="small"><Delete fontSize="small"/></IconButton>
									<LightTooltip title={workspace.description}><IconButton color="secondary" size="small"><Info fontSize="small"/></IconButton></LightTooltip>
								</Grid>
							</Grid>
						)}
					</Paper>
						)}})
					}
					</>
				)
			})}
			<AlertDialog
				title={`Eliminar workspace`}
				msg={
					`Debes confirmar que deseas eliminar: "${deleteCotizacionWorkspaceItem?.name}" de la lista.`
				}
				fnToDo={{
					action: () => {
						const id = deleteCotizacionWorkspaceItem.id;
						deleteCotizacionWorkspace(id);
						setDeleteCotizacionWorkspaceItem(null);
					},
					closeWindow: () => {
						/* Se reinicia todo */
						setDeleteCotizacionWorkspaceItem(null);
					},
				}}
				active={deleteCotizacionWorkspaceItem?.id >= 0 ? true : false}
			/>
			<Typography variant="body1" sx={{mt:2}}>Total Servicios: {printMoney(budgetEdit.total_services)}</Typography>
			<Typography variant="body1">Total Salones: {printMoney(budgetEdit.total_workspaces)}</Typography>
			<Typography variant="body1">Total IVA 19%: {printMoney(budgetEdit.total_tax)}</Typography>
			<Typography variant="h6">Total {printMoney(budgetEdit.total)} IVA Inc</Typography>
			{rol==="administrator" && <Typography variant="h6">Total Ganancia S&E {printMoney(budgetEdit.total_sye)} (Contiene IVA)</Typography>}
		</>}</>
	);
};

const BudgetDetail = ({ ID, newService, setNewService, newWorkspace,setNewWorkspace, negotiationData, providerList, 
						providerObj,setProviderObj,setModalAOpen,setModalBOpen,resetListNegotiation,getNegotiation,setLoadingBD}) => {
	const [budgetEdit,setBudgetEdit] = useState({});
	const [servicesProviders,setServicesProviders] = useState([]);
	const [workspacesProviders,setWorkspacesProviders] = useState([]);
	const [successSaveBudget,setSuccessSaveBudget] = useState(false);

	const {
		budgetData,
		requestStatus: statusBudget,
		updateBudget,
	} = useBudget({ ID });

	useEffect(()=>{
		if(statusBudget?.loading === 'pending'){
			setLoadingBD(true);
		}else{
			setLoadingBD(false);
		}
	},[statusBudget])

	const recalculateBudget = (budget) => {
		let newBudget = {};
		Object.assign(newBudget,budget);
		let total_services = 0;
		let total_workspaces = 0;
		let total_tax = 0;
		let total_services_sye = 0;
		let total_workspaces_sye = 0;
		let total_tax_sye = 0;

		newBudget.services?.forEach(element => {
			total_services += (element.price ? element.price : 0)*(element.quantity ? element.quantity : 0);
			total_services_sye += (element.price_provider ? element.price_provider : 0)*(element.quantity ? element.quantity : 0);
		});

		newBudget.workspaces?.forEach(element => {
			total_workspaces +=  (element.price ? element.price : 0)*(element.quantity ? element.quantity : 0);
			total_workspaces_sye += (element.price_provider ? element.price_provider : 0)*(element.quantity ? element.quantity : 0);
		});

		total_tax = (total_services+total_workspaces)*0.19;
		total_tax_sye = (total_services_sye+total_workspaces_sye)*0.19;

		let total = total_tax+total_services+total_workspaces;
		let total_sye = total_tax_sye+total_services_sye+total_workspaces_sye;

		newBudget.total = total;
		newBudget.total_services = total_services;
		newBudget.total_workspaces = total_workspaces;
		newBudget.total_tax = total_tax;
		newBudget.total_sye = total-total_sye;
		setBudgetEdit(newBudget);
	}

	useEffect(()=>{
		if(budgetEdit.services){
			let servicesProvidersArray = [];
			budgetEdit.services?.forEach((service) => {
				if(servicesProvidersArray.indexOf(service.provider)===-1){
					if(service.provider && service.provider!=="other_services"){
						servicesProvidersArray.push(service.provider);
					}
				}
			});
			servicesProvidersArray.push("other_services");
			setServicesProviders(servicesProvidersArray);
			let workspacesProvidersArray = [];
			budgetEdit.workspaces?.forEach((workspace) => {
				if(workspacesProvidersArray.indexOf(workspace.provider)===-1){
					workspacesProvidersArray.push(workspace.provider);
				}
			});
			setWorkspacesProviders(workspacesProvidersArray);
		}
		console.log(budgetEdit);
	},[budgetEdit]);

	useEffect(()=>{
		if(newService){
			let newServiceObj = {};
			Object.assign(newServiceObj, newService);
			const id_provider = newServiceObj.provider.id;
			newServiceObj.provider = id_provider;
			let budgetDataObj = {};
			Object.assign(budgetDataObj, budgetEdit);
			budgetDataObj.services.push(newServiceObj);
			setNewService(null);
			recalculateBudget(budgetDataObj);
		}
	},[newService]);

	useEffect(()=>{
		if(newWorkspace){
			let newWorkspaceObj = {};
			Object.assign(newWorkspaceObj, newWorkspace);
			const id_provider = newWorkspaceObj.provider.id;
			newWorkspaceObj.provider = id_provider;
			let budgetDataObj = {};
			Object.assign(budgetDataObj, budgetEdit);
			budgetDataObj.workspaces.push(newWorkspaceObj);
			setNewWorkspace(null);
			recalculateBudget(budgetDataObj);
		}
	},[newWorkspace]);

	useEffect(()=>{
		let budgetDataObj = {};
		Object.assign(budgetDataObj, budgetData);
		recalculateBudget(budgetDataObj);
	},[budgetData]);

	const saveBudget = () => {
		setLoadingBD(true);
		const dataUpdate = {
			name:budgetEdit.name,
			services:budgetEdit.services,
			workspaces:budgetEdit.workspaces,
			total: budgetEdit.total,
			total_services: budgetEdit.total_services,
			total_sye: budgetEdit.total_sye,
			total_tax: budgetEdit.total_tax,
			total_workspaces: budgetEdit.total_workspaces
		}
		updateBudget(budgetEdit.id, dataUpdate).then(() => {
			getNegotiation(budgetEdit.negociacion?.id);
			setSuccessSaveBudget(true);
			setLoadingBD(false);
		});
	}

	return (
		<Box sx={{mt:2}}>
			<Button 
				fullWidth
				startIcon={<Check/>} 
				color="secondary" 
				variant="contained"
				sx={{mb:3}}
				onClick={()=>saveBudget()}
			>
				Guardar
			</Button>
			{ successSaveBudget &&
			<Alert sx={{ mb:3}} onClose={() => {setSuccessSaveBudget(false)}} severity="success">
				Se guardaron los cambios correctamente 
			</Alert>}
			
		{ budgetEdit &&
			<BudgetInfo budgetEdit={budgetEdit} servicesProviders={servicesProviders} 
						workspacesProviders={workspacesProviders} setBudgetEdit={setBudgetEdit} 
						setModalAOpen={setModalAOpen} setModalBOpen={setModalBOpen} providerList={providerList} recalculateBudget={recalculateBudget} />
		}
		{ statusBudget==="pending" &&
			<img
			src={loading}
			width="50px"
			style={{ margin: 'auto', display: 'block' }}
			height="50px"
			alt="Cargando ..."
		/>
		}
		</Box>
		
	);
};

const ServicesPrint = ({services, setNewService, setModalAOpen}) => {
	return(
	<List>
		{services?.map((item) => (
			<ListItemButton
			onClick={() => {const itemS = item; 
							setNewService(itemS); 
							setModalAOpen(false);
			}}
			>
			<ListItemText primary={item.provider?.name} />
			<ListItemText primary={item.name+" / "+printMoney(item.price)} />
			<LightTooltip title={item.description}><IconButton color="secondary" size="small"><Info fontSize="small"/></IconButton></LightTooltip>
			</ListItemButton>
		))}
	</List>);
};

const WorkspacesPrint = ({workspaces, setNewWorkspace, setModalBOpen}) => {
	return(
	<List>
		{workspaces?.map((item) => (
			<ListItemButton
			onClick={() => {const itemS = item; 
							setNewWorkspace(itemS); 
							setModalBOpen(false);
			}}
			>
			<ListItemText primary={item.provider?.name} />
			<ListItemText primary={item.name+" / "+item.attendees_minimum+"-"+item.attendees_maximum+" / "+printMoney(item.price)} />
			<LightTooltip title={item.description}><IconButton color="secondary" size="small"><Info fontSize="small"/></IconButton></LightTooltip>
			</ListItemButton>
		))}
	</List>);
};

/* Configurando responsividad de la tabla. Siempre trabajar en base a 11 porque 1 está destinado al boton eliminar. */
const widthCells = { name: { xs: 7 }, price: { xs: 3 } };
const rowTitle = {
	name: (
		<Typography variant="subtitle2" component="div">
			Nombre
		</Typography>
	),
	total: (
		<Typography variant="subtitle2" component="div">
			Total
		</Typography>
	),
};

const NegotiationBudgets = ({ negotiationData, providers, setProviders, resetListNegotiation, getNegotiation, statusNegotiation, loadingBD,setLoadingBD }) => {
	const [budgetId, setBudgetId] = useState('');
	const [newService, setNewService] = useState(null);
	const [newWorkspace, setNewWorkspace] = useState(null);
	const [services, setServices] = useState([]);
	const [servicesFiltered, setServicesFiltered] = useState([]);
	const [workspacesFiltered, setWorkspacesFiltered] = useState([]);
	const [serviceSelected, setServiceSelected] = useState(null);
	const [modalAOpen,setModalAOpen] = useState(false);
	const [modalBOpen,setModalBOpen] = useState(false);
	const { createBudget, deleteBudget } = useBudget({ ID: 'new' }); //Se coloca new para no se ejecute la solicitud de un listado, ni se ejecute la lectura de un servicio
	
	const [providerObj,setProviderObj] = useState({});

	const createNewBudget = ()=>{
		setLoadingBD(true);
		const dataCreate = {
			negociacion:negotiationData.id,
			name:"Nueva cotización",
			services:[],
			workspaces:[],
			total: 0,
			total_services: 0,
			total_sye: 0,
			total_tax: 0,
			total_workspaces: 0
		}
		createBudget(dataCreate).then(() => {
			getNegotiation(negotiationData.id);
			setLoadingBD(false);
		});
	}

	useEffect(()=>{
		if(statusNegotiation?.loading === 'pending'){
			setLoadingBD(true);
		}else{
			setLoadingBD(false);
		}
	},[statusNegotiation])

	const {
		serviceList
	} = useService({});

	const {
		workspaceList
	} = useWorkspace({});


	const [dependencies, setDependencies] = useState({ 
        region: negotiationData.region ? negotiationData.region: '',
        commune: negotiationData.commune ? negotiationData.commune: '',
		provider:'',
		service_type:''
     });

    const dependenceFilter = (dependencies, dependenceValue) => {
        /* Si el campo tiene valor de dependencia, realiza la comparación */
        if (dependenceValue) {
            const key = dependenceValue.name;
            const value = dependenceValue.value;
            return dependencies[key] === value;
        }
        return true;
    };

	useEffect( () => {
		console.log(serviceList);
		let servicesFiltering = serviceList;
		if(servicesFiltering?.length>1){
			servicesFiltering = servicesFiltering?.filter(item => item.status === "active");
			servicesFiltering = servicesFiltering?.filter(item => item.provider?.status === "active");
			if(dependencies.region!==''){
				servicesFiltering = servicesFiltering?.filter(item => item.provider?.region === dependencies.region);
			}
			if(dependencies.commune!==''){
				servicesFiltering = servicesFiltering?.filter(item => item.provider?.commune === dependencies.commune);
			}
			if(dependencies.provider!==''){
				servicesFiltering = servicesFiltering?.filter(item => item.provider?.id === dependencies.provider);
			}
			if(dependencies.service_type!==''){
				servicesFiltering = servicesFiltering?.filter(item => item.service_types?.id === dependencies.service_type);
			}
		}
		console.log(servicesFiltering);
		setServicesFiltered(servicesFiltering);

		console.log(workspaceList);
		let workspacesFiltering = workspaceList;
		if(workspacesFiltering?.length>1){
			workspacesFiltering = workspacesFiltering?.filter(item => item.status === "active");
			workspacesFiltering = workspacesFiltering?.filter(item => item.provider?.status === "active");
			if(dependencies.region!==''){
				workspacesFiltering = workspacesFiltering?.filter(item => item.provider?.region === dependencies.region);
			}
			if(dependencies.commune!==''){
				workspacesFiltering = workspacesFiltering?.filter(item => item.provider?.commune === dependencies.commune);
			}
			if(dependencies.provider!==''){
				workspacesFiltering = workspacesFiltering?.filter(item => item.provider?.id === dependencies.provider);
			}
		}
		console.log(workspacesFiltering);
		setWorkspacesFiltered(workspacesFiltering);
	}, [dependencies,serviceList,workspaceList]);

	/* Lista de REGIONES Y COMUNAS */
	const regions = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations?.entity?.countries[0]?.data?.map(
				regions => ({
					name: regions.name,
					value: regions.id,
				})
			);
			list = JSON.parse(JSON.stringify(list));
			/* Ordena por name */
			sortData(list, 'name', 'asc');
			return list;
		}

		return [];
	});

	const communes = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations.entity.countries?.[0].data.reduce(
				(communesOptions, region) => {
					const options = region.communes.map(commune => ({
						name: commune.name,
						value: commune.id,
						dependence: {
							name: 'region',
							value: region.id,
						}, // Este campo tiene un padre y los valores dependen de ese padre
					}));

					return [...communesOptions, ...options];
				},
				[]
			);
			list = JSON.parse(JSON.stringify(list));
			/* Ordena por name */
			sortData(list, 'name', 'asc');
			return list;
		}
		return [];
	});

	const serviceTypes = useSelector(({ configurations }) =>
		configurations?.entity?.serviceTypes?.map(type => ({
			name: type.name,
			value: type.id,
			status: type.status,
			provider_categories: type.provider_categories?.id,
		}))
	);

	const boxModalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
  		maxWidth: '700px',
		overflowY: 'scroll',
		height: '95%',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		pt:8
	  };
	
	const rowsData = negotiationData.cotizacions.map(item => ({
		id: item.id,
		name: item.name,
		total: printMoney(item.total),
		inactive: item.status === 'inactive',
		onClick: () => {
			api()
			.get(`/services`)
			.then(({ data }) => {
				setServices(data?.filter(item_s => item_s.provider?.status === 'active' && item_s.status === 'active' ));
			})
			api()
			.get(`/providers`)
			.then(({ data }) => {
				setProviders(data?.filter(item_p => item_p.status === 'active' ));
				setBudgetId(item.id);
			})
		},
	}));

	return (
		<>
			<Modal
				open={modalAOpen}
				onClose={() => setModalAOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxModalStyle}>
				<IconButton color="primary" sx={{position:"absolute",top:"10px",right:"10px"}} aria-label="Cerrar" onClick={() => setModalAOpen(false)}>
					<Close />
				</IconButton>
				<form
					className="form-client-edit"
					style={{ width: '100%' }}
				>
				<Stack
					spacing={{ xs: 1, sm: 2, md: 4 }}
				>
					<Grid container justifyContent={"center"} spacing={2}>
						<Grid item xs={12} sm={3} >
							<FormControl fullWidth>
								<InputLabel id="region_lb">
									Región
								</InputLabel>
								<Select
									labelId="region_lb"
									id="region"
									name="region"
									defaultValue={dependencies.region ? dependencies.region : ""}
									onChange={e => {
										setDependencies({
											...dependencies,
											["region"]:
												e.target.value,
										});
									}}
								>
									<MenuItem
										key={`service-type-1`}
										value=""
									>
										Todas
									</MenuItem>
									{regions?.map((option, index) => (
										<MenuItem
											key={`region-${index}`}
											value={option.value}
										>
											{option.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={3}>
							<FormControl fullWidth >
								<InputLabel id="comuna_lb">
									Comuna
								</InputLabel>
								<Select
									labelId="comuna_lb"
									id="commune"
									name="commune"
									defaultValue={dependencies.commune ? dependencies.commune : ""}
									onChange={e => {
										setDependencies({
											...dependencies,
											["commune"]:
												e.target.value,
										});
									}}
								>
									<MenuItem
										key={`service-type-1`}
										value=""
									>
										Todas
									</MenuItem>
									{communes
										?.filter(option =>
											dependenceFilter(
												dependencies,
												option.dependence
											)
										)
										?.map((option, index) => (
										<MenuItem
											key={`commune-${index}`}
											value={option.value}
										>
											{option.name}
										</MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={3}>
							<FormControl fullWidth >
								<InputLabel id="provider_lb">
									Proveedor
								</InputLabel>
								<Select
									labelId="provider_lb"
									id="provider"
									name="provider"
									onChange={e => {
										setDependencies({
											...dependencies,
											["provider"]:
												e.target.value,
										});
									}}
								>
									<MenuItem
										key={`service-type-1`}
										value=""
									>
										Todos
									</MenuItem>
									{providers
										?.filter(option =>
											dependenceFilter(
												dependencies,
												{name:"commune",value:option.commune}
											)
										)
										?.map((option, index) => (
										<MenuItem
											key={`provider-${index}`}
											value={option.id}
										>
											{option.name}
										</MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={3}>
							<FormControl fullWidth>
								<InputLabel id="service_type_lb">
									Tipo
								</InputLabel>
								<Select
									labelId="service_type_lb"
									id="service_type"
									name="service_type"
									onChange={e => {
										setDependencies({
											...dependencies,
											["service_type"]:
												e.target.value,
										});
									}}
								>
									<MenuItem
										key={`service-type-1`}
										value=""
									>
										Todos
									</MenuItem>
									{serviceTypes
										?.map((option, index) => (
										<MenuItem
											key={`service-type-${index}`}
											value={option.value}
										>
											{option.name}
										</MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<Divider />
							<Paper variant="outlined" sx={{ padding: '16px', mt:2, mb:2 }}>
							<ServicesPrint services={servicesFiltered} setNewService={setNewService} setModalAOpen={setModalAOpen} />
							</Paper>
							
						</Grid>
					</Grid>
				</Stack>
				</form>
				</Box>
			</Modal>

			<Modal
				open={modalBOpen}
				onClose={() => setModalBOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxModalStyle}>
				<IconButton color="primary" sx={{position:"absolute",top:"10px",right:"10px"}} aria-label="Cerrar" onClick={() => setModalBOpen(false)}>
					<Close />
				</IconButton>
				<form
					className="form-client-edit"
					style={{ width: '100%' }}
				>
				<Stack
					spacing={{ xs: 1, sm: 2, md: 4 }}
				>
					<Grid container justifyContent={"center"} spacing={2}>
						<Grid item xs={12} sm={4} >
							<FormControl fullWidth>
								<InputLabel id="region_lb">
									Región
								</InputLabel>
								<Select
									labelId="region_lb"
									id="region"
									name="region"
									defaultValue={dependencies.region ? dependencies.region : ""}
									onChange={e => {
										setDependencies({
											...dependencies,
											["region"]:
												e.target.value,
										});
									}}
								>
									<MenuItem
										key={`service-type-1`}
										value=""
									>
										Todas
									</MenuItem>
									{regions?.map((option, index) => (
										<MenuItem
											key={`region-${index}`}
											value={option.value}
										>
											{option.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormControl fullWidth >
								<InputLabel id="comuna_lb">
									Comuna
								</InputLabel>
								<Select
									labelId="comuna_lb"
									id="commune"
									name="commune"
									defaultValue={dependencies.commune ? dependencies.commune : ""}
									onChange={e => {
										setDependencies({
											...dependencies,
											["commune"]:
												e.target.value,
										});
									}}
								>
									<MenuItem
										key={`service-type-1`}
										value=""
									>
										Todas
									</MenuItem>
									{communes
										?.filter(option =>
											dependenceFilter(
												dependencies,
												option.dependence
											)
										)
										?.map((option, index) => (
										<MenuItem
											key={`commune-${index}`}
											value={option.value}
										>
											{option.name}
										</MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormControl fullWidth >
								<InputLabel id="provider_lb">
									Proveedor
								</InputLabel>
								<Select
									labelId="provider_lb"
									id="provider"
									name="provider"
									onChange={e => {
										setDependencies({
											...dependencies,
											["provider"]:
												e.target.value,
										});
									}}
								>
									<MenuItem
										key={`service-type-1`}
										value=""
									>
										Todos
									</MenuItem>
									{providers
										?.filter(option =>
											dependenceFilter(
												dependencies,
												{name:"commune",value:option.commune}
											)
										)
										?.map((option, index) => (
										<MenuItem
											key={`provider-${index}`}
											value={option.id}
										>
											{option.name}
										</MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<Divider />
							<Paper variant="outlined" sx={{ padding: '16px', mt:2, mb:2 }}>
							<WorkspacesPrint workspaces={workspacesFiltered} setNewWorkspace={setNewWorkspace} setModalBOpen={setModalBOpen} />
							</Paper>
						</Grid>
					</Grid>
				</Stack>
				</form>
				</Box>
			</Modal>
		
		<Paper variant="outlined" sx={{ padding: '16px' }}>
			{budgetId === 'new' ? (
				<>
					<Typography variant="h5" component="h2" color="primary">
						Nueva cotización
					</Typography>
				</>
			) : !['', 'new'].includes(budgetId) ? (
				<>
					<Button
						type="button"
						fullWidth
						variant="outlined"
						sx={{ mt: '16px', mb: 2 }}
						onClick={() => setBudgetId('')}
					>
						Lista de cotizaciones
					</Button>
					<Typography variant="h5" component="h2" color="primary">
						Actualizando Cotización
					</Typography>
					<BudgetDetail ID={budgetId} newService={newService} setNewService={setNewService} newWorkspace={newWorkspace} 
									setNewWorkspace={setNewWorkspace} negotiationData={negotiationData} providerList={providers}
									providerObj={providerObj} setProviderObj={setProviderObj} setModalAOpen={setModalAOpen} setModalBOpen={setModalBOpen} 
									resetListNegotiation={resetListNegotiation} getNegotiation={getNegotiation} setLoadingBD={setLoadingBD}

					/>
				</>
			) : (
				<>
					<Typography variant="h5" component="h2" color="primary">
						Lista de Cotizaciones
					</Typography>
					<Button
						type="button"
						fullWidth
						variant="outlined"
						sx={{ mt: '16px', mb: 2 }}
						onClick={() => createNewBudget()}
					>
						Agregar
					</Button>
					{rowsData?.length > 0 && (
						<DataListDynamic
							{...{ rowTitle, rowsData, widthCells }}
							deleteFunction={id => {
								deleteBudget(id).then(() =>
									getNegotiation(negotiationData.id)
								);
							}}
						/>
					)}
				</>
			)}
		</Paper>
		</>
	);
};

export default NegotiationBudgets;
