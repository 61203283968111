import { combineReducers } from 'redux';

import budgets from './reducers/budgets';
import configurations from './reducers/configurations';
import menuOptions from './reducers/menuOptions';
import negotiations from './reducers/negotiations';
import notifications from './reducers/notifications';
import providers from './reducers/providers';
import services from './reducers/services';
import sessions from './reducers/sessions';
import user from './reducers/user';
import workspaces from './reducers/workspaces';

const reducer = combineReducers({
	budgets,
	configurations,
	menuOptions,
	negotiations,
	notifications,
	providers,
	services,
	sessions,
	user,
	workspaces,
});

export default reducer;
