import { useMenu } from '../hooks';
import {
	Paper,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
} from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

const status = {
	active: 'Activo',
	inactive: 'Inactivo',
};

const ServiceDetails = ({ serviceData }) => {
	const { goTo } = useMenu();
	return (
		<Paper variant="outlined">
			<List>
				<ListItem>
					<ListItemText
						primary={
							<Typography variant="h6" component="h2">
								Detalles del servicio
							</Typography>
						}
					/>
					<ListItemIcon>
						<IconButton
							onClick={() =>
								goTo(`/admin/services/${serviceData.id}/edit`)
							}
						>
							<EditOutlined />
						</IconButton>
					</ListItemIcon>
				</ListItem>
				{serviceData.commune && (
					<ListItem>
						Región: {serviceData.commune.region}
						<br />
						Comuna: {serviceData.commune.name}
					</ListItem>
				)}
				<ListItem>Nombre: {serviceData.name}</ListItem>
				<ListItem>Descripción: {serviceData.description}</ListItem>
				<ListItem>Precio: {serviceData.price}</ListItem>
				<ListItem>Mínimo: {serviceData.minimum}</ListItem>
				<ListItem>Máximo: {serviceData.maximum}</ListItem>
				<ListItem>
					Tipo de servicio: {serviceData.service_types?.name}
				</ListItem>
				<ListItem>Estatus: {status[serviceData.status]}</ListItem>
			</List>
		</Paper>
	);
};

export default ServiceDetails;
