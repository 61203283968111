/* Input para crear o actualizar categoria de proveedor */
import { useSelector } from 'react-redux';
import inputsProviderCategorySource from './useConfiguration/inputsProviderCategory';

const useConfiguration = () => {
	/* Lista de TIPOS general filtra las categorias */
	const providerTypes = useSelector(({ configurations }) =>
		configurations?.entity?.providerTypes?.map(type => ({
			name: `${type.name}`,
			value: type.id,
			status: type.status,
		}))
	);

	/* Categoria de proveedor */
	const inputsProviderCategory = inputsProviderCategorySource(
		providerTypes || []
	);

	return {
		inputsProviderCategory,
	};
};

export default useConfiguration;
