import useList from './useList';

/* Definicion de los campos para el formulario del proveedor */
import formInputs from './useWorkspace/inputs';

/* Definicion de los campos para el filtro de busqueda */
import filterInputs from './useWorkspace/inputsFind';

import actions from '../redux/action';
const fetchWorkspaces = actions.fetchWorkspaces;
const removeWorkspace = actions.deleteWorkspace;
const clearWorkspaces = actions.clearWorkspaces;
const fetchTotalWorkspace = actions.fetchTotalWorkspace;
const readWorkspace = actions.readWorkspace;
const pushWorkspace = actions.pushWorkspace;
const putWorkspace = actions.putWorkspace;

const useWorkspace = ({ ID }) => {
	/* Armar el query que se ejecutara segun el filtro aplicado */
	const queryFilter = filter => {
		let queryString = '';

		/* Setea el query para ser usado para cargar más */
		if (filter?.keyword) {
			const text = filter.keyword;
			queryString += `&_where[_or][0][name_contains]=${text}&_where[_or][1][description_contains]=${text}`;
		}

		if (filter?.status) {
			const status = filter.status;
			queryString += `&status=${status}`;
		}

		return queryString;
	};

	const {
		entityList: workspaceList,
		customEntityList: customWorkspaceList,
		totalEntities: totalWorkspaces,
		resetList,
		loadMore,
		createEntity: createWorkspace,
		updateEntity: updateWorkspace,
		deleteEntity: deleteWorkspace,
		requestStatus,
		entityData: workspaceData,
	} = useList({
		statusFn: ({ workspaces }) => workspaces?.status,
		entitiesFn: ({ workspaces }) => workspaces?.entities,
		totalFn: ({ workspaces }) => workspaces.total,
		queryFilter,
		clear: clearWorkspaces,
		fetchTotal: fetchTotalWorkspace,
		fetch: fetchWorkspaces,
		read: readWorkspace,
		push: pushWorkspace,
		put: putWorkspace,
		remove: removeWorkspace,
		ID,
	});

	return {
		workspaceList,
		customWorkspaceList,
		totalWorkspaces,
		resetList,
		loadMore,
		createWorkspace,
		updateWorkspace,
		deleteWorkspace,
		requestStatus,
		workspaceData,
		formInputs,
		filterInputs,
	};
};

export default useWorkspace;
