import { useDispatch, useSelector } from 'react-redux';
import {ArrowBack, ArrowForward, Close, Logout, Edit, Person, Info, ChevronLeft} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../plugins/api';
import loading from '../../assets/loading-pulse.svg';
import { useForm, Controller } from 'react-hook-form';
import { useUser } from "../../hooks";
import * as React from 'react';
import {
	Container,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Typography,
	Button,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Divider,
	Box,
	LinearProgress,
	TextField,
	Stack,
	Card,
	CardContent,
	CardHeader,
	CardActions,
	CardActionArea,
	IconButton,
	Modal,
	Alert,
	Fab,
	Popper,
	Fade,
	Paper,
	Tooltip,
	Popover
} from '@mui/material';
import { printMoney } from '../../plugins/utils';
import moment from 'moment';
import FormDynamic from '../../components/FormDynamic';
import DataListDynamic from '../../components/DataListDynamic';
import { sortData } from '../../plugins/utils';
import { useEffect, useState, useRef  } from 'react';
import { useProvider, useNegotiation } from '../../hooks';

import NegotiationResultSelected from '../../components/NegotiationResultSelected';
import PNavBar from '../../components/public/PNavBar';
import usePNavBar from '../../hooks/usePNavBar';
import PQuotationForm from '../../components/public/PQuotationForm';
import PQuotationCardsProviders from '../../components/public/PQuotationCardsProviders';
import Loader from '../../components/Loader';
import actions from '../../redux/action';
import configurations from '../../redux/actions/configurations';

const BudgetDetails = ({budget,providerList}) => {
	const [servicesProviders,setServicesProviders] = useState([]);
	const [workspacesProviders,setWorkspacesProviders] = useState([]);

	/* POPOVER SERVICE VARs */
	const [PopServiceOpen,SetPopServiceOpen] = useState(false);
	const [PopServiceAnchorEl,SetPopServiceAnchorEl] = useState(false);
	const [PopService,SetPopService] = useState(false);
	const PopServiceHandleClick = (event,service) => {
		SetPopServiceAnchorEl(event.currentTarget);
		SetPopServiceOpen(true);
		SetPopService(service);
	};

	useEffect(()=>{
		if(budget.services){
			let servicesProvidersArray = [];
			budget.services?.forEach((service) => {
				if(servicesProvidersArray.indexOf(service.provider)===-1){
					if(service.provider && service.provider!=="other_services"){
						servicesProvidersArray.push(service.provider);
					}
				}
			});
			servicesProvidersArray.push("other_services");
			setServicesProviders(servicesProvidersArray);
			let workspacesProvidersArray = [];
			budget.workspaces?.forEach((workspace) => {
				if(workspacesProvidersArray.indexOf(workspace.provider)===-1){
					workspacesProvidersArray.push(workspace.provider);
				}
			});
			setWorkspacesProviders(workspacesProvidersArray);
		}
	},[budget])

	return (
			<>
				<Popover
					id={"id"}
					open={PopServiceOpen}
					anchorEl={PopServiceAnchorEl}
					onClose={()=>{SetPopServiceOpen(false)}}
					anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
					}}
				>
					<Card sx={{maxWidth:"400px"}}>
						<CardHeader 
							title={PopService.name}
							sx={{pb:"0px !important"}}
						/>
						<CardContent>
							{PopService.desc}
							<Alert severity="info">El valor de los servicios de alimentos ya incluyen propina del 10%</Alert>
						</CardContent>
					</Card>
				</Popover>
				<Card>
					<CardContent>
						<Typography variant="h6" color="primary">{budget.name}</Typography>
						<Typography variant="h6">Servicios</Typography>
						{servicesProviders?.map((provider) => {
							if(provider){
								const provider_name = providerList?.find(element => element.id === provider)?.name;
								if(budget.services.findIndex(element =>element.provider===provider)>=0){
								return (
									<Paper variant="outlined" sx={{ padding: '16px' , mb:2}}>
										<Typography variant="h6">{provider_name ? provider_name : "Otros servicios"}</Typography>
										{budget.services?.map((service,index) => {
											console.log(index);
											if(service.provider === provider){
												return (
													<Typography variant="body1">
														{service.name ? ((provider_name ? provider_name+" / " : "")+service.name) : "SIN INFORMACIÓN"} {printMoney(service.price ? service.price*service.quantity : "Sin Precios")} {service.price ? "("+printMoney(service.price)+" X "+service.quantity+")" : ""} 
														<IconButton color="secondary" onClick={(e)=>{PopServiceHandleClick(e,{name:service.name,desc:service.description})}}><Info fontSize="small"/></IconButton>
													</Typography>
												);
											}
										})}
									</Paper>
								);}
							}
						})}
						<Typography variant="h6">Salones</Typography>
						{workspacesProviders?.map((provider) => {
							if(provider){
								const provider_name = providerList?.find(element => element.id === provider)?.name;
								if(budget.workspaces.findIndex(element =>element.provider===provider)>=0){
								return (
									<Paper variant="outlined" sx={{ padding: '16px' , mb:2}}>
										<Typography variant="h6">{provider_name ? provider_name : "Proveedor no identificado"}</Typography>
										{budget.workspaces?.map((workspace,index) => {
											console.log(index);
											if(workspace.provider === provider){
												return (
													<Typography variant="body1">
														{workspace.name ? workspace.name : "SIN INFORMACIÓN"} {printMoney(workspace.price ? workspace.price*workspace.quantity : "Sin Precios")} {workspace.price ? "("+printMoney(workspace.price)+" X "+workspace.quantity+")" : ""} 
													</Typography>
												);
											}
										})}
									</Paper>
								);}
							}
						})}
						{/* budget.total_workspaces > 0 ? (budget?.services?.array?.map((service) => { return( <>{ service.provider!=="other_services" &&
							<Typography variant="body1">{service.name ? service.name : "SIN INFORMACIÓN"} {printMoney(service.price ? service.price*service.quantity : "Sin Precios")} {service.price ? "("+printMoney(service.price)+" X "+service.quantity+")" : ""} <Tooltip color="secondary" title={service.description}><Info fontSize="small"/></Tooltip></Typography>
							}</>);})
							):(<Typography variant="body1">Sin información de precios para los servicios requeridos</Typography>)
						}
						{ budget.total_workspaces > 0 ? (<Typography variant="body1">{providerSelected.workspace.obj.name ? providerSelected.workspace.obj.name : "SIN INFORMACIÓN"} {printMoney(providerSelected.workspace.obj.price)} X Día</Typography>)
							:(<Typography variant="body1">Sin información de precios de salones</Typography>)
						*/}
						<br />
						<Typography variant="body1">Total servicios: { budget.total_services > 0 ? printMoney(budget.total_services) : "$0"} </Typography>
						<Typography variant="body1">Total salones: { budget.total_workspaces > 0 ? printMoney(budget.total_workspaces) : "$0"} </Typography>
						<Typography variant="body1">Total IVA: { budget.total_tax > 0 ? printMoney(budget.total_tax) : "$0"} </Typography>
						<Typography  variant="h6">Total IVA: { budget.total > 0 ? printMoney(budget.total) : "$0"} IVA Inc</Typography>
					</CardContent>
				</Card>
			</>
	);
}

const PClientRequestsDetails = () => {
	/*CONCEDERLE ACCESO A LA API A LOS CLIENTES*/

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {goTo} = usePNavBar();

	const [showLoader, setShowLoader] = useState(true);
	const [client, setClient] = useState(null);
	const [modalAOpen, setModalAOpen] = useState(false);
	const handleMAOpen = () => setModalAOpen(true);
	const handleMAClose = () => setModalAOpen(false);
	const [modalBOpen, setModalBOpen] = useState(false);
	const handleMBOpen = () => setModalBOpen(true);
	const handleMBClose = () => setModalBOpen(false);
	const [budgetData, setBudgetData] = useState(null);

	const {providerList} = useProvider({});

	const params = useParams();

	const negotiationID = params.id;

	const {negotiationData} = useNegotiation({ ID:negotiationID });
	
	const localClient= JSON.parse(localStorage.getItem("client"));

	useEffect(() => {
		console.log(negotiationID);
		if(!localClient){
			window.location.assign("/acceso");
		}else{
			setClient(localClient);
			setShowLoader(true);
		}
		
	}, []);

	useEffect(()=>{
		if(negotiationData.name){
			setShowLoader(false);
		}
	},[negotiationData])

	/* Lista de REGIONES Y COMUNAS */
	const regions = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations?.entity?.countries[0]?.data?.map(
				regions => ({
					name: regions.name,
					value: regions.id,
				})
			);
			list = JSON.parse(JSON.stringify(list));
			/* Ordena por name */
			sortData(list, 'name', 'asc');
			return list;
		}

		return [];
	});

	const communes = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations.entity.countries?.[0].data.reduce(
				(communesOptions, region) => {
					const options = region.communes.map(commune => ({
						name: commune.name,
						value: commune.id,
						dependence: {
							name: 'region',
							value: region.id,
						}, // Este campo tiene un padre y los valores dependen de ese padre
					}));

					return [...communesOptions, ...options];
				},
				[]
			);
			list = JSON.parse(JSON.stringify(list));
			/* Ordena por name */
			sortData(list, 'name', 'asc');
			return list;
		}
		return [];
	});
	/* Fin lista */

	const boxModalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
  		maxWidth: '700px',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		pt:8
	  };

	  const clearUser = () =>{
		localStorage.removeItem("client");
		setClient(null);
		goTo("/acceso");
	}

	//FORM CONTROLLERS
	const {register: registerFClientEdit, handleSubmit: handleSubmitFClientEdit} = useForm();
	const [clientConfirmEdit,setClientConfirmEdit] = useState(false);

	const editClient = () => {
		handleMAOpen();
	};

	const submiteFCE = (dataForm) => {
		setShowLoader(true);
		api()
		.put(`/user-infos/${client.id}`, dataForm)
		.then(async ()  =>  {
			client.name = dataForm.name;
			client.telephone = dataForm.telephone;
			client.lastname = dataForm.lastname;
			client.company = dataForm.company;
			setClient(client);
			localStorage.setItem("client", JSON.stringify(client));
			setClientConfirmEdit(true);
			setShowLoader(false);
		});
    };

	return (
		<Container>
			<Loader showLoader={showLoader}/>
		<PNavBar/>
		<Container className="clientQuotation" maxWidth="xl" component="main" sx={{ mb: 4}}>
		<Modal
				open={modalAOpen}
				onClose={handleMAClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxModalStyle}>
				<IconButton color="primary" sx={{position:"absolute",top:"10px",right:"10px"}} aria-label="Cerrar" onClick={handleMAClose}>
					<Close />
				</IconButton>
				<form
					className="form-client-edit"
					onSubmit={handleSubmitFClientEdit(submiteFCE)}
					style={{ width: '100%' }}
				>
				<Stack
					spacing={{ xs: 1, sm: 2, md: 4 }}
				>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Email"
							id="email"
							name="email"
							variant="standard"
							disabled
							defaultValue={client?.user ? client.user.email : ""}
						></TextField>
					</FormControl>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Nombre"
							id="name"
							name="name"
							variant="standard"
							required
							defaultValue={client ? client.name : ""}
							{...registerFClientEdit("name")}
						></TextField>
					</FormControl>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Apellido"
							id="lastname"
							name="lastname"
							variant="standard"
							required
							defaultValue={client ? client.lastname : ""}
							{...registerFClientEdit("lastname")}
						></TextField>
					</FormControl>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Teléfono"
							id="phone"
							name="phone"
							variant="standard"
							required
							defaultValue={client ? client.telephone : ""}
							{...registerFClientEdit("telephone")}
						></TextField>
					</FormControl>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Compañia o Institución"
							id="company"
							name="company"
							variant="standard"
							
							defaultValue={client ? client.company : ""}
							{...registerFClientEdit("company")}
						></TextField>
					</FormControl>
					{clientConfirmEdit && <Alert sx={{mt:2}} severity="success">Se actualizó correctamente su información</Alert>}
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						sx={{mt:2}}
					>
						{"ACTUALIZAR"}
					</Button>
				</Stack>
				</form>
				</Box>
			</Modal>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box sx={{ width: '100%', mb: "20px" }}>
						{ client &&
						<>
							<Card sx={{mb:2, boxShadow: 3}}>
								<CardContent sx={{pt:"10px !important",pb:"10px !important"}}>
									<Stack direction="row" spacing={1} >
										<Person color="primary" sx={{mt:"7px"}}/>
										<Typography variant="h7" gutterBottom component="div" sx={{pt:"7px"}}>
											{client?.name+" "+client?.lastname} 
										</Typography>
										<>
											<Stack direction="row" spacing={1}>
												<Tooltip title="Editar información">
													<IconButton color="secondary" onClick={()=>  editClient()}>
														<Edit />
													</IconButton>
												</Tooltip>
												<Tooltip title="Salir">
													<IconButton color="secondary" onClick={() => clearUser()}>
														<Logout />
													</IconButton>
												</Tooltip>
											</Stack>
										</>
									</Stack>
								</CardContent>
							</Card>
						</>
						}
						<Stack direction={"row"} spacing={1}>
						<IconButton color="primary" size="small" onClick={()=>goTo("/cuenta/eventos")}>
							<ChevronLeft />
						</IconButton>
						<Typography variant="h4" color="primary">
							Eventos
						</Typography>
						</Stack>
						<LinearProgress sx={{height:"2px"}} variant="determinate" value={100} color="secondary" />
					</Box>
					<Grid container justifyContent={"center"} spacing={2}>
						<Grid item xs={12} lg={6}>
							<Card>
								<CardContent>
									<Typography variant="h6" color="primary">{negotiationData.name}</Typography>
									<Typography variant="body1">Formato: {negotiationData.event_formats?.name}</Typography>
									<Typography variant="body1">Tipo: {negotiationData.event_types?.name}</Typography>
									<Typography variant="body1">Región: {regions.find(element => element.value===negotiationData.region)?.name}</Typography>
									<Typography variant="body1">Comuna: {communes.find(element => element.value===negotiationData.commune)?.name}</Typography>
									<Typography variant="body1">Asistentes: {negotiationData.attendees}</Typography>
									<Typography variant="body1">Desde: {moment.parseZone(negotiationData.date_start).format("DD/MM/YYYY")}</Typography>
									<Typography variant="body1">Hasta: {moment.parseZone(negotiationData.date_end).format("DD/MM/YYYY")}</Typography>
									<Typography variant="body1">Detalles: {negotiationData.requirements}</Typography>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Stack direction={"row"} spacing={1}>
							{ negotiationData?.cotizacions && budgetData &&
							<IconButton color="primary" size="small" onClick={()=>setBudgetData(null)}>
								<ChevronLeft />
							</IconButton>}
							<Typography variant="h5" color="primary">
								Cotizaciones
							</Typography>
							</Stack>
							<Divider sx={{mb: 2,mt:"10px"}}/>
								{ negotiationData?.cotizacions && !budgetData &&
									negotiationData.cotizacions.map((cot) => {return(
										<Card>
											<CardActionArea onClick={()=>setBudgetData(cot)}>
												<CardContent>
													<Typography variant="h6" color="primary">{cot.name}</Typography>
													<Typography variant="body1">Total servicios: {cot.total_services > 0 ? printMoney(cot.total_services) : "$0"}</Typography>
													<Typography variant="body1">Total salones: {cot.total_workspaces > 0 ? printMoney(cot.total_workspaces) : "$0"}</Typography>
													<Typography variant="body1">Total IVA: {cot.total_tax > 0 ? printMoney(cot.total_tax) : "$0"}</Typography>
													<Typography variant="body1">Total: {cot.total > 0 ? printMoney(cot.total) : "$0"}</Typography>
												</CardContent>
											</CardActionArea>
										</Card>
									)})
								}
								{ negotiationData?.cotizacions && budgetData &&
									<BudgetDetails budget={budgetData} providerList={providerList}/>
								}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
		</Container>
		
	);

};

export default PClientRequestsDetails;