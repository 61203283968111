import { useParams } from 'react-router-dom';
import { Grid, Typography, Paper } from '@mui/material';
import FormDynamic from '../components/FormDynamic';
import { useMenu, useProvider } from '../hooks';
import loading from '../assets/loading-pulse.svg';
import ProviderDetails from '../components/ProviderDetails';
import ProviderDetailsServices from '../components/ProviderDetailsServices';
import ProviderDetailsWorkspaces from '../components/ProviderDetailsWorkspaces';
import ProviderDetailsBankData from '../components/ProviderDetailsBankData';
import ProviderDetailsBilling from '../components/ProviderDetailsBilling';
import ProviderDetailsContacts from '../components/ProviderDetailsContacts';
import Breadcrumbs from '../components/Breadcrumbs';

const ProviderSummary = ({ edit }) => {
	const params = useParams();
	/* ID pasado por URL del usuario */
	const ID = params.id;

	const { goTo } = useMenu();

	const {
		providerData,
		formInputs: inputs,
		categoriesForType,
		createProvider,
		updateProvider,
		requestStatus: status,
		resetList,
	} = useProvider({ ID });

	const formProps = {
		validateFunction: () => {},
		inputs,
		cancelFunction: () => goTo('/admin/providers'),
	};

	const defaultValues = {
		name: '',
		slug: '',
		region: '',
		url_iframe: '',
		email: '',
		commune: '',
		address: '',
		description: '',
		event_types: [], //debe ser un array por acepta multiples valores
		provider_categories: [], //debe ser un array por acepta multiples valores
		provider_type: '',
		status: 'inactive',
		comments: '',
	};

	const formPropsNew = {
		...formProps,
		submitFunction: data => {
			/* Enviando solo las categorias que coinciden con la dependencia */
			data.provider_categories = data.provider_categories.filter(
				category =>
					categoriesForType[data.provider_type].includes(category)
			);

			createProvider(data).then(() => {
				/* Se refresca la lista con el dato nuevo */
				resetList();
				goTo('/admin/providers');
			});
		},
		defaultValues,
		buttonText: 'Crear',
	};

	const attUpdated = {};

	/* Si estan definidos estos key, solo se usan sus id para los input select */
	if (providerData.event_types)
		attUpdated.event_types =
			providerData.event_types?.map(event => event.id) || [];
	if (providerData.provider_categories)
		attUpdated.provider_categories =
			providerData.provider_categories?.map(category => category.id) ||
			[];
	if (providerData.provider_type)
		attUpdated.provider_type = providerData.provider_type.id || '';

	const providerDataEdit = {
		name: providerData.name,
		slug: providerData.slug,
		url_iframe: providerData.url_iframe,
		email: providerData.email,
		region: providerData.region,
		commune: providerData.commune,
		address: providerData.address,
		description: providerData.description,
		event_types: providerData.event_types,
		provider_categories: providerData.provider_categories,
		provider_type: providerData.provider_type,
		status: providerData.status,
		comments: providerData.comments,
	};

	const formPropsEdit = {
		...formProps,
		submitFunction: data => {
			/* Enviando solo las categorias que coinciden con la dependencia */
			data.provider_categories = data.provider_categories.filter(
				category =>
					categoriesForType[data.provider_type].includes(category)
			);

			updateProvider(ID, data).then(() => goTo(`/admin/providers/${ID}`));
		},
		defaultValues: { ...defaultValues, ...providerDataEdit, ...attUpdated },
		buttonText: 'Actualizar',
		cancelFunction: () => goTo(`/admin/providers/${ID}`),
	};

	const onClick = () => goTo('/admin/providers');
	const links = [{ name: 'Lista de proveedores', onClick }];

	const linksDetails = [...links, { name: 'Detalles' }];
	const linksNew = [...links, { name: 'Crear proveedor' }];

	const onClickEdit = () => goTo(`/admin/providers/${providerData.id}`);
	const linksEdit = [
		...links,
		{ name: 'Detalles', onClick: onClickEdit },
		{ name: `Actualizando a ${providerData.name}` },
	];

	return ID === 'new' ? (
		<>
			<Breadcrumbs links={linksNew} />
			{status?.loading === 'pending' && (
				<img
					src={loading}
					width="50px"
					style={{ margin: 'auto', display: 'block' }}
					height="50px"
					alt="Cargando ..."
				/>
			)}
			<FormDynamic {...formPropsNew} />
		</>
	) : edit && providerData.id ? (
		<>
			<Breadcrumbs links={linksEdit} />
			{status?.loading === 'pending' && (
				<img
					src={loading}
					width="50px"
					style={{ margin: 'auto', display: 'block' }}
					height="50px"
					alt="Cargando ..."
				/>
			)}
			<FormDynamic {...formPropsEdit} />
		</>
	) : providerData.id ? (
		<>
			<Breadcrumbs links={linksDetails} />
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12}>
					<Typography variant="h2" component="h1">
						{providerData.name}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Paper variant="outlined">
								<ProviderDetails {...{ providerData }} edit />
							</Paper>
						</Grid>
						<Grid item xs={12} >
							<ProviderDetailsContacts {...{ providerData }} />
						</Grid>
						<Grid item xs={12} >
							<ProviderDetailsBilling {...{ providerData }} />
						</Grid>
						<Grid item xs={12} >
							<ProviderDetailsBankData {...{ providerData }} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<ProviderDetailsServices {...{ providerData }} />
						</Grid>
						<Grid item xs={12} sm={6}>
							<ProviderDetailsWorkspaces {...{ providerData }} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	) : (
		<img
			src={loading}
			style={{ margin: 'auto', display: 'block' }}
			width="50px"
			height="50px"
			alt="Cargando ..."
		/>
	);
};

export default ProviderSummary;
