import { useSelector } from 'react-redux';
import useList from './useList';

import actions from '../redux/action';
const fetchBudgets = actions.fetchBudgets;
const removeBudget = actions.deleteBudget;
const clearBudgets = actions.clearBudgets;
const fetchTotalBudget = actions.fetchTotalBudget;
const readBudget = actions.readBudget;
const pushBudget = actions.pushBudget;
const putBudget = actions.putBudget;

const useBudget = ({ ID }) => {
	/* Funciones para la administracion de negociaciones */
	/* Armar el query que se ejecutara segun el filtro aplicado */
	const queryFilter = filter => {
		let queryString = '';

		/* Setea el query para ser usado para cargar más */
		if (filter?.keyword) {
			const text = filter.keyword;
			queryString += `&_where[_or][0][name_contains]=${text}&_where[_or][1][requirements_contains]=${text}`;
		}

		if (filter?.status) {
			const status = filter.status;
			queryString += `&status=${status}`;
		}

		return queryString;
	};

	const {
		entityList: budgetList,
		customEntityList: customBudgetList,
		totalEntities: totalBudgets,
		resetList,
		loadMore,
		createEntity: createBudget,
		updateEntity: updateBudget,
		deleteEntity: deleteBudget,
		requestStatus,
		entityData: budgetData,
	} = useList({
		statusFn: ({ budgets }) => budgets?.status,
		entitiesFn: ({ budgets }) => budgets?.entities,
		totalFn: ({ budgets }) => budgets.total,
		queryFilter,
		clear: clearBudgets,
		fetchTotal: fetchTotalBudget,
		fetch: fetchBudgets,
		read: readBudget,
		push: pushBudget,
		put: putBudget,
		remove: removeBudget,
		ID,
	});

	return {
		budgetList,
		customBudgetList,
		totalBudgets,
		resetList,
		loadMore,
		createBudget,
		updateBudget,
		deleteBudget,
		requestStatus,
		budgetData
	};
};

export default useBudget;
