import useList from './useList';

/* Definicion de los campos para el formulario de datos bancarios */
import inputsFn from './useProviderBilling/inputs';

import { useSelector} from 'react-redux';

import { sortData } from '../plugins/utils';

import actions from '../redux/action';
const removeProviderBillings = actions.deleteProviderBillings;
const pushProviderBillings = actions.pushProviderBillings;
const putProviderBillings = actions.putProviderBillings;



const useProviderBilling = ({ ID }) => {
	/* Lista de REGIONES Y COMUNAS */
const regions = useSelector(({ configurations }) => {
	if (configurations.entity?.countries?.[0]) {
		let list = configurations?.entity?.countries[0]?.data?.map(
			regions => ({
				name: regions.name,
				value: regions.id,
			})
		);
		list = JSON.parse(JSON.stringify(list));
		/* Ordena por name */
		sortData(list, 'name', 'asc');
		return list;
	}

	return [];
});

const communes = useSelector(({ configurations }) => {
	if (configurations.entity?.countries?.[0]) {
		let list = configurations.entity.countries?.[0].data.reduce(
			(communesOptions, region) => {
				const options = region.communes.map(commune => ({
					name: commune.name,
					value: commune.id,
					dependence: {
						name: 'region',
						value: region.id,
					}, // Este campo tiene un padre y los valores dependen de ese padre
				}));

				return [...communesOptions, ...options];
			},
			[]
		);
		list = JSON.parse(JSON.stringify(list));
		/* Ordena por name */
		sortData(list, 'name', 'asc');
		return list;
	}
	return [];
});

/* Campos del formulario para crear o editar, con las opciones activas de categorias y tipos */
const formInputs = inputsFn({
	regions,
	communes,
});

	const {
		createEntity: createProviderBillings,
		updateEntity: updateProviderBillings,
		deleteEntity: deleteProviderBillings,
	} = useList({
		statusFn: () => {},
		entitiesFn: () => {},
		totalFn: () => {},
		push: pushProviderBillings,
		put: putProviderBillings,
		remove: removeProviderBillings,
		ID,
	});

	return {
		createProviderBillings,
		updateProviderBillings,
		deleteProviderBillings,
		formInputs,
	};
};

export default useProviderBilling;
