import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({ title, msg, fnToDo, active, children }) {
	const handleClose = () => {
		fnToDo.closeWindow();
	};

	return (
		<div>
			<Dialog
				open={active}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{msg}
					</DialogContentText>
				</DialogContent>
				<DialogContent>{children}</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancelar</Button>
					<Button onClick={() => fnToDo.action()} autoFocus>
						Hacerlo
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
