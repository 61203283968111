import api from '../../plugins/api';
import { mat, asyncMac } from '../utils';

/* Sesiones en terapias del usuario */
const asyncSessions = mat('sessions');
const [setPending, setFulfilled, setError] = asyncMac(asyncSessions);

/* Listado de los sesiones o sesion en especifico */
const fetchSessions = idTherapy => async dispatch => {
	/* Obtiene todas las sesiones de una terapia o una sesion en especifico */
	const query = `?patient_therapy=${idTherapy}&_limit=1000&_sort=date:desc`;

	dispatch(setPending());

	/* Obtiene todos las sesiones de la terapia pasada por URL */
	return api()
		.get(`/therapy-sessions/${query}`)
		.then(({ data }) => {
			dispatch(setFulfilled(data));
		})
		.catch(error => dispatch(setError(error)));
};

/* Elimando una sesion a la terapia del paciente */
const deleteSessions = idSessions => async (dispatch, getState) => {
	const state = getState();
	dispatch(setPending());
	return api()
		.delete(`/therapy-sessions/${idSessions}`)
		.then(() => {
			let sessions = state.sessions.entities.filter(
				item => item.id !== idSessions
			);
			dispatch(setFulfilled(sessions));
		})
		.catch(error => dispatch(setError(error)));
};

const sessions = {
	fetchSessions,
	deleteSessions,
};

export default sessions;
