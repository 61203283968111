const inputs = ({
	eventTypes,
	providerCategories,
	providerTypes,
	regions,
	communes,
}) => [
	{
		type: 'text',
		label: 'Nombre',
		name: 'name',
		placeholder: 'Ej: Google',
		required: true,
		autoFocus: true,
	},
	{
		type: 'text',
		label: 'Slug',
		name: 'slug',
		placeholder: 'Ej: google',
	},
	{
		type: 'text',
		label: 'URL INFO S&E.CL',
		name: 'url_iframe',
		placeholder: 'Ej: google',
	},
	{
		type: 'email',
		label: 'Email principal',
		name: 'email',
		placeholder: 'Ej: nombre@gmail.com',
	},
	{
		type: 'select',
		label: 'Región',
		name: 'region',
		options: regions,
		required: true,
	},
	{
		type: 'select',
		label: 'Comuna',
		name: 'commune',
		options: communes,
		required: true,
	},
	{
		type: 'text',
		label: 'Dirección',
		name: 'address',
		placeholder: 'Ej: Irarrázaval 3550, Ñuñoa, Región Metropolitana, Chile',
	},
	{
		type: 'text',
		label: 'Descripción',
		name: 'description',
		placeholder: 'Ej: Que haga referencia a la empresa',
	},
	{
		type: 'select',
		label: 'Tipo de proveedor',
		name: 'provider_type',
		options: providerTypes,
		required: true,
	},
	{
		type: 'select',
		label: 'Categoría',
		name: 'provider_categories',
		options: providerCategories,
		multiple: true,
		required: true,
	},
	{
		type: 'select',
		label: 'Tipos de eventos',
		name: 'event_types',
		options: eventTypes,
		multiple: true,
		required: true,
	},
	{
		type: 'select',
		label: 'Estatus',
		name: 'status',
		required: true,
		options: [
			{ name: 'Activo', value: 'active' },
			{ name: 'Inactivo', value: 'inactive' },
		],
	},
	{
		type: 'text',
		multiline: true,
		label: 'Comentarios',
		name: 'comments',
		placeholder: 'Proveedor en negociación...',
	},
];

export default inputs;
