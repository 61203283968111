import { Snackbar, Alert } from '@mui/material';
import { useState } from 'react';
/* severity=[success,error] */
const AlertNotification = ({ severity, duration, message, funcClose }) => {
	const [open, setOpen] = useState(true);

	const handleClose = () => {
		setOpen(false);
		if (funcClose) funcClose();
	};

	const readMessage = message => {
		if (typeof message === 'object') {
			/* Formato de error de */
			if (Array.isArray(message)) {
				return message.reduce(
					(mss, item) =>
						item.message
							? mss.concat(' ', item.message)
							: readMessage(item.messages),
					''
				);
			} else if (message.errors) {
				const errors = message.errors;
				return Object.keys(message.errors).reduce(
					(toSend, key, index) =>
						index === 0
							? `El campo ${key}: ${errors[key]}`
							: `${toSend}. El campo ${key}: ${errors[key]}`,
					''
				);
			}
		} else if (typeof message === 'string') {
			return message;
		}

		return '';
	};

	return (
		<Snackbar
			{...{
				open,
				autoHideDuration: duration || 6000,
				anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
				onClose: handleClose,
			}}
		>
			<Alert
				onClose={handleClose}
				severity={severity || 'error'}
				sx={{ width: '100%' }}
			>
				{readMessage(message)}
			</Alert>
		</Snackbar>
	);
};

export default AlertNotification;
