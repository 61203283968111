import { useSelector } from 'react-redux';
import { useState, useRef } from 'react';
import { sortData, normalizeText } from '../plugins/utils';
import {
	InputBase,
	Paper,
	Box,
	List,
	ListItem,
	IconButton,
} from '@mui/material';
import { Search, DoDisturbAltOutlined } from '@mui/icons-material';

const textToSearch = text => normalizeText(text).toLocaleLowerCase();

const SelectCommune = ({ setCommune, communeDefault }) => {
	const communes = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations.entity.countries?.[0].data.reduce(
				(communesOptions, region) => {
					const options = region.communes.map(commune => ({
						id: commune.id,
						name: commune.name,
						region: region.name,
					}));

					return [...communesOptions, ...options];
				},
				[]
			);
			list = JSON.parse(JSON.stringify(list));
			/* Ordena por nombre */
			sortData(list, 'name', 'asc');
			/* Ordena por region */
			sortData(list, 'region', 'asc');
			return list;
		}
		return [];
	});

	const anchorRef = useRef(null);
	const [selected, setSelected] = useState(communeDefault || {});
	const [keyword, setKeyword] = useState('');
	const [result, setResult] = useState([]);

	const queryCommune = text => {
		setKeyword(text);
		if (text.length > 0) {
			const textSearch = textToSearch(text);
			let result = communes.filter(commune =>
				textToSearch(commune.name).includes(textSearch)
			);
			setResult(result);
		}
	};

	const onClickCommune = commune => {
		setSelected(commune);
		setCommune(commune || '');
		setKeyword('');
	};

	return (
		<Box sx={{ pl: 1, pr: 1 }}>
			<Paper
				variant="outlined"
				component="form"
				ref={anchorRef}
				sx={{ width: '100%' }}
			>
				<List>
					{selected.name ? (
						<ListItem
							secondaryAction={
								<IconButton
									edge="end"
									onClick={() => onClickCommune({})}
								>
									<DoDisturbAltOutlined />
								</IconButton>
							}
						>
							{selected.name}
						</ListItem>
					) : (
						<>
							<ListItem sx={{ display: 'flex' }}>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder="Buscar comuna"
									onChange={e => {
										queryCommune(e.target.value);
									}}
								/>
								<Search />
							</ListItem>
							{keyword.length > 0 && (
								<ListItem>{`${result.length} resultados.`}</ListItem>
							)}
							{result.map(option => (
								<ListItem
									key={option.id}
									onClick={() => onClickCommune(option)}
								>
									<Box
										component="span"
										sx={{
											padding: '2px 5px',
											background: '#dedede',
											marginRight: '15px',
											fontSize: '12px',
											position: 'relative',
											'&::after': {
												content: `''`,
												position: 'absolute',
												right: '-12px',
												top: '0px',
												width: 0,
												height: 0,
												borderTop:
													'11px solid transparent',
												borderLeft:
													'12px solid #dedede',
												borderBottom:
													'11px solid transparent',
											},
										}}
									>
										{option.region}
									</Box>{' '}
									{option.name}
								</ListItem>
							))}
						</>
					)}
				</List>
			</Paper>
		</Box>
	);
};

export default SelectCommune;
