import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import AlertDialog from '../components/AlertDialog';
import { CardContent, Card, Grid, Typography, FormControl, TextField, Button, CardActionArea, Backdrop, IconButton } from '@mui/material';
import FormDynamic from '../components/FormDynamic';
import moment from 'moment';
import { useMenu, useNegotiation } from '../hooks';
import loading from '../assets/loading-pulse.svg';
import NegotiationDetails from '../components/NegotiationDetails';
import NegotiationBudgets from '../components/NegotiationBudgets';
import Breadcrumbs from '../components/Breadcrumbs';
import api from '../plugins/api';
import { useEffect } from 'react';
import { Delete, Download } from '@mui/icons-material';

const NegotiationSummary = ({ edit }) => {
	const [loadingBD,setLoadingBD] = useState(true);
	const [providers, setProviders] = useState(null);
	const [customerData,setCustomerData] = useState({});

	const params = useParams();
	/* ID pasado por URL del servicio */
	const ID = params.id;

	const { goTo } = useMenu();

	const {
		negotiationData,
		formInputs: inputs,
		createNegotiation,
		updateNegotiation,
		requestStatus: statusNegotiation,
		resetList:resetListNegotiation,
		getNegotiation
	} = useNegotiation({ ID });

	useEffect(()=>{
		if(negotiationData?.customer?.id){
			api()
			.get(`/user-infos/`+negotiationData?.customer?.user_info)
			.then( ( {data} ) => {
				setCustomerData(data);
				setLoadingBD(false);
			});
		}
	},[negotiationData])
	

	const formProps = {
		validateFunction: () => {},
		inputs,
		cancelFunction: () => goTo('/admin/negotiations'),
	};

	const formPropsNew = {
		...formProps,
		submitFunction: data => {
			createNegotiation({ ...data }).then(() => {
				/* Se refresca la lista con el dato nuevo */
				resetListNegotiation();
				goTo('/admin/negotiations');
			});
		},
		defaultValues: {
			name: '',
			attendees: '',
			date_start: '',
			date_end: '',
			requirements: '',
			total: '0',
			total_sye: '0',
			total_tax: '0',
			event_types: '',
			event_formats: '',
			status: 'inactive',
		},
		buttonText: 'Crear',
	};

	const [negotiationDataEdit, setNegotiationDataEdit] = useState({});

	useEffect(()=>{
		if(negotiationData.name){
			let negotiationNew = {}
			Object.assign(negotiationNew,negotiationData);
			negotiationNew.event_types = negotiationNew.event_types?._id;
			negotiationNew.event_formats = negotiationNew.event_formats?._id;
			negotiationNew.date_start = moment.parseZone(negotiationNew.date_start).format('YYYY-MM-DD');
			negotiationNew.date_end = moment.parseZone(negotiationNew.date_end).format('YYYY-MM-DD');
			setNegotiationDataEdit(negotiationNew);
		}
	},[negotiationData]);

	const formPropsEdit = {
		...formProps,
		submitFunction: data => {
			updateNegotiation(ID, { ...data }).then(() =>
				goTo(`/admin/negotiations/${ID}`)
			);
		},
		defaultValues: negotiationDataEdit,
		buttonText: 'Actualizar',
		cancelFunction: () => goTo(`/admin/negotiations/${ID}`),
	};

	const onClick = () => goTo('/admin/negotiations');
	const links = [{ name: 'Lista de negociaciones', onClick }];

	const linksDetails = [...links, { name: 'Detalles' }];
	const linksNew = [...links, { name: 'Crear negociación' }];

	const onClickEdit = () => goTo(`/admin/negotiations/${negotiationData.id}`);
	const linksEdit = [
		...links,
		{ name: 'Detalles', onClick: onClickEdit },
		{ name: `Actualizando a ${negotiationData.name}` },
	];

	/* FORM FILES */
	const {register:registerFiles, handleSubmit:handleSFiles, reset:resetFiles} = useForm();
	async function submiteFiles(dataForm){
		setLoadingBD(true);
		console.log(dataForm.files);
		console.log(dataForm.files[0]);
		const form = new FormData();
		form.append("files",dataForm.files[0]);
		api()
			.post(`/upload`, form)
			.then(({ data })  =>  {
				console.log(data[0].url);
				let files = negotiationData.files ? negotiationData.files : []; 
				const id_n = negotiationData._id;
				files.push({name:dataForm.name,file:data[0].url});
				console.log(files);
				updateNegotiation(id_n, { files:files }).then(() => {
					getNegotiation(id_n);
					resetFiles();
					setLoadingBD(false);
				});
			})
	}

	const [fileToDelete,setFileToDelete] = useState(null);

	const deleteFile = (index) =>{
		setLoadingBD(true);
		let files = negotiationData.files ? negotiationData.files : []; 
		const id_n = negotiationData._id;
		files.splice(index,1);
		console.log(files);
		updateNegotiation(id_n, { files:files }).then(() => {
			getNegotiation(id_n);
			setLoadingBD(false);
		});
	}

	return (
	<>
		<Backdrop
			sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={loadingBD}
		>
			<img
				src={loading}
				width="50px"
				style={{ margin: 'auto', display: 'block' }}
				height="50px"
				alt="Cargando ..."
			/>
		</Backdrop>
	{ID === 'new' ? (
		<>
			<Breadcrumbs links={linksNew} />
			{statusNegotiation?.loading === 'pending' && (
				<img
					src={loading}
					width="50px"
					style={{ margin: 'auto', display: 'block' }}
					height="50px"
					alt="Cargando ..."
				/>
			)}
			<FormDynamic {...formPropsNew} />
		</>
	) : edit && negotiationData.id ? (
		<>
			<Breadcrumbs links={linksEdit} />
			{statusNegotiation?.loading === 'pending' && (
				<img
					src={loading}
					width="50px"
					style={{ margin: 'auto', display: 'block' }}
					height="50px"
					alt="Cargando ..."
				/>
			)}
			<FormDynamic {...formPropsEdit} />
		</>
	) : negotiationData.id ? (
		<>
			<Breadcrumbs links={linksDetails} />
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12}>
					<Typography variant="h2" component="h1">
						{negotiationData.name}
					</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<NegotiationDetails
						{...{
							negotiationData,
						}}
					/>
					<Card sx={{mt:3}}>
						<CardContent>
							<Typography variant="h6">Cliente</Typography>
							<Typography>Nombre: {customerData.name}</Typography>
							<Typography>Apellido(s): {customerData.lastname}</Typography>
							<Typography>Email: {customerData.user?.email}</Typography>
							<Typography>Teléfono: {customerData.telephone}</Typography>
							<Typography>Compañia / Institución: {customerData.company}</Typography>
						</CardContent>
					</Card>
					<Card sx={{mt:3}}>
						<CardContent>
							<Typography variant="h6">Archivos</Typography>
							<form
								onSubmit={handleSFiles(submiteFiles)}
								style={{ width: '100%'}}
							>
								<FormControl fullWidth variant="outlined" sx={{alignContent:"center",mt:3}}>
									<TextField
										label="Nombre"
										variant="outlined"
										required
										{...registerFiles("name")}
									></TextField>
								</FormControl>
								<FormControl fullWidth variant="outlined" sx={{alignContent:"center",mt:2}}>
									<TextField
										label="Cargar archivo"
										variant="outlined"
										type="file"
										required
										{...registerFiles("files")}
										{...{
											InputLabelProps: {shrink: true }
											}
										}
									></TextField>
								</FormControl>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="secondary"
									sx={{mt:2}}
								>
									{"Cargar"}
								</Button>
								{negotiationData.files?.map((file,index)=>(
									<Card sx={{mt:3}}>
										<CardContent>
											<Grid container>
												<Grid item xs={10}>
													<Typography>{file?.name}</Typography>
												</Grid>
												<Grid item xs={1}>
													<IconButton 
														color="primary"
														href={file?.file}
														target="_blank"
														download
													>
														<Download />
													</IconButton>
												</Grid>
												<Grid item xs={1}>
													<IconButton onClick={()=>setFileToDelete({name:file.name,index:index})} color="secondary"><Delete /></IconButton>
												</Grid>
											</Grid>
												
										</CardContent>
									</Card>
								))}
							</form>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<NegotiationBudgets negotiationData={negotiationData} providers={providers} setProviders={setProviders} 
					resetListNegotiation={resetListNegotiation} getNegotiation={getNegotiation} statusNegotiation={statusNegotiation}
					loadingBD={loadingBD} setLoadingBD={setLoadingBD}/>
				</Grid>
			</Grid>
		</>
	) : (
		<img
			src={loading}
			style={{ margin: 'auto', display: 'block' }}
			width="50px"
			height="50px"
			alt="Cargando ..."
		/>
	)}
	<AlertDialog
		title={`Eliminar archivo`}
		msg={
			`Confirme para eliminar el archivo "`+fileToDelete?.name+`".`
		}
		fnToDo={{
			action: () => {
				deleteFile(fileToDelete.index);
				setFileToDelete(null);
			},
			closeWindow: () => {
				/* Se reinicia todo */
				setFileToDelete(null);
			},
		}}
		active={fileToDelete? true : false}
	/>
	</>);
};

export default NegotiationSummary;
