import api from '../../plugins/api';

/* Agregando un nuevo dato bancario */
const pushProviderBillings = form => () =>
	api().post(`/provider-billing-informations`, form);

/* Actualizando un dato bancario */
const putProviderBillings = (id, form) => () =>
	api().put(`/provider-billing-informations/${id}`, form);

/* Elimando un dato bancario */
const deleteProviderBillings = id => () =>
	api().delete(`/provider-billing-informations/${id}`);

const providerBillings = {
	pushProviderBillings,
	putProviderBillings,
	deleteProviderBillings,
};

export default providerBillings;
