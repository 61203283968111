import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import {
	InputBase,
	Paper,
	Box,
	List,
	ListItem,
	IconButton,
} from '@mui/material';
import { Search, DoDisturbAltOutlined } from '@mui/icons-material';

import actions from '../redux/action';

const fetchProviders = actions.fetchProviders;
const clearProviders = actions.clearProviders;
const fetchTotalProvider = actions.fetchTotalProvider;

const SelectProvider = ({ setProvider, providerDefault }) => {
	const dispatch = useDispatch();
	const status = useSelector(({ providers }) => providers.status);
	const totalEntities = useSelector(({ providers }) => providers.total);
	const entities = useSelector(({ providers }) => providers.entities);
	const anchorRef = useRef(null);
	const [selected, setSelected] = useState(providerDefault || {});
	const [keyword, setKeyword] = useState('');

	/* Cargar lista de proveedores */
	useEffect(() => {
		dispatch(clearProviders());

		return () => {
			dispatch(clearProviders());
		};
	}, [dispatch]);

	const queryProvider = text => {
		const query = `&_where[_or][0][name_contains]=${text}&_where[_or][1][address_contains]=${text}&_where[_or][2][description_contains]=${text}&_where[_or][3][telephone_contains]=${text}`;
		setKeyword(text);
		dispatch(clearProviders());
		if (text.length > 0) {
			dispatch(fetchProviders({ query, limit: 50 }));
			dispatch(fetchTotalProvider({ query }));
		}
	};

	const onClickProvider = provider => {
		setSelected(provider);
		setProvider(provider.id || '');
		setKeyword('');
		/* Limpiar los resultados de busqueda */
		if (!provider.name) dispatch(clearProviders());
	};

	const findStatus = () => {
		if (status.loading === 'succeded')
			return `${totalEntities} resultados.`;
		if (status.loading === 'pending')
			return `Buscando coincidencias con "${keyword}".`;

		/* Error */
		return `Ha ocurrido un erro.`;
	};

	return (
		<Box sx={{ pl: 1, pr: 1 }}>
			<Paper
				variant="outlined"
				component="form"
				ref={anchorRef}
				sx={{ width: '100%' }}
			>
				<List>
					{selected.name ? (
						<ListItem
							secondaryAction={
								<IconButton
									edge="end"
									onClick={() => onClickProvider({})}
								>
									<DoDisturbAltOutlined />
								</IconButton>
							}
						>
							{selected.name}
						</ListItem>
					) : (
						<>
							<ListItem sx={{ display: 'flex' }}>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder="Buscar proveedor"
									onChange={e => {
										queryProvider(e.target.value);
									}}
								/>
								<Search />
							</ListItem>
							{keyword.length > 0 && (
								<ListItem>{findStatus()}</ListItem>
							)}
							{entities.map(option => (
								<ListItem
									key={option.id}
									onClick={() => onClickProvider(option)}
								>
									{option.name}
								</ListItem>
							))}
						</>
					)}
				</List>
			</Paper>
		</Box>
	);
};

export default SelectProvider;
