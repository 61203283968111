import '../css/loader.css';
import logo from '../assets/logo_fav.png';
import {Modal} from '@mui/material';

const Loader = ({showLoader}) => { 
    return (
        <Modal
        hideBackdrop
        disableAutoFocus
        disableEnforceFocus
        disableEscapeKeyDown
        sx={{backgroundColor:"#ffffffb5"}}
        open={showLoader}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="div-loader" >
                <img className='loader' src={logo} />
            </div>
        </Modal>
    )
}

export default Loader;