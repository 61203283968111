import { useSelector } from 'react-redux';
import useList from './useList';

/* Definicion de los campos para el formulario del proveedor */
import inputsFn from './useService/inputs';

/* Definicion de los campos para el filtro de busqueda */
import filterInputs from './useService/inputsFind';

import actions from '../redux/action';
const fetchServices = actions.fetchServices;
const removeService = actions.deleteService;
const clearServices = actions.clearServices;
const fetchTotalService = actions.fetchTotalService;
const readService = actions.readService;
const pushService = actions.pushService;
const putService = actions.putService;

const useService = ({ ID }) => {
	/* Armar el query que se ejecutara segun el filtro aplicado */
	const queryFilter = filter => {
		let queryString = '';

		/* Setea el query para ser usado para cargar más */
		if (filter?.keyword) {
			const text = filter.keyword;
			queryString += `&_where[_or][0][name_contains]=${text}&_where[_or][1][description_contains]=${text}`;
		}

		if (filter?.status) {
			const status = filter.status;
			queryString += `&status=${status}`;
		}

		return queryString;
	};

	const {
		entityList: serviceList,
		customEntityList: customServiceList,
		totalEntities: totalServices,
		resetList,
		loadMore,
		createEntity: createService,
		updateEntity: updateService,
		deleteEntity: deleteService,
		requestStatus,
		entityData: serviceData,
	} = useList({
		statusFn: ({ services }) => services?.status,
		entitiesFn: ({ services }) => services?.entities,
		totalFn: ({ services }) => services.total,
		queryFilter,
		clear: clearServices,
		fetchTotal: fetchTotalService,
		fetch: fetchServices,
		read: readService,
		push: pushService,
		put: putService,
		remove: removeService,
		ID,
	});

	/* Lista de TIPOS general filtra las categorias */
	const serviceTypes = useSelector(({ configurations }) =>
		configurations?.entity?.serviceTypes?.map(type => ({
			name: type.name,
			value: type.id,
			status: type.status,
			provider_categories: type.provider_categories?.id,
		}))
	);

	/* Campos del formulario para crear o editar, con las opciones activas */
	const formInputs = serviceTypes
		? inputsFn({
				serviceTypes: serviceTypes?.filter(
					type => type.status === 'active'
				),
		  })
		: [];

	return {
		serviceList,
		customServiceList,
		totalServices,
		resetList,
		loadMore,
		createService,
		updateService,
		deleteService,
		requestStatus,
		serviceData,
		formInputs,
		filterInputs,
	};
};

export default useService;
