import {
	Container,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@mui/material';

import FormDynamic from './FormDynamic';
import SelectProvider from '../components/SelectProvider';
import SelectCommune from '../components/SelectCommune';

const ServiceForm = ({
	serviceData,
	setCommune,
	setProvider,
	assignTo,
	setAssignTo,
	...props
}) => {
	const onChange = e => {
		setAssignTo(e.target.value);
		setCommune('');
		setProvider('');
	};

	return (
		<>
			<Container maxWidth="xl" sx={{ pl: '16px', pr: '16px' }}>
				<FormControl>
					<FormLabel id="demo-row-radio-buttons-group-label">
						Asignar servicio a
					</FormLabel>
					<RadioGroup
						row
						aria-labelledby="demo-row-radio-buttons-group-label"
						name="row-radio-buttons-group"
						value={assignTo}
						onChange={onChange}
					>
						<FormControlLabel
							value="provider"
							control={<Radio />}
							label="Proveedor"
						/>
						<FormControlLabel
							value="commune"
							control={<Radio />}
							label="Comuna"
						/>
					</RadioGroup>
				</FormControl>
				{assignTo === 'provider' && (
					<SelectProvider
						setProvider={setProvider}
						providerDefault={serviceData.provider}
					/>
				)}

				{assignTo === 'commune' && (
					<SelectCommune
						setCommune={setCommune}
						communeDefault={serviceData.commune}
					/>
				)}
			</Container>
			<FormDynamic {...props} />
		</>
	);
};

export default ServiceForm;
