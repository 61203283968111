import { useDispatch } from 'react-redux';
import { Typography, Box } from '@mui/material';
import FormDynamic from '../components/FormDynamic';

const FormCreateAndUpdate = ({
	defaultValues,
	changeItem,
	endpoint,
	pushFunction,
	putFunction,
	formProps,
}) => {
	const dispatch = useDispatch();

	const formPropsNew = {
		...formProps,
		submitFunction: data => {
			data.endpoint = endpoint;
			dispatch(pushFunction(data));
			changeItem(null);
		},
		defaultValues,
		buttonText: 'Crear',
	};

	const formPropsUpdate = {
		...formProps,
		submitFunction: data => {
			data.endpoint = endpoint;
			dispatch(putFunction(data));
			changeItem(null);
		},
		defaultValues,
		buttonText: 'Actualizar',
		cancelFunction: () => changeItem(null),
	};

	return (
		<Box>
			{defaultValues?.id && (
				<>
					<Typography variant="subtitle1" component="h2">
						Formulario para actualizar
					</Typography>
					<Typography variant="body2" component="p" color="secondary">
						Cambia los datos del registro seleccionado.
					</Typography>
					<FormDynamic {...formPropsUpdate} />
				</>
			)}
			{defaultValues?.name === '' && (
				<>
					<Typography variant="subtitle1" component="h2">
						Formulario para crear
					</Typography>
					<Typography variant="body2" component="p" color="secondary">
						Cargar los datos que deseas asignar al nuevo registro.
					</Typography>
					<FormDynamic {...formPropsNew} />
				</>
			)}
		</Box>
	);
};

export default FormCreateAndUpdate;
