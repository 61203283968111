import api from '../../plugins/api';

/* Agregando un nuevo dato bancario */
const pushProviderBankDetails = form => () =>
	api().post(`/provider-bank-details`, form);

/* Actualizando un dato bancario */
const putProviderBankDetails = (id, form) => () =>
	api().put(`/provider-bank-details/${id}`, form);

/* Elimando un dato bancario */
const deleteProviderBankDetails = id => () =>
	api().delete(`/provider-bank-details/${id}`);

const providerBankDetails = {
	pushProviderBankDetails,
	putProviderBankDetails,
	deleteProviderBankDetails,
};

export default providerBankDetails;
