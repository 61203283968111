import { Paper, Typography, Button } from '@mui/material';
import FormDynamic from './FormDynamic';
import DataListDynamic from '../components/DataListDynamic';
import { useProviderContact, useProvider } from '../hooks';
import { useState } from 'react';

const Form = ({ providerContactsData, providerID, cancel }) => {
	const ID = providerContactsData?.id;
	const {
		formInputs: inputs,
		createProviderContacts,
		updateProviderContacts,
	} = useProviderContact({ ID });

	const { resetList } = useProvider({});

	const formProps = {
		validateFunction: () => {},
		inputs,
		defaultValues: {
			name: '',
			telephon: '',
			comments: '',
			position: '',
			email: '',
		},
		cancelFunction: cancel,
	};

	const formPropsNew = {
		...formProps,
		submitFunction: data => {
			const toSend = { ...data, provider: providerID };
			createProviderContacts(toSend).then(() => {
				/* Se refresca proveedor */
				resetList();
				cancel();
			});
		},
		buttonText: 'Crear',
	};

	const formPropsEdit = {
		...formProps,
		submitFunction: data => {
			const toSend = { ...data, provider: providerID };
			updateProviderContacts(ID, toSend).then(() => {
				/* Se refresca proveedor */
				resetList();
				cancel();
			});
		},
		defaultValues: providerContactsData,
		buttonText: 'Actualizar',
	};

	if (ID && formPropsEdit?.defaultValues?.id)
		return <FormDynamic cancelFunction={cancel} {...formPropsEdit} />;
	if (!ID) return <FormDynamic cancelFunction={cancel} {...formPropsNew} />;

	return 'Cargando datos';
};

/* Configurando responsividad de la tabla. Siempre trabajar en base a 11 porque 1 está destinado al boton eliminar. */
const widthCells = { description: { xs: 10 } };
const rowTitle = {
	description: (
		<Typography variant="subtitle2" component="div">
			Detalles
		</Typography>
	),
};

const ProviderDetailsBankData = ({ providerData }) => {
	const [providerContacts, setProviderContacts] = useState('');
	const { resetList } = useProvider({});
	const { deleteProviderContacts } = useProviderContact({ ID: 'new' }); //Se coloca new para no se ejecute la solicitud de un listado, ni se ejecute la lectura de un data bancario
	const cancelForm = () => setProviderContacts('');

	const rowsData = providerData.provider_contacts.map(item => ({
		id: item.id,
		description: (
			<>
				Nombre: {item.name}
				<br />
				Cargo: {item.position}
				<br />
				Email: {item.email}
				<br />
				Teléfonos: {item.telephon}
				<br />
				Comentarios: {item.comments}
			</>
		),
		inactive: item.status === 'inactive',
		onClick: () => setProviderContacts(item),
	}));

	return (
		<Paper variant="outlined" sx={{ padding: '16px' }}>
			{providerContacts === 'new' ? (
				<>
					<Typography variant="h6" component="h2">
						Nuevo Contacto
					</Typography>
					<Form cancel={cancelForm} providerID={providerData.id} />
				</>
			) : !['', 'new'].includes(providerContacts) ? (
				<>
					<Typography variant="h6" component="h2">
						Actualizando Contacto
					</Typography>
					<Form
						cancel={cancelForm}
						providerID={providerData.id}
						providerContactsData={providerContacts}
					/>
				</>
			) : (
				<>
					<Typography variant="h6" component="h2">
						Lista de Contactos
					</Typography>
					<Button
						type="button"
						fullWidth
						variant="outlined"
						sx={{ mt: '16px', mb: 2 }}
						onClick={() => setProviderContacts('new')}
					>
						Agregar
					</Button>
					{rowsData?.length > 0 && (
						<DataListDynamic
							{...{ rowTitle, rowsData, widthCells }}
							deleteFunction={id => {
								deleteProviderContacts(id).then(() =>
									resetList()
								);
							}}
						/>
					)}
				</>
			)}
		</Paper>
	);
};

export default ProviderDetailsBankData;
