import api from '../../plugins/api';
import { mat, asyncMac } from '../utils';

const asyncUser = mat('negotiations');
const [setPending, setFulfilled, setError] = asyncMac(asyncUser);

/* Listar a los negociaciones */
const fetchNegotiations = params => (dispatch, getState) => {
	dispatch(setPending());
	const state = getState();
	const limit = 0;/*params?.limit || process.env.REACT_APP_PER_PAGE;*/

	let query = `?_limit=${limit}&_start=${params?.start || 0}`;

	if (params?.query?.length) query += params.query;

	return api()
		.get(`/negotiations${query}`)
		.then(({ data }) => {
			const entities = state.negotiations.entities;
			dispatch(setFulfilled([...entities, ...data]));
		})
		.catch(error => dispatch(setError(error)));
};

/* Total negociaciones */
const fetchTotalNegotiation = params => dispatch => {
	/* Filtros para contar */
	let query = `?`;
	if (params?.query?.length) query += params.query;

	return api()
		.get(`/negotiations/count${query}`)
		.then(({ data }) => {
			dispatch({ type: 'negotiation-total/set', payload: data });
		})
		.catch(error => dispatch(setError(error)));
};

/* Agregando una nueva negociación */
const pushNegotiation = form => async dispatch => {
	dispatch(setPending());
	return api()
		.post(`/negotiations`, form)
		.then(({ data }) => {
			dispatch({ type: 'negotiations/add', payload: data });
			dispatch({ type: 'negotiation-total/add' });
		})
		.catch(error => dispatch(setError(error)));
};

/* Actualizando una negociación */
const putNegotiation = (id, form) => async dispatch => {
	dispatch(setPending());
	return api()
		.put(`/negotiations/${id}`, form)
		.then(({ data }) => {
			dispatch({ type: 'negotiations/update', payload: data });
		})
		.catch(error => dispatch(setError(error)));
};

/* Limpiar la lista de negociaciones */
const clearNegotiations = () => dispatch => {
	dispatch({ type: 'negotiations/clear' });
	dispatch({ type: 'negotiation-total/clear' });
};

/* INDIVIDUAL */
/* Esta instancia se ejecuta cuando el usuario ingresa directo a la URL y no existe lista */
const readNegotiation = id => dispatch => {
	dispatch(setPending());

	return api()
		.get(`/negotiations/${id}`)
		.then(({ data }) => {
			dispatch(setFulfilled([data]));
		})
		.catch(error => dispatch(setError(error)));
};

/* Elimando una negociación */
const deleteNegotiation = id => async dispatch => {
	dispatch(setPending());
	return api()
		.delete(`/negotiations/${id}`)
		.then(() => {
			dispatch({ type: 'negotiations/remove', payload: id });
			dispatch({ type: 'negotiation-total/remove' });
		})
		.catch(error => dispatch(setError(error)));
};

const negotiations = {
	fetchNegotiations,
	readNegotiation,
	fetchTotalNegotiation,
	pushNegotiation,
	putNegotiation,
	deleteNegotiation,
	clearNegotiations,
};

export default negotiations;
