const inputs = (types = []) => [
	{
		type: 'text',
		label: 'Nombre',
		name: 'name',
		placeholder: 'Nombre para identificar ...',
		required: true,
		autoFocus: true,
	},
	{
		type: 'select',
		label: 'Estado',
		name: 'status',
		required: true,
		options: [
			{ name: 'Activo', value: 'active' },
			{ name: 'Inactivo', value: 'inactive' },
		],
	},
	{
		type: 'select',
		label: 'Tipo de proveedor',
		name: 'provider_types',
		required: true,
		options: types,
	},
	{
		type: 'text',
		label: 'Descripción',
		name: 'description',
		placeholder: 'Texto que describa el uso ...',
	},
];

export default inputs;
