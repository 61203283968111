const initialState = { duration: 6000, message: null, loading: null };

const actionNotification = (state = initialState, action) => {
	switch (action.type) {
		case 'notification/set': {
			return { ...state, ...action.payload };
		}
		case 'notification/reset': {
			return { ...initialState, duration: state.duration };
		}
		case 'notification/resetall': {
			return initialState;
		}
		default:
			return state;
	}
};

const reducer = actionNotification;

export default reducer;
