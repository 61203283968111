/* Configutaciones del menu */

const menuOptionsSet = data => async dispatch => {
	dispatch({ type: 'menu-options/set', payload: data });
};

const fnMenuOptions = {
	menuOptionsSet,
};

export default fnMenuOptions;
