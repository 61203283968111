import { Outlet } from 'react-router-dom';
import { useState } from 'react';

import {
	Box,
	Drawer,
	AppBar,
	Toolbar,
	CssBaseline,
	Typography,
	Divider,
	IconButton,
} from '@mui/material';

import { ChevronLeft, Menu, NavigateBefore, NavigateNext } from '@mui/icons-material';

import logo from '../assets/logo.png';
import logo_fav from '../assets/logo_fav.png';
import MenuList from '../components/MenuList';
import { useMenu } from '../hooks';

const drawerWidth = 250;

export default function AppLayout() {
	const [open, setOpen] = useState(false);
	const [hideMenu, setHideMenu] = useState(false);
	const { itemBylocation } = useMenu();
	const handleDrawerToggle = () => {
		setOpen(!open);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					width: { sm: hideMenu ? `calc(100% - 75px)`: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: hideMenu ? `75px` : `${drawerWidth}px` },
				}}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerToggle}
						edge="start"
						sx={{ mr: 2, display: { sm: 'none' } }}
					>
						{open ? <ChevronLeft /> : <Menu />}
					</IconButton>
					<Typography
						variant="h6"
						noWrap
						component="div"
						sx={{ flexGrow: 1 }}
					>
						{itemBylocation?.name}
					</Typography>
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				sx={{ width: { sm: hideMenu ? 75 : drawerWidth }, flexShrink: { sm: 0 } }}
			>
				<Drawer
					variant="permanent"
					sx={{
						display: { xs: 'none', sm: 'block' },
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: hideMenu ? 75 : drawerWidth,
						},
					}}
					open
				>
					<Toolbar sx={{textAlign: "center", padding: hideMenu ? "10px !important" : "20px !important"}}>
						<img
							src={hideMenu ? logo_fav : logo}
							alt="Salones y Eventos"
							width="100%"
							height="auto"
							loading="lazy"
						/>
					</Toolbar>
					<Divider />
					<Box sx={{textAlign: "center", pl:"10px"}}>
						<IconButton
							color="primary"
							aria-label="open drawer"
							onClick={() => hideMenu ? setHideMenu(false) : setHideMenu(true)}
							edge="start"
						>
						{hideMenu ? (<NavigateNext />):(<NavigateBefore />)}
						</IconButton>
					</Box>
					<Divider />
					{!hideMenu &&
						<MenuList />
					}
				</Drawer>
				<Drawer
					variant="temporary"
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: drawerWidth,
						},
					}}
					{...{ open }}
				>
					<Toolbar sx={{textAlign: "center", padding: "20px"}}>
						<img
							src={logo}
							alt="Salones y Eventos"
							width="100%"
							height="auto"
							loading="lazy"
						/>
					</Toolbar>
					<Divider />
					<MenuList menuClick={handleDrawerToggle} />
				</Drawer>
			</Box>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 3,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}
			>
				<Toolbar />
				<Outlet />
			</Box>
		</Box>
	);
}
