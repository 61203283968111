const theme = {
	palette: {
		primary: {
			light: '#013dc2',
			main: '#002984',
			dark: '#032572',
			contrastText: '#fff',
		},
		secondary: {
			light: '#ff6b21',
			lightover: '#ff6b21',
			main: '#fc5400',
			dark: '#dc4b02',
			contrastText: '#fff',
		},
		light:{
			main: '#eee',
			dark:"##a3a2a2"
		}
	},
	typography: {
		// Tell MUI what's the font-size on the html element is.
		htmlFontSize: 16,
	},
};

export default theme;
