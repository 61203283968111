import { Typography, Breadcrumbs, Link } from '@mui/material';

/* 
	links:[{name,onClick}]
*/
const BreadCrumbs = ({ links }) => {
	return (
		<Breadcrumbs sx={{ mb: '16px' }}>
			{links.map((link, index) => {
				if (link.onClick)
					return (
						<Link
							key={`${index}-bc-link`}
							underline="hover"
							color="inherit"
							onClick={link.onClick}
							href="#"
						>
							{link.name}
						</Link>
					);
				return (
					<Typography key={`${index}-bc-text`} color="text.primary">
						{link.name}
					</Typography>
				);
			})}
		</Breadcrumbs>
	);
};

export default BreadCrumbs;
