import { Paper, Typography, Button } from '@mui/material';
import FormDynamic from './FormDynamic';
import DataListDynamic from '../components/DataListDynamic';
import { useWorkspace, useProvider } from '../hooks';
import { useState } from 'react';

const Form = ({ ID, providerID, cancel }) => {
	const {
		workspaceData,
		formInputs: inputs,
		createWorkspace,
		updateWorkspace,
	} = useWorkspace({ ID });

	const { resetList } = useProvider({});

	const formProps = {
		validateFunction: () => {},
		inputs,
		defaultValues: {
			name: '',
			description: '',
			area: '0',
			price_type: '0',
			price: '0',
			price_provider: '0',
			hours_minimum: '0',
			attendees_minimum: '0',
			attendees_maximum: '0',
			status: 'inactive',
		},
		cancelFunction: cancel,
	};

	const formPropsNew = {
		...formProps,
		submitFunction: data => {
			const toSend = { ...data, provider: providerID };
			createWorkspace(toSend).then(() => {
				/* Se refresca proveedor */
				resetList();
				cancel();
			});
		},
		buttonText: 'Crear',
	};

	const formPropsEdit = {
		...formProps,
		submitFunction: data => {
			const toSend = { ...data, provider: providerID };
			updateWorkspace(ID, toSend).then(() => {
				/* Se refresca proveedor */
				resetList();
				cancel();
			});
		},
		defaultValues: workspaceData,
		buttonText: 'Actualizar',
	};

	if (ID && formPropsEdit?.defaultValues?.id)
		return <FormDynamic cancelFunction={cancel} {...formPropsEdit} />;
	if (!ID) return <FormDynamic cancelFunction={cancel} {...formPropsNew} />;

	return 'Cargando datos';
};

/* Configurando responsividad de la tabla. Siempre trabajar en base a 11 porque 1 está destinado al boton eliminar. */
const widthCells = { name: { xs: 7 }, price: { xs: 3 } };
const rowTitle = {
	name: (
		<Typography variant="subtitle2" component="div">
			Nombre
		</Typography>
	),
	price: (
		<Typography variant="subtitle2" component="div">
			Precio
		</Typography>
	),
};

const ProviderDetailsWorkspaces = ({ providerData }) => {
	const [workspaceId, setWorkspaceId] = useState('');
	const { resetList } = useProvider({});
	const { deleteWorkspace } = useWorkspace({ ID: 'new' }); //Se coloca new para no se ejecute la solicitud de un listado, ni se ejecute la lectura de un salon
	const cancelForm = () => setWorkspaceId('');

	const rowsData = providerData.workspaces.map(item => ({
		id: item.id,
		name: item.name,
		price: item.price,
		inactive: item.status === 'inactive',
		onClick: () => setWorkspaceId(item.id),
	}));

	return (
		<Paper variant="outlined" sx={{ padding: '16px' }}>
			{workspaceId === 'new' ? (
				<>
					<Typography variant="h6" component="h2">
						Nuevo salón
					</Typography>
					<Form cancel={cancelForm} providerID={providerData.id} />
				</>
			) : !['', 'new'].includes(workspaceId) ? (
				<>
					<Typography variant="h6" component="h2">
						Actualizando salón
					</Typography>
					<Form
						cancel={cancelForm}
						providerID={providerData.id}
						ID={workspaceId}
					/>
				</>
			) : (
				<>
					<Typography variant="h6" component="h2">
						Lista de salones
					</Typography>
					<Button
						type="button"
						fullWidth
						variant="outlined"
						sx={{ mt: '16px', mb: 2 }}
						onClick={() => setWorkspaceId('new')}
					>
						Agregar
					</Button>
					{rowsData?.length > 0 && (
						<DataListDynamic
							{...{ rowTitle, rowsData, widthCells }}
							deleteFunction={id => {
								deleteWorkspace(id).then(() =>
								resetList()
								);
							}}
						/>
					)}
				</>
			)}
		</Paper>
	);
};

export default ProviderDetailsWorkspaces;
