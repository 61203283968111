import { useSelector } from 'react-redux';
import { ArrowForward, Info, Check, PlaylistRemove,ChevronLeft} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useOutletContext } from 'react-router-dom';
import api from '../../plugins/api';
import { useForm } from 'react-hook-form';
import * as React from 'react';
import {
	Grid,
	FormControl,
	Typography,
	Button,
	FormControlLabel,
	Checkbox,
	Divider,
	Box,
	TextField,
	Stack,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	Modal,
	Alert,
	Link,
	Popover
} from '@mui/material';
import { printMoney } from '../../plugins/utils';
import moment from 'moment';
import { useEffect, useState, useRef  } from 'react';
import PQuotationForm from '../../components/public/PQuotationForm';
import PQuotationCardsProviders from '../../components/public/PQuotationCardsProviders';
import usePNavBar from '../../hooks/usePNavBar';
import PQuotationStepper from '../../components/public/PQuotationStepper';
import { useProvider } from '../../hooks';

const PClientQuotationSteps = ({step}) => {
	const {goTo} = usePNavBar();
	const {client,setClient,showLoader,setShowLoader,providerPage,providerPageId} = useOutletContext();

	const [providerOptions,setProviderOptions] = useState([]);
	const [queryFind, setQueryFind] = useState(null);
	const [providerResults, setProviderResults] = useState(null);
	const [providerSelected, setProviderSelected] = useState(null);
	const [serviceSelected, setServiceSelected] = useState([]);
	const [virtualEvent, setVirtualEvent] = useState(false);
	const [emailClient, setEmailClient] = useState("");
	const [negotiationData,setNegotiationData] = useState({});
	const [modalAOpen, setModalAOpen] = useState(false);
	const handleMAOpen = () => setModalAOpen(true);
	const handleMAClose = () => setModalAOpen(false);
	const [modalBOpen, setModalBOpen] = useState(false);
	const handleMBOpen = () => setModalBOpen(true);
	const handleMBClose = () => setModalBOpen(false);
	const [negotiationID,setNegotiationID] = useState(null);
	const [eventFormat, setEventFormat] = useState('');
	const refProviderOptions = useRef(null);
	const refProviderSelected = useRef(null);
	const refScrollTop= useRef(null);
	const [redirectedToProvider,setRedirectedToProvider] = useState(false);

	const {
		providerList
	} = useProvider({})

	/* POPOVER SERVICE VARs */
	const [PopServiceOpen,SetPopServiceOpen] = useState(false);
	const [PopServiceAnchorEl,SetPopServiceAnchorEl] = useState(false);
	const [PopService,SetPopService] = useState(false);
	const PopServiceHandleClick = (event,service) => {
		SetPopServiceAnchorEl(event.currentTarget);
		SetPopServiceOpen(true);
		SetPopService(service);
	};

	const localClient = JSON.parse(localStorage.getItem("client"));
	const localEmailClient = localStorage.getItem("emailClient");
	const localQueryQuotation = JSON.parse(localStorage.getItem("queryQuotation"));
	const localEventFormat= JSON.parse(localStorage.getItem("eventFormat"));
	const localServiceSelected= JSON.parse(localStorage.getItem("serviceSelected"));
	const localProviderSelected= JSON.parse(localStorage.getItem("providerSelected"));

	useEffect(() => {
		console.log(localClient);
		/* MODIFICAR VARIABLES Y REDIRECCIONAR AL FORZAR CAMBIO DE PASO DE COTIZACION */
		switch (step) {
			case "1.1":
				console.log("step"+step);
				if(localClient?.name){
					goTo("/cotizar/2");
				}
				localStorage.removeItem("emailClient");
			break;
			case "1.2":
				console.log("step"+step);
				if(!localEmailClient){
					goTo("/cotizar");
				}
				if(localClient?.name){
					goTo("/cotizar/2");
				}
				/*ASIGNAR VALORES DEL LOCALSTORAGE */
				if(localEmailClient){
					setEmailClient(localEmailClient);
				}
			break;
			case "2":
				console.log("step"+step);
				if(!localClient){
					goTo("/cotizar");
				}
				if(!localClient?.name){
					goTo("/cotizar/1_2");
				}
				localStorage.removeItem("providerSelected");
				/*ASIGNAR VALORES DEL LOCALSTORAGE */
				if(localQueryQuotation){
					setQueryFind(localQueryQuotation);
				}
				if(localEventFormat){
					setEventFormat(localEventFormat);
				}
				if(localServiceSelected){
					setServiceSelected(localServiceSelected);
				}
			break;
			case "3":
				console.log("step"+step);
				if(!localClient){
					goTo("/cotizar");
				}
				if(!localClient?.name){
					goTo("/cotizar/1_2");
				}
				if(!localProviderSelected){
					goTo("/cotizar/2");
				}
				/*ASIGNAR VALORES DEL LOCALSTORAGE */
				if(localQueryQuotation){
					setQueryFind(localQueryQuotation);
				}
				if(localEventFormat){
					setEventFormat(localEventFormat);
				}
				if(localServiceSelected){
					setServiceSelected(localServiceSelected);
				}
				if(localProviderSelected){
					setProviderSelected(localProviderSelected);
				}
			break;
			default:
				console.log("step"+step);
				goTo("/cotizar");
			break;
		}

		
	},[step,client]);

	useEffect(() => {
		refProviderSelected?.current?.scrollIntoView({block: "start", behavior: "smooth"});
	}, [providerSelected]);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const scrollToBottom = () => {
		window.scrollTo({
		  top: document.documentElement.scrollHeight,
		  behavior: 'smooth',
		});
	  };

	const boxModalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
  		maxWidth: '700px',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		pt:8
	};

	const tax = useSelector(({ configurations }) => {
		if (configurations.entity && configurations.entity.countries)
			return configurations.entity.countries[0]?.tax;
		return 0;
	});

		/* Funciones para los calculos que obtienes el precio más bajo de lo seleccionado por el usuario */
		const workspaceLowCost = workspaces => {
			const dateStart = moment.parseZone(queryFind.date_start);
			const dateEnd = moment.parseZone(queryFind.date_end);
			const totalDate = dateEnd.diff(dateStart, 'days') + 1;
	
			if (workspaces === undefined || workspaces.length === 0) return {total:0,obj:{price:0}}; // Sin salón
			/* Utilizar solo los salones activos */
			const workspacesActive = workspaces.filter(
				workspace => workspace.status === 'active'
			);
			if (workspacesActive.length === 0) return {total:0,obj:{price:0}}; // Sin salón activo
			if (workspacesActive.length === 1){
				let workspaceF2 = {};
				Object.assign(workspaceF2,workspacesActive[0]);
				workspaceF2.quantity = totalDate;
				return {total:workspacesActive[0].price * totalDate,obj:workspaceF2}; // 1 salón activo
			} 
			
			/* Filtrar los salones con capacidad >= a la requerida */
			const workspacesActiveMatch = workspaces.filter(
				workspace => workspace.attendees_maximum >= parseInt(queryFind.attendees)
			);

			const compare = (lowcost, workspace) => {
				return lowcost.price === 0 || lowcost.price > workspace.price
					? (workspace.price > 0 ? workspace : {price:0})
					: lowcost;
			};

			const workspaceF =  workspacesActiveMatch.reduce(compare, {price:0});
			let workspaceF2 = {};
			Object.assign(workspaceF2,workspaceF);
			workspaceF2.quantity = totalDate;
	
			return {total:workspaceF.price * totalDate,obj:workspaceF2};
		};

		const virtualServiceDefault = {
			name: "Streaming del evento",
			description: `Este servicio incluye computadores, camaras, microfonos y dos personas (tecnico y productor) de manera presencial para la transmisión del evento. 
						  El valor es por el día completo (12Hrs)`,
			price: 800000,
			price_provider: 800000,
			minimum:1,
			maximum:999,
			provider:"other_services",
			provider_name:"e-swaper"
		};
	
		const servicesLowCost = services => {
			let total = 0;
			let servicesLowCost = [];
			/* Entre todos los servicios se escogen los más economicos elegistos por el usuario */
			serviceSelected.forEach(selected => {
				if(selected.value==="virtualDefault"){
					var lowCost = virtualServiceDefault;
					lowCost.quantity = selected.quantity;
					servicesLowCost.push(lowCost);
					total += lowCost.price *selected.quantity;
				}else{
					/* Utilizar solo los salones activos */
					const servicesActive = services.filter(
						service => service.status === 'active'
					);
					const compare = (lowcost, service) => {
						return lowcost.price === 0 || lowcost.price > service.price
							? (service.price > 0 ? service : {price:0})
							: lowcost;
					};
		
					var lowCost = servicesActive
						.filter(service => service.service_types === selected.value)
						.reduce(compare, {price:0});
					lowCost.quantity = selected.quantity;
					if(!lowCost.name){
						servicesLowCost.push(selected);
					}else{
						servicesLowCost.push(lowCost);
					}
					total += lowCost.price *selected.quantity;
				}
			});
	
			return {total: total,array:servicesLowCost};
		};
	

	const getProviderOptions = () => {
		var array = [];

		if (queryFind){
			providerResults?.map(item => {
				const workspace = workspaceLowCost(item.workspaces);
				const services = servicesLowCost(item.services);
				const iva = ((workspace.total + services.total) * tax) / 100;
				const total = workspace.total + services.total + iva;
				array.push({
					id: item.id,
					name: item.name,
					email: item.email,
					url_iframe: item.url_iframe,
					workspace: workspace,
					services: services,
					total: total > 0 ? total : '0',
					iva: iva > 0 ? iva : '$0',
					inactive: item.status === 'inactive',
					onClick: () => setProviderSelected(item),
				});
			});
			refProviderOptions?.current?.scrollIntoView({block: "start", behavior: "smooth"});
			localStorage.setItem("queryQuotation", JSON.stringify(queryFind));
			localStorage.setItem("eventFormat", JSON.stringify(eventFormat));
			localStorage.setItem("serviceSelected", JSON.stringify(serviceSelected));
		}
		return array;
	}

	useEffect(() => {
		setProviderOptions(getProviderOptions());
	}, [queryFind,providerResults]);

	const clearUser = () =>{
		localStorage.setItem("client", null);
		setClient(null);
	}

	//FORM CONTROLLERS
	const {register: registerFClientEdit, handleSubmit: handleSubmitFClientEdit} = useForm();
	const {register: registerFClientEmail, handleSubmit: handleSubmitFClientEmail} = useForm();
	const {register: registerFClientInfo, handleSubmit: handleSubmitFClientInfo} = useForm();
	const {register: registerFStep3, handleSubmit: handleSubmitFStep3} = useForm();

	const [emailConfirmError,setEmailConfirmError] = useState(false);
	const [clientConfirmEdit,setClientConfirmEdit] = useState(false);

	const submite1_1 = (dataForm) => {
		setShowLoader(true);
		if(dataForm.email.replace(/ /g, "").toLowerCase()===dataForm.email_confirm.replace(/ /g, "").toLowerCase()){
			const s1Email = dataForm.email.replace(/ /g, "").toLowerCase();
			api()
			.get(`/user-infos/`)
			.then( ( {data} ) => {
				const s1UserByEmail = data.filter(item => (item.user ? item.user.email : "") === s1Email);
				if(s1UserByEmail[0]){
					localStorage.removeItem("emailClient");
					localStorage.setItem("client", JSON.stringify(s1UserByEmail[0]));
					setClient(s1UserByEmail[0]);
					setShowLoader(false);
					goTo("/cotizar/1_2");
				}else{
					localStorage.setItem("emailClient", s1Email);
					setShowLoader(false);
					goTo("/cotizar/1_2");
				}
			});
		}else{
			setShowLoader(false);
			setEmailConfirmError(true);
		}
        
    };

	var userData = {};
	const submite1_2 = (data) => {
		setShowLoader(true);
		userData.email = emailClient;
		userData.password = "0000";
		userData.name = data.name;
		userData.lastname = data.lastname;
		userData.telephone = data.phone;
		userData.company = data.company;
		userData.role = "customer";
		api()
		.post(`/user-infos`, userData)
		.then(async ({ data })  =>  {
			api()
			.get(`/user-infos/${data.id}`)
			.then(async ({ data }) => {
				localStorage.removeItem("emailClient");
				localStorage.setItem("client", JSON.stringify(data));
				setClient(data);
				setShowLoader(false);
				goTo("/cotizar/2");
			});
			
		});
		
    };

	const goStep3 = () => {
		goTo("/cotizar/3");
    };

	const global_conf = useSelector(({ configurations }) =>
		configurations?.entity?.globals
        ?.map(type => ({
			name: type.name,
			vars: type.vars,
		}))
	);

	const submite3 = (dataForm) => {
		console.log(providerSelected);
		setShowLoader(true);
		let negotiationDataCopy = {}
		Object.assign(negotiationDataCopy,negotiationData);
		negotiationDataCopy.type = "request";
		negotiationDataCopy.status = "nuevas";
		negotiationDataCopy.requirements = dataForm.requirements;
		negotiationDataCopy.customer = client.user.id;

		var budget = {
			name:"Cotización Inicial",
			total_workspaces: providerSelected.workspace.total,
			total_services: providerSelected.services.total,
			total_tax: providerSelected.iva,
			total: providerSelected.total,
			comments: "Esta cotización corresponde a la elección que realizo el cliente de las cotizaciones iniciales entregadas por sistema, no puede ser eliminada o editada.",
			customer: client.user.id,
			services:providerSelected.services.array,
			workspaces:[providerSelected.workspace.obj],
			type:"request"
		};
		console.log(negotiationDataCopy);
		const array_event_types_redirect = ["6286876be626a8005603c4ea","62d1a0ce7f9ca500dec079be"];
		if(array_event_types_redirect.indexOf(negotiationDataCopy.event_types) >= 0){
			negotiationDataCopy.type = "request_redirected";
			setRedirectedToProvider(true);
		}else{
			setRedirectedToProvider(false);
		}

		let emailData = {};
		Object.assign(emailData,negotiationDataCopy);
		emailData.to = providerSelected.email;
		emailData.to_name = providerSelected.name;
		emailData.client_name = client.name+" "+client.lastname;
		emailData.client_telephone = client.telephone;
		emailData.client_email = client.user?.email;
		emailData.client_company = client.company;

		const notified_seller = global_conf?.find(item => item.name==="email")?.vars?.notified_seller;
		console.log(notified_seller);
        if(negotiationID){
            api()
			.put(`/negotiations/${negotiationID}`, negotiationDataCopy)
			.then(async ({ data }) => {
                console.log("Consulta actualizada con ID "+negotiationID);
				budget.negociacion = negotiationID;
				if(array_event_types_redirect.indexOf(negotiationDataCopy.event_types) >=0){
					api().post(`/email/confirm_request_send/redirected/provider`, emailData);
					api().post(`/email/confirm_request_send/redirected/client`, {to: client.user.email,name:client.name,name_provider:providerSelected.name});
				}else{
					api().post(`/email/confirm_request_send`, {to: client.user.email,name:client.name});
					api().post(`/email/confirm_request_send/notify`, {to: notified_seller});
				}
				api()
				.post(`/budgets`, budget)
				.then(({ data })  =>  {
					setShowLoader(false);
					setNegotiationData(null);
					setProviderOptions([]);
					setProviderResults([]);
					setProviderSelected(null);
					localStorage.removeItem("providerSelected");
					handleMBOpen();
					console.log("Cotizacion agregada con ID "+data.id);
				});
				setNegotiationID(null);
            });
        }else{
			negotiationDataCopy.name="Evento ";
            api()
            .post(`/negotiations`, negotiationDataCopy)
            .then(({ data })  =>  {
                console.log("Consulta agregada con ID "+data.id);
				budget.negociacion = data.id;
				if(array_event_types_redirect.indexOf(negotiationDataCopy.event_types) >=0){
					api().post(`/email/confirm_request_send/redirected_to_provider`, emailData);
					api().post(`/email/confirm_request_send/redirected/client`, {to: client.user.email,name:client.name,name_provider:providerSelected.name});
				}else{
					api().post(`/email/confirm_request_send`, {to: client.user.email,name:client.name});
					api().post(`/email/confirm_request_send/notify`, {to: notified_seller});
				}
				api()
				.post(`/budgets`, budget)
				.then(({ data })  =>  {
					setShowLoader(false);
					setNegotiationData(null);
					setProviderOptions([]);
					setProviderResults([]);
					setProviderSelected(null);
					localStorage.removeItem("providerSelected");
					handleMBOpen();
					console.log("Cotizacion agregada con ID "+data.id);
				});
				setNegotiationID(null);
            });
        }
    };

	const editClient = () => {
		handleMAOpen();
	};

	const editCotizacion = () => {
		localStorage.removeItem("providerSelected");
		goTo("/cotizar/2");
	};

	const submiteFCE = (dataForm) => {
		setShowLoader(true);
		api()
		.put(`/user-infos/${client.id}`, dataForm)
		.then(async ()  =>  {
			client.name = dataForm.name;
			client.telephone = dataForm.telephone;
			client.lastname = dataForm.lastname;
			client.company = dataForm.company;
			setClient(client);
			localStorage.setItem("client", JSON.stringify(client));
			setClientConfirmEdit(true);
			setShowLoader(false);
		});
    };

	return (
		<>
			<PQuotationStepper step={step.split(".")[0]}/>
			<Modal
				disableAutoFocus
				disableEnforceFocus
				disableEscapeKeyDown
				open={modalBOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxModalStyle}>
				<Stack
					spacing={{ xs: 1, sm: 2, md: 4 }}
				>
					{!redirectedToProvider &&
						<>
						<Alert severity="success">
						Tu solicitud de evento fue enviada a nuestros ejecutivos. 
						<br />
						Puedes revisar tus solicitudes de evento en "Mis Eventos", donde podrás ver las cotizaciones que nuestros ejecutivos generarán. 
						<br />
						Agradecemos su preferencia.
						</Alert>
						<Button
							variant="contained"
							color="secondary"
							sx={{mt:2}}
							onClick={()=>{handleMBClose();goTo("/cotizar/2");}}
						>
							{"COTIZAR OTRO EVENTO"}
						</Button>
						<Button
							variant="contained"
							color="secondary"
							sx={{mt:2}}
							onClick={()=>goTo("/cuenta/eventos")}
						>
							{"VER MIS EVENTOS"}
						</Button>
						</>
					}
					{redirectedToProvider &&
						<>
						<Alert severity="success">
						Su solicitud de cotización se envió al proveedor que seleccionó, este se contactará con Ud. para continuar el proceso de organizar su evento.
						<br />
						Esta solicitud no se verá en la sección "Mis eventos", ya que no es gestionada por nuestros ejecutivos.
						<br />
						Agradecemos su preferencia.
						</Alert>
						<Button
							variant="contained"
							color="secondary"
							sx={{mt:2}}
							onClick={()=>{handleMBClose();goTo("/cotizar/2");}}
						>
							{"Cotizar otro evento"}
						</Button>
						</>
					}
					
				</Stack>
				</Box>
			</Modal>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{(!client && step==="1.1") &&
					<Grid container justifyContent={"center"} spacing={2}>
						<Grid item xs={11} sm={5} lg={3}>
						<form
							className="form-email"
							onSubmit={handleSubmitFClientEmail(submite1_1)}
							style={{ width: '100%' }}
						>
						<Stack
							spacing={{ xs: 1, sm: 2, md: 4 }}
						>
							<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
								<TextField
									label="Email"
									id="email"
									name="email"
									variant="standard"
									required
									
									{...registerFClientEmail("email")}
								></TextField>
							</FormControl>
							<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
								<TextField
									label="Repetir Email"
									id="email_confirm"
									name="email_confirm"
									variant="standard"
									required
									{...registerFClientEmail("email_confirm")}
								></TextField>
								{emailConfirmError && <Alert sx={{mt:2}} severity="error">Los emails no son identicos</Alert>}
							</FormControl>
						</Stack>
						<Stack sx={{mt:"25px", mb:"25px"}} spacing={2}
							direction="row"
							alignItems="center"
							justifyContent="center"
						>
							<Button
								type="submit"
								endIcon={<ArrowForward />}
								variant="contained"
								color="secondary"
							>
								{"CONTINUAR"}
							</Button>
						</Stack>
						</form>
						</Grid>
					</Grid>
					}
					{(!client && step==="1.2") &&
					<form
						className="form-info"
						onSubmit={handleSubmitFClientInfo(submite1_2)}
						style={{ width: '100%' }}
					>
					<Grid container justifyContent={"center"} spacing={2}>
						<Grid item xs={12} md={3}>
							<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
								<TextField
									label="Email"
									variant="standard"
									disabled
									value={emailClient}
								></TextField>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={3}>
							<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
								<TextField
									label="Nombre"
									id="name"
									name="name"
									variant="standard"
									required
									{...registerFClientInfo("name")}
								></TextField>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={3}>
							<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
								<TextField
									label="Apellido"
									id="lastname"
									name="lastname"
									variant="standard"
									required
									{...registerFClientInfo("lastname")}
								></TextField>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={3}>
							<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
								<TextField
									label="Teléfono"
									id="phone"
									name="phone"
									variant="standard"
									required
									{...registerFClientInfo("phone")}
								></TextField>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={3}>
							<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
								<TextField
									label="Compañia o Institución"
									id="company"
									name="company"
									variant="standard"
									
									{...registerFClientInfo("company")}
								></TextField>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="center"
							>
								<FormControlLabel 
									control={<Checkbox required />} 
									label={
										<Link href="https://salonesyeventos.cl/terminos-y-condiciones" target="_blank" underline="none">
											{'Acepto los términos y condiciones de salonesyeventos.cl'}
								  		</Link>
									} 
								/>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack sx={{mt:"25px", mb:"25px"}} spacing={2}
								direction="row"
								alignItems="center"
								justifyContent="center"
							>
								<Button
									type="submit"
									endIcon={<ArrowForward />}
									variant="contained"
									color="secondary"
								>
									{"CONTINUAR"}
								</Button>
							</Stack>
						</Grid>
					</Grid>
					</form>
					}
					{(client && step==="2") &&
					<Box>
						<Box >
							{providerPage &&
								<PQuotationForm 
									queryFind={queryFind} setQueryFind={setQueryFind}  providerList={providerList}
									virtualEvent={virtualEvent} setVirtualEvent={setVirtualEvent} 
									serviceSelected={serviceSelected} setServiceSelected={setServiceSelected}
									setProviderResults={setProviderResults} providerPage={providerPage}
									setShowLoader={setShowLoader} negotiationData={negotiationData} setNegotiationData={setNegotiationData}
									negotiationID={negotiationID} setNegotiationID={setNegotiationID} client={client} eventFormat={eventFormat} setEventFormat={setEventFormat}
								/>
							}
							{!providerPage &&
								<PQuotationForm 
									queryFind={queryFind} setQueryFind={setQueryFind} providerList={providerList}
									virtualEvent={virtualEvent} setVirtualEvent={setVirtualEvent} 
									serviceSelected={serviceSelected} setServiceSelected={setServiceSelected}
									setProviderResults={setProviderResults} providerPage={providerPage}
									setShowLoader={setShowLoader} negotiationData={negotiationData} setNegotiationData={setNegotiationData}
									negotiationID={negotiationID} setNegotiationID={setNegotiationID} client={client} eventFormat={eventFormat} setEventFormat={setEventFormat}
								/>
							}
						</Box>
						<Box sx={{pt: "70px"}} ref={refProviderOptions}>
						{providerResults && providerOptions?.length>=1 &&
							<>
							<Typography variant="h4" sx={{fontWeight:"700 !important"}} gutterBottom component="div" color="primary">
								Resultados
							</Typography>
							<Divider sx={{mb: "20px"}} />
							<PQuotationCardsProviders goStep3={goStep3} providers={providerOptions} setProviderSelected={setProviderSelected} 
														scrollToTop={scrollToTop} setShowLoader={setShowLoader} providerPage={providerPage}/>
							</>
						}
						{providerResults && providerOptions?.length===0 &&
							<>
							<Typography variant="h4" sx={{fontWeight:"700 !important"}} gutterBottom component="div" color="primary">
								Resultados
							</Typography>
							<Divider sx={{mb: "20px"}} />
							<Card sx={{boxShadow: 3}}>
								<CardContent sx={{p:"30px !important"}}>
									<Stack direction="row" spacing={1} justifyContent="center">
										<PlaylistRemove color="secondary" fontSize="large"/>
										<Typography variant="h5" color="secondary" sx={{fontWeight:"700 !important"}}>
											No hay resultados
										</Typography>
									</Stack>
								</CardContent>
							</Card>
							</>
						}
						</Box>
						{/*
						<Stack sx={{mt:"25px", mb:"25px"}} spacing={2}
						direction="row"
						alignItems="center"
						justifyContent="center"
						ref={refProviderSelected}
						>
								<Button
									endIcon={<ArrowForward />}
									onClick={() => goStep3() }
									color="secondary"
									disabled={ !providerSelected ? true : false}
									variant="contained"
								>
									Continuar
								</Button>
						</Stack>*/}
						
					</Box>
					}
					{(step==="3"&& providerSelected) &&
					<>
					<Stack sx={{mb:"30px"}} spacing={2}
						direction="row"
						alignItems="center"
						justifyContent="center"
					>
						<Button
							startIcon={<ChevronLeft />}
							variant="contained"
							color="secondary"
							onClick={()=>goTo("/cotizar/2")}
						>
							{"Volver a cotizar"}
						</Button>
					</Stack>
					<form
						className="form-final"
						onSubmit={handleSubmitFStep3(submite3)}
						style={{ width: '100%' }}
					>
					<Grid container justifyContent={"center"} spacing={2}>
						<Grid item xs={12} md={6}>
							<Card>
								<CardContent>
									<Typography variant="h5" color="primary">
										Detalle cotización
									</Typography>
									{ (providerSelected.services.array.filter(element => !element.price).length>0 || !providerSelected.workspace.total>0) &&
									<Alert severity="info">Actualmente no contamos en nuestro sistema con toda la lista de precios de este lugar que seleccionó para entregarle una cotizacion inicial, 
															pero de todas formas puede enviar esta solicitud y podra obtener los precios para su cotización con nuestros ejecutivos.
									</Alert>}
									<Typography variant="h6">Lugar: {providerSelected.name}</Typography>
									<Popover
										id={"id"}
										open={PopServiceOpen}
										anchorEl={PopServiceAnchorEl}
										onClose={()=>{SetPopServiceOpen(false)}}
										anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
										}}
									>
										<Card sx={{maxWidth:"400px"}}>
											<CardHeader 
												title={PopService.name}
												sx={{pb:"0px !important"}}
											/>
											<CardContent>
												{PopService.desc}
												<Alert severity="info">El valor de los servicios de alimentos ya incluye propina del 10%</Alert>
											</CardContent>
										</Card>
									</Popover>
									<Typography variant="h6">Servicios</Typography>
									{ providerSelected.services.total > 0 ? (providerSelected?.services?.array?.map((service) => { return( <>{ service.provider!=="other_services" &&
										<Typography variant="body1">
											{service.name ? service.name : "SIN INFORMACIÓN"} {printMoney(service.price ? service.price*service.quantity : "Sin Precios")} {service.price ? "("+printMoney(service.price)+" X "+service.quantity+")" : ""} 
											<IconButton color="secondary" onClick={(e)=>{PopServiceHandleClick(e,{name:service.name,desc:service.description})}}><Info fontSize="small"/></IconButton>
										</Typography>
										}</>);})
									  ):(<Typography variant="body1">Sin información de precios para los servicios requeridos</Typography>)
									}
									{ providerSelected.services.array.filter(element => element.provider === "other_services").length>0 && 
										<>
											<Typography variant="h6">Servicios Externos</Typography>
											{providerSelected.services.array.map((service) => {  
												return( 
													<>{ service.provider==="other_services" &&
														<Typography variant="body1">
															{service.name ? service.name : "SIN INFORMACIÓN"} {printMoney(service.price*service.quantity)} ({printMoney(service.price)} X {service.quantity})
															<IconButton color="secondary" onClick={(e)=>{PopServiceHandleClick(e,{name:service.name,desc:service.description})}}><Info fontSize="small"/></IconButton>
														</Typography>
													}</>
												);})
											}
										</>
									}
									<Typography variant="h6">Salón</Typography>
									{ providerSelected.workspace.total > 0 ? (<Typography variant="body1">{providerSelected.workspace.obj.name ? providerSelected.workspace.obj.name : "SIN INFORMACIÓN"} {printMoney(providerSelected.workspace.obj.price)} X Día</Typography>)
									  :(<Typography variant="body1">Sin información de precios de salones</Typography>)
									}
									<br />
									{ providerSelected.services.total > 0 && (<Typography variant="body1">Total servicios: {printMoney(providerSelected.services.total)}</Typography>)}
									{ providerSelected.workspace.total > 0 && (<Typography variant="body1">Total salones {printMoney(providerSelected.workspace.total)} ({moment.parseZone(queryFind.date_end).diff(moment.parseZone(queryFind.date_start), 'days')+1} Días)</Typography>)}
									{ providerSelected.iva > 0 && (<Typography variant="body1">Total IVA 19%: {printMoney(providerSelected.iva)}</Typography>)}
									{ providerSelected.total > 0 && (<Typography variant="h6">Total {printMoney(providerSelected.total)} IVA Inc</Typography>)}
									<Alert severity="info" sx={{mt:2}}>
										La cotización presentada está basada en servicios básicos. 
										Existen otros servicios superiores que pueden ser solicitados al ejecutivo(a) que coordine su evento.
									</Alert>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} md={6}>
							
							<Typography variant="body1">Agregue detalles adicionales necesarios para su cotización final.</Typography>
							<FormControl fullWidth variant="standard" sx={{alignContent:"center", mt:1}}>
								<TextField
									multiline
									rows={6}
									label="Detalles adicionales"
									id="requirements"
									name="requirements"
									{...registerFStep3("requirements")}
								></TextField>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={8}>
							<Stack sx={{mt:"25px", mb:1}} spacing={2}
								direction="row"
								alignItems="center"
								justifyContent="center"
							>
								<Typography variant="body1">Presione el siguiente botón para enviar su solicitud de evento y una ejecutiva se contactará con usted antes de 3 hrs (en horario laboral)</Typography>
							</Stack>
							<Stack sx={{mb:"30px"}} spacing={2}
								direction="row"
								alignItems="center"
								justifyContent="center"
							>
								<Button
									type="submit"
									endIcon={<Check />}
									variant="contained"
									color="secondary"
								>
									{"COORDINEMOS MI EVENTO"}
								</Button>
							</Stack>
						</Grid>
					</Grid>
					</form>
					</>
					}
				</Grid>
			</Grid>
		</>
	);

};

export default PClientQuotationSteps;