const inputs = ({ serviceTypes }) => [
	{
		type: 'text',
		label: 'Nombre',
		name: 'name',
		placeholder: 'Ej: ',
		required: true,
		autoFocus: true,
	},
	{
		type: 'text',
		label: 'Descripción',
		name: 'description',
		placeholder: 'Ej: ',
	},
	{
		type: 'number',
		label: 'Precio cliente',
		name: 'price',
		placeholder: 'Ej: ',
		required: true,
	},
	{
		type: 'number',
		label: 'Precio S&E',
		name: 'price_provider',
		placeholder: 'Ej: ',
		required: true,
	},
	{
		type: 'number',
		label: 'Cantidad mínima',
		name: 'minimum',
		placeholder: 'Ej: ',
		required: true,
	},
	{
		type: 'number',
		label: 'Cantidad máxima',
		name: 'maximum',
		placeholder: 'Ej:',
		required: true,
	},
	{
		type: 'select',
		label: 'Tipo de servicio',
		name: 'service_types',
		required: true,
		options: serviceTypes,
	},
	{
		type: 'select',
		label: 'Estado',
		name: 'status',
		required: true,
		options: [
			{ name: 'Activo', value: 'active' },
			{ name: 'Inactivo', value: 'inactive' },
		],
	},
];

export default inputs;
