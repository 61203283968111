import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Container, Paper } from '@mui/material';
import FormDynamic from '../components/FormDynamic';
import { useMenu, useWorkspace } from '../hooks';
import loading from '../assets/loading-pulse.svg';
import WorkspaceDetails from '../components/WorkspaceDetails';
import ProviderDetails from '../components/ProviderDetails';
import Breadcrumbs from '../components/Breadcrumbs';
import SelectProvider from '../components/SelectProvider';

const priceType = {
	hour: 'Hora',
	day: 'Día',
	halfDay: 'Medio día',
};

const WorkspaceSummary = ({ edit }) => {
	const params = useParams();
	/* ID pasado por URL del servicio */
	const ID = params.id;

	const { goTo } = useMenu();

	const {
		workspaceData,
		formInputs: inputs,
		createWorkspace,
		updateWorkspace,
		requestStatus: status,
		resetList,
	} = useWorkspace({ ID });

	/* Set proveedor */
	const [provider, setProvider] = useState('');

	useEffect(() => {
		/* Si se tiene el id del proveedor se setea */
		const idProvider = workspaceData.provider?.id;
		if (idProvider) setProvider(idProvider);
	}, [workspaceData]);

	const formProps = {
		validateFunction: () => {},
		inputs,
		cancelFunction: () => goTo('/admin/workspaces'),
	};

	const formPropsNew = {
		...formProps,
		submitFunction: data => {
			if (provider.length) {
				createWorkspace({ ...data, provider }).then(() => {
					/* Se refresca la lista con el dato nuevo */
					resetList();
					goTo('/admin/workspaces');
				});
			} else {
				alert('Falta el proveedor');
			}
		},
		defaultValues: {
			name: '',
			description: '',
			area: '',
			price_type: '',
			price: '',
			price_provider: '',
			hours_minimum: '',
			attendees_minimum: '',
			attendees_maximum: '',
			status: 'inactive',
		},
		buttonText: 'Crear',
	};

	const formPropsEdit = {
		...formProps,
		submitFunction: data => {
			if (provider.length) {
				updateWorkspace(ID, { ...data, provider }).then(() =>
					goTo(`/workspaces/${ID}`)
				);
			} else {
				alert('Falta el proveedor');
			}
		},
		defaultValues: workspaceData,
		buttonText: 'Actualizar',
		cancelFunction: () => goTo(`/workspaces/${ID}`),
	};

	const onClick = () => goTo('/admin/workspaces');
	const links = [{ name: 'Lista de salones', onClick }];

	const linksDetails = [...links, { name: 'Detalles' }];
	const linksNew = [...links, { name: 'Crear salón' }];

	const onClickEdit = () => goTo(`/workspaces/${workspaceData.id}`);
	const linksEdit = [
		...links,
		{ name: 'Detalles', onClick: onClickEdit },
		{ name: `Actualizando a ${workspaceData.name}` },
	];

	return ID === 'new' ? (
		<>
			<Breadcrumbs links={linksNew} />
			{status?.loading === 'pending' && (
				<img
					src={loading}
					width="50px"
					style={{ margin: 'auto', display: 'block' }}
					height="50px"
					alt="Cargando ..."
				/>
			)}
			<Container maxWidth="xl" sx={{ pl: '16px', pr: '16px' }}>
				<SelectProvider
					setProvider={setProvider}
					providerDefault={workspaceData.provider}
				/>
			</Container>
			<FormDynamic {...formPropsNew} />
		</>
	) : edit && workspaceData.id ? (
		<>
			<Breadcrumbs links={linksEdit} />
			{status?.loading === 'pending' && (
				<img
					src={loading}
					width="50px"
					style={{ margin: 'auto', display: 'block' }}
					height="50px"
					alt="Cargando ..."
				/>
			)}
			<Container maxWidth="xl" sx={{ pl: '16px', pr: '16px' }}>
				<SelectProvider
					setProvider={setProvider}
					providerDefault={workspaceData.provider}
				/>
			</Container>
			<FormDynamic {...formPropsEdit} />
		</>
	) : workspaceData.id ? (
		<>
			<Breadcrumbs links={linksDetails} />
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12}>
					<Typography variant="h2" component="h1">
						{workspaceData.name}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<WorkspaceDetails
						{...{
							workspaceData: {
								...workspaceData,
								price_type: priceType[workspaceData.price_type],
							},
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Paper variant="outlined">
								{workspaceData.provider && (
									<ProviderDetails
										providerData={workspaceData.provider}
									/>
								)}
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	) : (
		<img
			src={loading}
			style={{ margin: 'auto', display: 'block' }}
			width="50px"
			height="50px"
			alt="Cargando ..."
		/>
	);
};

export default WorkspaceSummary;
