import {useParams,useOutletContext} from 'react-router-dom';
import usePNavBar from '../../hooks/usePNavBar';
import { useEffect } from 'react';

const PQuotationClientSetProvider = () => {
    const params = useParams();
    const {goTo} = usePNavBar();
    const {setProviderPageId,setShowLoader} = useOutletContext();
    useEffect(()=>{
        setShowLoader(true);
        localStorage.setItem("providerID",params.provider);
        setProviderPageId(params.provider);
        goTo("/cotizar");
    },[])

    return (<>Redirect</>);
}

export default PQuotationClientSetProvider;