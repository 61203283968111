import {Navigate} from 'react-router-dom';
import AppLayout from '../pages/AppLayout';
import PClientQuotation from '../pages/public/PClientQuotation';
import PClientQuotationSteps from '../pages/public/PClientQuotationSteps'
import PQuotationClientSetProvider from '../pages/public/PQuotationClientSetProvider';
import ConfigEventFormats from '../pages/ConfigEventFormats';
import ConfigEventType from '../pages/ConfigEventType';
import ConfigLayout from '../pages/ConfigLayout';
import ConfigProviderCategory from '../pages/ConfigProviderCategory';
import ConfigProviderType from '../pages/ConfigProviderType';
import ConfigServiceType from '../pages/ConfigServiceType';
import DashBoard from '../pages/DashBoard';
import LoginForm from '../pages/LoginForm';
import NegotiationSummary from '../pages/NegotiationSummary';
import NegotiationList from '../pages/NegotiationList';
import QueryList from '../pages/QueryList';
import RequestRedirectedList from '../pages/RequestRedirectedList'
import NegotiationNew from '../pages/NegotiationNew';
import ProviderSummary from '../pages/ProviderSummary';
import ProviderList from '../pages/ProviderList';
import PClientLogin from '../pages/public/PClientLogin';
import PClientRequests from '../pages/public/PClientRequests';
import PClientRequestsDetails from '../pages/public/PClientRequestsDetails';
import UserDetails from '../pages/UserDetails';
import UserList from '../pages/UserList';
import WorkspaceSummary from '../pages/WorkspaceSummary';
import WorkspaceList from '../pages/WorkspaceList';
import ServiceSummary from '../pages/ServiceSummary';
import ServiceList from '../pages/ServiceList';
const token = () => localStorage.getItem(process.env.REACT_APP_TOKEN_STORE);


/* Doc: https://reactrouter.com/docs/en/v6/getting-started/concepts */
const Routes = () => [
	{
		path: '/',
		element: <Navigate to="/cotizar"/>,
	},
	{
		path: '/cotizar',
		element: <PClientQuotation />,
		children: [
			{
				index: true,
				element: <PClientQuotationSteps step="1.1"/>,
			},
			{
				path: '1_2',
				element: <PClientQuotationSteps step="1.2"/>,
			},
			{
				path: '2',
				element: <PClientQuotationSteps step="2"/>,
			},
			{
				path: '3',
				element: <PClientQuotationSteps step="3"/>,
			},
			{
				path: ':provider',
				element: <PQuotationClientSetProvider />,
			},
			
		],
	},
	{
		path: '/acceso',
		element: <PClientLogin />,
	},
	{
		path: '/cuenta/eventos',
		children: [
			{
				index: true,
				element: <PClientRequests />,
			},
			{
				path:':id',
				element: <PClientRequestsDetails />,
			}
		],
	},
	{
		path: '/admin',
		element: token() ? <AppLayout /> : <LoginForm />,
		children: [
			{
				index: true,
				element: <DashBoard />,
			},
			{
				path: 'users',
				children: [
					{
						index: true,
						element: <UserList />,
					},
					{
						path: ':id',
						element: <UserDetails />,
					},
					{
						path: ':id/edit',
						element: <UserDetails edit />,
					},
					{
						path: ':id/reset',
						element: <UserDetails resetPassword />,
					},
				],
			},
			{
				path: 'providers',
				children: [
					{
						index: true,
						element: <ProviderList />,
					},
					{
						path: ':id',
						element: <ProviderSummary />,
					},
					{
						path: ':id/edit',
						element: <ProviderSummary edit />,
					},
				],
			},
			{
				path: 'services',
				children: [
					{
						index: true,
						element: <ServiceList />,
					},
					{
						path: ':id',
						element: <ServiceSummary />,
					},
					{
						path: ':id/edit',
						element: <ServiceSummary edit />,
					},
				],
			},
			{
				path: 'workspaces',
				children: [
					{
						index: true,
						element: <WorkspaceList />,
					},
					{
						path: ':id',
						element: <WorkspaceSummary />,
					},
					{
						path: ':id/edit',
						element: <WorkspaceSummary edit />,
					},
				],
			},
			{
				path: 'configuration',
				element: <ConfigLayout />,
				children: [
					{
						index: true,
						element: <ConfigProviderCategory />,
					},
					{
						index: true,
						path: 'provider-category',
						element: <ConfigProviderCategory />,
					},
					{
						path: 'provider-type',
						element: <ConfigProviderType />,
					},
					{
						path: 'event-format',
						element: <ConfigEventFormats />,
					},
					{
						path: 'event-type',
						element: <ConfigEventType />,
					},
					{
						path: 'service-type',
						element: <ConfigServiceType />,
					},
				],
			},
			{
				path: 'negotiations',
				children: [
					{
						index: true,
						element: <NegotiationList />,
					},
					{
						path: ':id',
						element: <NegotiationSummary />,
					},
					{
						path: ':id/edit',
						element: <NegotiationSummary edit />,
					},
				],
			},
			{
				path: 'queries',
				element: <QueryList />,
			},
			{
				path: 'requestRedirected',
				element: <RequestRedirectedList />,
			},
		],
	},
];

export default Routes;
