const inputs = ({
	regions,
	communes,
	}) => [
	{
		type: 'text',
		label: 'Rut',
		name: 'rut',
		placeholder: '11222333-4',
		required: true,
		autoFocus: true,
	},
	{
		type: 'text',
		label: 'Nombre / Razón Social',
		name: 'name',
		placeholder: 'Ej: Hotel III',
		required: true,
	},
	{
		type: 'text',
		label: 'Giro',
		name: 'giro',
		placeholder: 'Hoteleria',
		required: true,
	},
	{
		type: 'select',
		label: 'Región',
		name: 'region',
		options: regions,
		required: true,
	},
	{
		type: 'select',
		label: 'Comuna',
		name: 'commune',
		options: communes,
		required: true,
	},
	{
		type: 'text',
		label: 'Dirección',
		name: 'address',
		placeholder: 'Ej: Irarrázaval 3550, Ñuñoa, Región Metropolitana, Chile',
		required: true,
	},
	{
		type: 'email',
		label: 'Email',
		name: 'email',
		placeholder: 'Ej: email@email.com',
		required: true,
	},
	{
		type: 'text',
		label: 'Teléfono',
		name: 'telephon',
		placeholder: 'Ej: +56912345678',
	}
];

export default inputs;
