import { compose, applyMiddleware, createStore } from 'redux';
import reducer from './reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const asyncMiddleware = store => next => action => {
	if (typeof action === 'function') {
		return action(store.dispatch, store.getState);
	}
	return next(action);
};

const store = createStore(
	reducer,
	composeEnhancers(applyMiddleware(asyncMiddleware))
);

export default store;
