/* Librerias */
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import Routes from './routers/main-routers';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import actions from './redux/action';
import ThemeOptions from './ThemeOptions';

import TagManager from 'react-gtm-module';
 
const tagManagerArgs = {
    gtmId: 'GTM-KHZ7LTK'
}
 
TagManager.initialize(tagManagerArgs)


const fetchUser = actions.fetchUser;
const fetchGetConfigurations = actions.fetchGetConfigurations;

const theme = createTheme(ThemeOptions);

const App = () => {
	const dispatch = useDispatch();
	
	useEffect(() => {
		dispatch(fetchUser());
		dispatch(fetchGetConfigurations("unauntenticathed"));

	}, [dispatch]);

	const elements = useRoutes(Routes());
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{elements}
		</ThemeProvider>
	);
};

export default App;
