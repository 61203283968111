import api from '../../plugins/api';

/* Agregando un nuevo dato bancario */
const pushProviderContacts = form => () =>
	api().post(`/proveedor-contactos`, form);

/* Actualizando un dato bancario */
const putProviderContacts = (id, form) => () =>
	api().put(`/proveedor-contactos/${id}`, form);

/* Elimando un dato bancario */
const deleteProviderContacts = id => () =>
	api().delete(`/proveedor-contactos/${id}`);

const providerContacts = {
	pushProviderContacts,
	putProviderContacts,
	deleteProviderContacts,
};

export default providerContacts;
