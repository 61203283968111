import { combineReducers } from 'redux';

import {
	makeFetchingReducer,
	makeSetReducer,
	reduceReducer,
	mat,
} from '../utils';
const asyncUser = mat('user');

const fetchingReducer = makeFetchingReducer(asyncUser);

const fulfilledReducer = makeSetReducer(['user/fulfilled']);

const makeCrudReducer =
	actions =>
	(state = {}, action) => {
		switch (action.type) {
			default:
				return state;
		}
	};

const crudReducer = makeCrudReducer([]);
const userReducer = reduceReducer(crudReducer, fulfilledReducer);

const reducer = combineReducers({
	entity: userReducer,
	status: fetchingReducer,
});

export default reducer;
