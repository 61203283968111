import { combineReducers } from 'redux';

import {
	makeFetchingReducer,
	makeSetReducer,
	reduceReducer,
	mat,
} from '../utils';

/* Control de listado */
const asyncServices = mat('services');
const fetchingReducer = makeFetchingReducer(asyncServices);
const fulfilledReducer = makeSetReducer(['services/fulfilled']);

const makeCrudReducer =
	actions =>
	(state = [], action) => {
		switch (action.type) {
			case actions[0]: {
				return state.filter(item => item.id !== action.payload);
			}
			case actions[1]: {
				return [action.payload].concat(state);
			}
			case actions[2]: {
				return state.map(item =>
					item.id === action.payload.id ? action.payload : item
				);
			}
			case actions[3]: {
				return [];
			}
			default:
				return state;
		}
	};

const crudServicesReducer = makeCrudReducer([
	'services/remove',
	'services/add',
	'services/update',
	'services/clear',
]);
const servicesReducer = reduceReducer(crudServicesReducer, fulfilledReducer);

const total = (state = 0, action) => {
	switch (action.type) {
		case 'service-total/set': {
			return action.payload;
		}
		case 'service-total/add': {
			return state + 1;
		}
		case 'service-total/remove': {
			return state - 1;
		}
		case 'service-total/clear': {
			return 0;
		}
		default:
			return state;
	}
};

const reducer = combineReducers({
	entities: servicesReducer,
	total,
	status: fetchingReducer,
});

export default reducer;
