import { combineReducers } from 'redux';

import {
	makeFetchingReducer,
	makeSetReducer,
	reduceReducer,
	mat,
} from '../utils';
const asyncConfigurations = mat('configurations');

const fetchingReducer = makeFetchingReducer(asyncConfigurations);

const fulfilledReducer = makeSetReducer(['configurations/fulfilled']);

/* Dando el valor inicial al state */
const makeCrudReducer =
	actions =>
	(state = { userInfos: { list: [], total: 0 } }, action) => {
		switch (action.type) {
			case actions[0]: {
				const response = {
					...state,
					userInfos: {
						...state.userInfos,
						total: action.payload,
					},
				};
				return response;
			}
			default:
				return state;
		}
	};

const crudReducer = makeCrudReducer(['configurations/total-user']);
const configurationsReducer = reduceReducer(crudReducer, fulfilledReducer);

const reducer = combineReducers({
	entity: configurationsReducer,
	status: fetchingReducer,
});

export default reducer;
