import { combineReducers } from 'redux';

import {
	makeFetchingReducer,
	makeSetReducer,
	reduceReducer,
	mat,
} from '../utils';
const asyncSessions = mat('sessions');

const fetchingReducer = makeFetchingReducer(asyncSessions);

const fulfilledReducer = makeSetReducer(['sessions/fulfilled']);

const makeCrudReducer =
	actions =>
	(state = [], action) => {
		switch (action.type) {
			default:
				return state;
		}
	};

const crudReducer = makeCrudReducer([]);
const sessionReducer = reduceReducer(crudReducer, fulfilledReducer);

const reducer = combineReducers({
	entities: sessionReducer,
	status: fetchingReducer,
});

export default reducer;
