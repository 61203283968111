import {Grid,Box,Button,Stack,Card,CardContent,CardActionArea,Typography,Link,Modal,IconButton
} from '@mui/material';
import {Close} from '@mui/icons-material';
import { getElementError } from '@testing-library/react';
import { useRef,useState, useEffect } from 'react';
import { printMoney } from '../../plugins/utils';
import LoaderSingle from '../LoaderSingle';

const ProviderCard = ({provider,setProviderIframe,providerCardSelected,handleMCOpen,printMoney}) => {
    return (
        <Grid item xs={12} md={4}>
                <Card id={"p-"+provider.id} sx={{boxShadow: 3}}>
                    <CardContent>
                        <Typography variant="h6"  color="">
                        {provider.name}
                        </Typography>
                        <Stack 
                            sx={{mt:1,mb:1}} spacing={2}
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
						>
                            <Link
                                component="button"
                                variant="h6"
                                underline="none"
                                color="secondary"
                                onClick={() => {
                                    setProviderIframe(provider.url_iframe);
                                    handleMCOpen();
                                }}
                            >
                                Ver ficha
                            </Link>
                        </Stack>
                        
                        <CardActionArea onClick={()=>  providerCardSelected(provider.id)}>
                        {provider.total>0 ? (
                        <><Typography variant="body2" sx={{ mb: -0.5 }} color="text.secondary">
                        Desde
                        </Typography>
                        <Typography variant="h5" sx={{mb: 0.5}} component="div">
                            {printMoney(provider.total)}
                            <Typography variant="body1" sx={{ pl:0.5}} display="inline-block">
                                IVA Inc 
                            </Typography>
                        </Typography>
                        <Stack sx={{mb:1.5}} spacing={2}
                            direction="row"
                        >
                            <Box>
                                <Typography variant="body1" sx={{ mb: -0.5 }} color="text.secondary">
                                Salon
                                </Typography>
                                <Typography variant="h6" component="div">
                                {provider.workspace.total > 0 ? printMoney(provider.workspace.total) : "SIN PRECIOS"}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body1" sx={{ mb: -0.5 }} color="text.secondary">
                                Servicios
                                </Typography>
                                <Typography variant="h6" component="div">
                                {provider.services.total > 0 ? printMoney(provider.services.total) : "SIN PRECIOS"}
                                </Typography>
                            </Box>
                        </Stack></>
                        ):(
                            <Typography variant="body1" sx={{ mb: -0.5 }} color="text.secondary">
                            Aún no estan los precios en sistema, pero si desea, seleccione este proveedor y un ejecutivo se comunicará con ud para realizar la cotización.
                            </Typography>
                        )}
                        <Box sx={{display: 'inline-block', width:"100%", textAlign: "center"}}>
                            <Typography variant="h6" sx={{fontWeight:"bold"}} component="div"  color="primary">
                                Seleccionar
                            </Typography>
                        </Box>
                        </CardActionArea>
                    </CardContent>
                </Card>
            </Grid>
    );
}

const PQuotationCardsProviders = ({providers,setProviderSelected,goStep3,scrollToBottom,setShowLoader,providerSuggested,providerPage}) => {
    const [modalCOpen, setModalCOpen] = useState(false);
	const handleMCOpen = () => {setModalCOpen(true);setLoaderIframe(true)};
	const handleMCClose = () => setModalCOpen(false);
    const [providerIframe,setProviderIframe] = useState("");
    const [loaderIframe,setLoaderIframe] = useState(false);

    useEffect(()=>{
		if(loaderIframe){
			setTimeout(()=>{
				setLoaderIframe(false);
			},3000);
		}
	},[loaderIframe])

    const providerCardSelected = (id) => {
        var getProvider = providers.find(provider => provider.id === id);
        localStorage.setItem("providerSelected", JSON.stringify(getProvider));
        setProviderSelected(getProvider);
		providers?.map(provider => {
			document.getElementById("p-"+provider.id).style.border = "none";
		})
		document.getElementById("p-"+id).style.border = "solid 3px #002984";
        goStep3();
	}

    const boxModalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '90%',
        height: '80%',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: "5px",
		pt:"20px"
    };

    return (
        <Grid container spacing={2}>
            <Modal
				open={modalCOpen}
				onClose={handleMCClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
                keepMounted
			>
				<Box sx={boxModalStyle}>
                    <Stack 
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Button
                            endIcon={<Close />}
                            variant="contained"
                            color="secondary"
                            onClick={handleMCClose}
                        >
                            {"Cerrar ficha"}
                        </Button>
                    </Stack>
                    <Box sx={{display:"contents"}}>
                        <div style={{width:"96%", height:"90%",margin:"2%",border:"solid 3px",display: loaderIframe ? "block":"none"}}>
							<LoaderSingle />
						</div>
                        {providers?.map((provider) => (
                        <iframe src={provider.url_iframe} style={{width:"96%", height:"90%",margin:"2%",border:"solid 3px", display:providerIframe===provider.url_iframe && !loaderIframe ? "block":"none"}}></iframe>
                        ))}
                    </Box>
				</Box>
			</Modal>
            {providerPage && providers.find(element => element.id === providerPage.id) && 
                <ProviderCard provider={providers.find(element => element.id === providerPage.id)} 
                                setProviderIframe={setProviderIframe} providerCardSelected={providerCardSelected} 
                                handleMCOpen={handleMCOpen} printMoney={printMoney}/>
            }
            {providers?.map((provider) => { 
                if(providerPage){
                    if(provider.id !== providerPage.id){
                        return(
                            <ProviderCard provider={provider} setProviderIframe={setProviderIframe} providerCardSelected={providerCardSelected} 
                                            handleMCOpen={handleMCOpen} printMoney={printMoney}/>
                        );
                    }
                }else{
                    return(
                        <ProviderCard provider={provider} setProviderIframe={setProviderIframe} providerCardSelected={providerCardSelected} 
                                        handleMCOpen={handleMCOpen} printMoney={printMoney}/>
                    );
                }
            })}
        </Grid>
    );
};

export default PQuotationCardsProviders;
