import api from '../../plugins/api';
import { mat, asyncMac } from '../utils';
import configurations from './configurations';
const fetchGetConfigurations = configurations.fetchGetConfigurations;

const nameStore = process.env.REACT_APP_TOKEN_STORE;

/* Usuario que inicia sesion */
const asyncUser = mat('user');
const [setPending, setFulfilled, setError] = asyncMac(asyncUser);

/* Datos del usuario de la sesion */
const fetchUser = () => dispatch => {
	const token = localStorage.getItem(nameStore);

	if (token) {
		dispatch(setPending());
		return api()
			.get(`/users/me`)
			.then(({ data }) => {
				dispatch(setFulfilled(data));
				const role = data.role?.type;
				dispatch(fetchGetConfigurations(role));
			})
			.catch(error => dispatch(setError(error)));
	}

	return null;
};

/* Log in para iniciar la sesion */
const fetchUserLogin = form => async dispatch => {
	dispatch(setPending());

	if (form)
		return api()
			.post(`/auth/local`, form)
			.then(res => {
				const { data } = res;
				const jwt = data.jwt;
				localStorage.setItem(nameStore, jwt);

				dispatch(setFulfilled(data.user));
				const role = data.user?.role?.type;
				dispatch(fetchGetConfigurations(role));
			})
			.catch(err => {
				dispatch(setError(err));
			})
			.finally(() => dispatch({ type: 'user/form', payload: {} }));

	return null;
};

/* ADMINISTRACION DE USUARIOS */

/* Leer usuarios del sistema */
const fetchUsers = params => (dispatch, getState) => {
	const state = getState();
	dispatch({ type: 'configurations/pending' });

	const limit = 0;/*params?.limit || process.env.REACT_APP_PER_PAGE;*/

	let query = `?_limit=${limit}&_start=${params?.start || 0}`;

	if (params?.query?.length) query += params.query;

	return api()
		.get(`/user-infos${query}`)
		.then(({ data }) => {
			const configurations = state.configurations.entity;
			configurations.userInfos.list = [
				...configurations.userInfos.list,
				...data,
			];
			dispatch({
				type: 'configurations/fulfilled',
				payload: configurations,
			});
		})
		.catch(error => dispatch(setError(error)));
};

/* Total usuarios */
const fetchTotalUser = () => dispatch => {
	/* Filtros para contar */
	let query = `?`;

	return api()
		.get(`/user-infos/count${query}`)
		.then(({ data }) => {
			dispatch({
				type: 'configurations/total-user',
				payload: data,
			});
		})
		.catch(error => dispatch(setError(error)));
};

/* Este se debe llamar cuando el usuario ingresa directo a la URL y no existe lista */
const readUser = id => (dispatch, getState) => {
	const state = getState();
	dispatch({ type: 'configurations/pending' });

	return api()
		.get(`/user-infos/${id}`)
		.then(({ data }) => {
			const configurations = state.configurations.entity;
			configurations.userInfos.list = [
				...configurations.userInfos.list,
				data,
			];
			/* Para obligar la carga de la lista al visitar */
			configurations.userInfos.total = 0;
			dispatch({
				type: 'configurations/fulfilled',
				payload: configurations,
			});
		})
		.catch(error => dispatch(setError(error)));
};

/* Agregando un nuevo usuario */
const pushUser = form => async (dispatch, getState) => {
	const state = getState();
	dispatch({ type: 'configurations/pending' });
	return api()
		.post(`/user-infos`, form)
		.then(({ data }) => {
			/* Agregando al inicio al especialista */
			const configurations = state.configurations.entity;
			data.new = true;
			configurations.userInfos.total = configurations.userInfos.total + 1;
			configurations.userInfos.list = [data].concat(
				configurations.userInfos.list
			);
			dispatch({
				type: 'configurations/fulfilled',
				payload: configurations,
			});
		})
		.catch(error => dispatch(setError(error)));
};

/* Actualizando un especialistas */
const putUser = (id, form) => async (dispatch, getState) => {
	const state = getState();
	dispatch({ type: 'configurations/pending' });
	return api()
		.put(`/user-infos/${id}`, form)
		.then(({ data }) => {
			const configurations = state.configurations.entity;
			configurations.userInfos.list = configurations.userInfos.list.map(
				item => (item.id === id ? data : item)
			);
			dispatch({
				type: 'configurations/fulfilled',
				payload: configurations,
			});
		})
		.catch(error => dispatch(setError(error)));
};

/* Elimando un especialistas */
const deleteUser = id => async (dispatch, getState) => {
	const state = getState();
	dispatch({ type: 'configurations/pending' });
	return api()
		.delete(`/user-infos/${id}`)
		.then(() => {
			const configurations = state.configurations.entity;
			configurations.userInfos.total = configurations.userInfos.total - 1;
			configurations.userInfos.list =
				configurations.userInfos.list.filter(item => item.id !== id);
			dispatch({
				type: 'configurations/fulfilled',
				payload: configurations,
			});
		})
		.catch(error => dispatch(setError(error)));
};

/* Limpiar la lista de usuarios */
const clearUsers = () => (dispatch, getState) => {
	const state = getState();
	dispatch({ type: 'configurations/pending' });
	const configurations = state.configurations.entity;
	configurations.userInfos = { list: [], total: 0 };
	dispatch({
		type: 'configurations/fulfilled',
		payload: configurations,
	});
};

const user = {
	fetchUser,
	fetchUserLogin,
	fetchUsers,
	readUser,
	fetchTotalUser,
	pushUser,
	putUser,
	deleteUser,
	clearUsers,
};

export default user;
