import * as React from 'react';
import PropTypes from 'prop-types';
import {AppBar ,
        Box,
        Divider ,
        Drawer ,
        IconButton ,
        List ,
        ListItem ,
        ListItemButton ,
        ListItemText ,
        Toolbar ,
        Typography,
        Button,
        Link} 
from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/logo.png';
import usePNavBar from '../../hooks/usePNavBar';


const drawerWidth = 240;
const navItems = [{href:'/',text:"Cotizar"},{href:'/acceso',text:"Mis Eventos"},{href:'https://salonesyeventos.cl',text:"S&E.CL"}];

function PNavBar(props) {
  const { windowProp } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const {goTo} = usePNavBar();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' ,pt: "10px"}}>
      <img
            src={logo}
            alt="LOGO SALONESYEVENTOS.CL"
            loading="lazy"
            width="145px"
          />
      <Divider />
      <List>
        {navItems.map(({href,text}) => (
            <ListItemButton sx={{ textAlign: 'center' }} underline="none" color="secondary"  onClick={() => {goTo(href)}}>
              <ListItemText primary={text} primaryTypographyProps={{color: 'secondary'}}/>
            </ListItemButton>
        ))}
      </List>
    </Box>
  );

  const container = windowProp !== undefined ? () => windowProp().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' ,height:'100px'}}>
      <AppBar component="nav" color="light">
        <Toolbar disableGutters >
          <Box sx={{ flexGrow: 1, pl: "10px", pt: "5px"}}>
            <img
              src={logo}
              alt="LOGO SALONESYEVENTOS.CL"
              loading="lazy"
              width="145px"
            />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' }, pr: "30px" }}>
            {navItems.map(({href,text}) => (
              <Link sx={{ml:3, fontSize:"1rem"}} component="button" underline="none" color="secondary" onClick={() => {goTo(href)}}>
                {text}
              </Link>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 1, display: { xs:'flex',sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

PNavBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windowProp: PropTypes.func,
};

export default PNavBar;