import { Paper, Typography, Button } from '@mui/material';
import FormDynamic from './FormDynamic';
import DataListDynamic from '../components/DataListDynamic';
import { useProviderBankDetail, useProvider } from '../hooks';
import { useState } from 'react';

const Form = ({ providerBankDetailsData, providerID, cancel }) => {
	const ID = providerBankDetailsData?.id;
	const {
		formInputs: inputs,
		createProviderBankDetails,
		updateProviderBankDetails,
	} = useProviderBankDetail({ ID });

	const { resetList } = useProvider({});

	const formProps = {
		validateFunction: () => {},
		inputs,
		defaultValues: {
			bank_name: '',
			account_type: '',
			account_number: '',
			rut: '',
			name: '',
			email: ''
		},
		cancelFunction: cancel,
	};

	const formPropsNew = {
		...formProps,
		submitFunction: data => {
			const toSend = { ...data, provider: providerID };
			createProviderBankDetails(toSend).then(() => {
				/* Se refresca proveedor */
				resetList();
				cancel();
			});
		},
		buttonText: 'Crear',
	};

	const formPropsEdit = {
		...formProps,
		submitFunction: data => {
			const toSend = { ...data, provider: providerID };
			updateProviderBankDetails(ID, toSend).then(() => {
				/* Se refresca proveedor */
				resetList();
				cancel();
			});
		},
		defaultValues: providerBankDetailsData,
		buttonText: 'Actualizar',
	};

	if (ID && formPropsEdit?.defaultValues?.id)
		return <FormDynamic cancelFunction={cancel} {...formPropsEdit} />;
	if (!ID) return <FormDynamic cancelFunction={cancel} {...formPropsNew} />;

	return 'Cargando datos';
};

/* Configurando responsividad de la tabla. Siempre trabajar en base a 11 porque 1 está destinado al boton eliminar. */
const widthCells = { description: { xs: 10 } };
const rowTitle = {
	description: (
		<Typography variant="subtitle2" component="div">
			Detalles
		</Typography>
	),
};

const ProviderDetailsBankData = ({ providerData }) => {
	const [providerBankDetails, setProviderBankDetails] = useState('');
	const { resetList } = useProvider({});
	const { deleteProviderBankDetails } = useProviderBankDetail({ ID: 'new' }); //Se coloca new para no se ejecute la solicitud de un listado, ni se ejecute la lectura de un data bancario
	const cancelForm = () => setProviderBankDetails('');

	const account_type_val = {
		corriente:"Cuenta Corriente",
		ahorro:"Cuenta de Ahorro",
		vista:"Cuenta Vista"
	}

	const rowsData = [{
		id: providerData.provider_bank_detail?.id,
		description: (
			<>
				Nombre: {providerData.provider_bank_detail?.name}
				<br />
				RUT: {providerData.provider_bank_detail?.rut}
				<br />
				Banco: {providerData.provider_bank_detail?.bank_name}
				<br />
				{account_type_val[providerData.provider_bank_detail?.account_type]}
				<br />
				N° {providerData.provider_bank_detail?.account_number}
				<br />
				Email: {providerData.provider_bank_detail?.email}
				<br />
			</>
		),
		inactive: false,
		onClick: () => setProviderBankDetails(providerData?.provider_bank_detail),
	}];

	return (
		<Paper variant="outlined" sx={{ padding: '16px' }}>
			{providerBankDetails === 'new' ? (
				<>
					<Typography variant="h6" component="h2">
						Asignando datos bancarios
					</Typography>
					<Form cancel={cancelForm} providerID={providerData.id} />
				</>
			) : !['', 'new'].includes(providerBankDetails) ? (
				<>
					<Typography variant="h6" component="h2">
						Actualizando datos bancarios
					</Typography>
					<Form
						cancel={cancelForm}
						providerID={providerData.id}
						providerBankDetailsData={providerBankDetails}
					/>
				</>
			) : (
				<>
					<Typography variant="h6" component="h2">
						Datos Bancarios
					</Typography>
					{!providerData.provider_bank_detail?.id &&(
						<Button
							type="button"
							fullWidth
							variant="outlined"
							sx={{ mt: '16px', mb: 2 }}
							onClick={() => setProviderBankDetails('new')}
						>
							Agregar
						</Button>
					)}
					{providerData.provider_bank_detail?.id && (
						<DataListDynamic
							{...{ rowTitle, rowsData, widthCells }}
							deleteFunction={null}
						/>
					)}
				</>
			)}
		</Paper>
	);
};

export default ProviderDetailsBankData;
