import api from '../../plugins/api';
import { mat, asyncMac } from '../utils';

const asyncUser = mat('budgets');
const [setPending, setFulfilled, setError] = asyncMac(asyncUser);

/* Listar a los negociaciones */
const fetchBudgets = params => (dispatch, getState) => {
	dispatch(setPending());
	const state = getState();

	let query = `?_limit=${process.env.REACT_APP_PER_PAGE}&_start=${
		params?.start || 0
	}`;

	if (params?.query?.length) query += params.query;

	return api()
		.get(`/budgets${query}`)
		.then(({ data }) => {
			const entities = state.budgets.entities;
			dispatch(setFulfilled([...entities, ...data]));
		})
		.catch(error => dispatch(setError(error)));
};

/* Total negociaciones */
const fetchTotalBudget = params => dispatch => {
	/* Filtros para contar */
	let query = `?`;
	if (params?.query?.length) query += params.query;

	return api()
		.get(`/budgets/count${query}`)
		.then(({ data }) => {
			dispatch({ type: 'budget-total/set', payload: data });
		})
		.catch(error => dispatch(setError(error)));
};

/* Agregando una nueva negociación */
const pushBudget = form => async dispatch => {
	dispatch(setPending());
	return api()
		.post(`/budgets`, form)
		.then(({ data }) => {
			dispatch({ type: 'budgets/add', payload: data });
			dispatch({ type: 'budget-total/add' });
		})
		.catch(error => dispatch(setError(error)));
};

/* Actualizando una negociación */
const putBudget = (id, form) => async dispatch => {
	dispatch(setPending());
	return api()
		.put(`/budgets/${id}`, form)
		.then(({ data }) => {
			dispatch({ type: 'budgets/update', payload: data });
		})
		.catch(error => dispatch(setError(error)));
};

/* Limpiar la lista de negociaciones */
const clearBudgets = () => dispatch => {
	dispatch({ type: 'budgets/clear' });
	dispatch({ type: 'budget-total/clear' });
};

/* INDIVIDUAL */
/* Esta instancia se ejecuta cuando el usuario ingresa directo a la URL y no existe lista */
const readBudget = id => dispatch => {
	dispatch(setPending());

	return api()
		.get(`/budgets/${id}`)
		.then(({ data }) => {
			dispatch(setFulfilled([data]));
		})
		.catch(error => dispatch(setError(error)));
};

/* Elimando una negociación */
const deleteBudget = id => async dispatch => {
	dispatch(setPending());
	return api()
		.delete(`/budgets/${id}`)
		.then(() => {
			dispatch({ type: 'budgets/remove', payload: id });
			dispatch({ type: 'budget-total/remove' });
		})
		.catch(error => dispatch(setError(error)));
};

const budgets = {
	fetchBudgets,
	readBudget,
	fetchTotalBudget,
	pushBudget,
	putBudget,
	deleteBudget,
	clearBudgets,
};

export default budgets;
