import { Paper, Typography, Button } from '@mui/material';
import FormDynamic from './FormDynamic';
import DataListDynamic from '../components/DataListDynamic';
import { useService, useProvider } from '../hooks';
import { useState } from 'react';

const Form = ({ ID, provider, cancel }) => {
	const providerID = provider.id;
	const {
		serviceData,
		formInputs: inputs,
		createService,
		updateService,
	} = useService({ ID });

	const { resetList } = useProvider({});

	const formProps = {
		validateFunction: () => {},
		inputs: inputs,
		defaultValues: {
			name: '',
			description: '',
			price: '0',
			price_provider: '0',
			minimum: '0',
			maximum: '0',
			service_types: '',
			status: 'inactive',
		},
		cancelFunction: cancel,
	};

	const formPropsNew = {
		...formProps,
		submitFunction: data => {
			const toSend = { ...data, provider: providerID };
			createService(toSend).then(() => {
				/* Se refresca proveedor */
				resetList();
				cancel();
			});
		},
		buttonText: 'Crear',
	};

	const formPropsEdit = {
		...formProps,
		submitFunction: data => {
			const toSend = { ...data, provider: providerID };
			updateService(ID, toSend).then(() => {
				/* Se refresca proveedor */
				resetList();
				cancel();
			});
		},
		defaultValues: {
			...serviceData,
			service_types: serviceData.service_types?.id,
		},
		buttonText: 'Actualizar',
	};

	if (ID && formPropsEdit?.defaultValues?.id)
		return <FormDynamic cancelFunction={cancel} {...formPropsEdit} />;
	if (!ID) return <FormDynamic cancelFunction={cancel} {...formPropsNew} />;

	return 'Cargando datos';
};

/* Configurando responsividad de la tabla. Siempre trabajar en base a 11 porque 1 está destinado al boton eliminar. */
const widthCells = { name: { xs: 7 }, price: { xs: 3 } };
const rowTitle = {
	name: (
		<Typography variant="subtitle2" component="div">
			Nombre
		</Typography>
	),
	price: (
		<Typography variant="subtitle2" component="div">
			Precio
		</Typography>
	),
};

const ProviderDetailsServices = ({ providerData }) => {
	const [serviceId, setServiceId] = useState('');
	const { resetList } = useProvider({});
	const { deleteService } = useService({ ID: 'new' }); //Se coloca new para no se ejecute la solicitud de un listado, ni se ejecute la lectura de un servicio
	const cancelForm = () => setServiceId('');

	const rowsData = providerData.services.map(item => ({
		id: item.id,
		name: item.name,
		price: item.price,
		inactive: item.status === 'inactive',
		onClick: () => setServiceId(item.id),
	}));

	return (
		<Paper variant="outlined" sx={{ padding: '16px' }}>
			{serviceId === 'new' ? (
				<>
					<Typography variant="h6" component="h2">
						Nuevo servicio
					</Typography>
					<Form cancel={cancelForm} provider={providerData} />
				</>
			) : !['', 'new'].includes(serviceId) ? (
				<>
					<Typography variant="h6" component="h2">
						Actualizando servicio
					</Typography>
					<Form
						cancel={cancelForm}
						provider={providerData}
						ID={serviceId}
					/>
				</>
			) : (
				<>
					<Typography variant="h6" component="h2">
						Lista de servicios
					</Typography>
					<Button
						type="button"
						fullWidth
						variant="outlined"
						sx={{ mt: '16px', mb: 2 }}
						onClick={() => setServiceId('new')}
					>
						Agregar
					</Button>
					{rowsData?.length > 0 && (
						<DataListDynamic
							{...{ rowTitle, rowsData, widthCells }}
							deleteFunction={id => {
								deleteService(id).then(() =>
									resetList()
								);
							}}
						/>
					)}
				</>
			)}
		</Paper>
	);
};

export default ProviderDetailsServices;
