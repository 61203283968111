const sortData = (objs, prop, type = '') => {
	/* Ordenar arraglo de objetos */
	objs.sort(function (a, b) {
		if (type.toLowerCase() === 'asc') {
			if (a[prop] > b[prop]) return 1;
			if (a[prop] < b[prop]) return -1;
		} else {
			if (a[prop] > b[prop]) return -1;
			if (a[prop] < b[prop]) return 1;
		}
		/* a debe ser igual a b */
		return 0;
	});
};

const printMoney = num => {
	//return Number.isInteger( num ) ? 'CLP ' + num : 'CLP ' + parseFloat( num ).toFixed( 2 );
	return num
		? num.toLocaleString('es-cl', {
				style: 'currency',
				currency: 'CLP',
		  })
		: 0;
};

const normalizeText = text =>
	text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export { sortData, normalizeText, printMoney };
