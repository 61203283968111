const inputs = [
	{
		type: 'text',
		label: 'Nombre',
		name: 'name',
		placeholder: 'Ej: Jose Muñoz',
		required: true,
		autoFocus: true,
	},
	{
		type: 'text',
		label: 'RUT',
		name: 'rut',
		placeholder: 'Ej: 11.222.333-4',
		required: true,
	},
	{
		type: 'text',
		label: 'Banco',
		name: 'bank_name',
		placeholder: 'Ej: ',
		required: true,
	},
	{
		type: 'select',
		label: 'Tipo de cuenta',
		name: 'account_type',
		options: [
			{ name: 'Corriente', value: 'corriente' },
			{ name: 'Ahorro', value: 'ahorro' },
			{ name: 'Vista', value: 'vista' },
		],
		required: true,
	},
	{
		type: 'text',
		label: 'Número de cuenta',
		name: 'account_number',
		placeholder: 'Ej: ',
		required: true,
	},
	{
		type: 'email',
		label: 'Email del proveedor',
		name: 'email',
		placeholder: 'Ej: ',
	}
];

export default inputs;
