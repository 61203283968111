import { useMenu } from '../hooks';
import {
	Paper,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
} from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

const status = {
	active: 'Activo',
	inactive: 'Inactivo',
};

const WorkspaceDetails = ({ workspaceData }) => {
	const { goTo } = useMenu();
	return (
		<Paper variant="outlined">
			<List>
				<ListItem>
					<ListItemText
						primary={
							<Typography variant="h6" component="h2">
								Detalles del salón
							</Typography>
						}
					/>
					<ListItemIcon>
						<IconButton
							onClick={() =>
								goTo(`/admin/workspaces/${workspaceData.id}/edit`)
							}
						>
							<EditOutlined />
						</IconButton>
					</ListItemIcon>
				</ListItem>
				<ListItem>Nombre: {workspaceData.name}</ListItem>
				<ListItem>Descripción: {workspaceData.description}</ListItem>
				<ListItem>Área: {workspaceData.area}</ListItem>
				<ListItem>Tipo de precio: {workspaceData.price_type}</ListItem>
				<ListItem>Precio: {workspaceData.price}</ListItem>
				<ListItem>Hora miníma: {workspaceData.hours_minimum}</ListItem>
				<ListItem>
					Asistentes minímos: {workspaceData.attendees_minimum}
				</ListItem>
				<ListItem>
					Asistentes máximos: {workspaceData.attendees_maximum}
				</ListItem>
				<ListItem>Estatus: {status[workspaceData.status]}</ListItem>
			</List>
		</Paper>
	);
};

export default WorkspaceDetails;
