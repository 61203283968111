import {
	Container,
	Grid,
	Typography,
	Divider,
	SpeedDial,
	Button,
	Box,
	Tabs,
	Tab,
	Dialog,
	DialogTitle,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Avatar
} from '@mui/material';
import AlertDialog from '../components/AlertDialog';
import { DataGrid,GridActionsCellItem , GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import {Edit,Delete,AddOutlined, Info, ArrowCircleLeft, ArrowCircleRight, Cancel, PersonAdd, Person, Restore, ManageAccounts} from '@mui/icons-material';
import moment from 'moment';
import loading from '../assets/loading-pulse.svg';
import { styled } from '@mui/material/styles';

import { useNegotiation, useMenu, useUser } from '../hooks';

import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { sortData } from '../plugins/utils';

const alertMessage =
	' Estimado Administrador, recuerde que una vez eliminada la negociacion se perderan sus datos y los relacionados a ella excepto los datos del cliente.';

const StyledGridOverlay = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
}));
	
const rowTitle = {
	name: (
		<Typography variant="subtitle2" component="div">
			Nombre
		</Typography>
	),
	customer: (
		<Typography variant="subtitle2" component="div">
			Cliente
		</Typography>
	),
	seller: (
		<Typography variant="subtitle2" component="div">
			Vendedor
		</Typography>
	),
	event_types: (
		<Typography variant="subtitle2" component="div">
			Tipo evento
		</Typography>
	),
	event_formats: (
		<Typography variant="subtitle2" component="div">
			Formato evento
		</Typography>
	),
};

/* DIALOG PARA ASIGNAR EL VENDEDOR A LA NEGOCIACION */
function DialogSetSeller(props) {
	const { onClose, open, users, negotiation, updateNegotiation } = props;
  
	const handleClose = () => {
	  onClose();
	};
  
	const handleListItemClick = (id_seller) => {
		if (negotiation.id?.length>1){
			if(negotiation.status==="nuevas"){
				updateNegotiation(negotiation.id, {status:"asignadas",seller:id_seller}).then(() => {
				});
			}else{
				updateNegotiation(negotiation.id, {seller:id_seller}).then(() => {
				});
			}
			
		}
	  	onClose();
	};
  
	return (
	  <Dialog onClose={handleClose} open={open}>
		<DialogTitle>Asignar negociación</DialogTitle>
		<List sx={{ pt: 0 }}>
			{users.map((userL)=>
				(
					<ListItem button onClick={()=>handleListItemClick(userL.user?.id)} key={userL.user?.id}>
						<ListItemAvatar>
							<Avatar sx={{ bgcolor: "primary", color: "white" }}>
							<Person />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary={userL.name+" "+userL.lastname+" | "+userL.user?.email} />
					</ListItem>
				)
			)}
		</List>
	  </Dialog>
	);
}

const NegotiationList = () => {

	/* VARs PARA EL DIALOG SET SELLER */
	const [openDSS, setOpenDSS] = useState(false);
	const [negotiationDSS, setNegotiationDSS] = useState({});
  
	const handleOpenDSS = (id,status) => {
		setNegotiationDSS({id:id,status:status});
		setOpenDSS(true);
	};
  
	const handleCloseSS = () => {
		setOpenDSS(false);
	};

	const {
		negotiationList,
		totalNegotiations,
		deleteNegotiation,
		requestStatus,
		loadMore,
		filterInputs,
		customNegotiationList,
		updateNegotiation,
	} = useNegotiation({});
	const { goTo } = useMenu();

	const [userListF, setUserListF] = useState([]);
	const {
		userList
	} = useUser();

	useEffect(()=>{
		if(userList?.length>1){
			setUserListF(userList?.filter(element => element.user?.role === "62461be7b1f63a004d58a09b" || 
									   element.user?.role === "6328edc12a9a2b0056c5ec4f" ||
									   element.user?.role === "62461c09b1f63a004d58a0f8" ))
		}
	},[userList])

	const {user,rol} = useSelector(({ user }) => {
		const text = user?.entity?.role?.type;
		const user_entity = user?.entity ? user?.entity : {};
		return {user:user_entity,rol:text};
	});


	const [valueTab, setValueTab] = useState(rol==="administrator" || rol==="head_seller" ? "nuevas" : "asignadas");

	const handleChangeTab = (event, newValue) => {
		const query = rowsData?.filter(r => r.status === newValue);
		if(query.length > 1){
			setNoDataRows(false);
		}else{
			setNoDataRows(true);
		}
		setValueTab(newValue);
	};

	const [itemToDelete, setItemToDelete] = useState(null);
	const [rowsData, setRowsData] = useState([]);
	const [noDataRows, setNoDataRows] = useState(false);

	const CustomNoRowsOverlay = () => {
		if(noDataRows){
			return (
				<StyledGridOverlay>
				  <Info/>
				  No hay negociaciones
				</StyledGridOverlay>
			  );
		}else{
			return (
				<StyledGridOverlay>
				  <img
					  src={loading}
					  width="50px"
					  style={{ margin: 'auto', display: 'block' }}
					  height="50px"
					  alt="Cargando ..."
				  />
				</StyledGridOverlay>
			  );
		}
		
	}

	useEffect(() => {
		/* SORTING PROVIDERS BY UPDATE TIME */
		sortData(negotiationList, 'createdAt','desc');

		/* FILTERING PROVIDERS REMOVING DUPLICATE */
		var newArrayNegotiations = [];
		if(rol){
			if(rol==="administrator" || rol==="head_seller"){
				negotiationList?.filter(n => n.type === "request").forEach((item) => {
					let index = newArrayNegotiations.findIndex(object => {
						return object.id === item.id;
					})
					if(index===-1){
						newArrayNegotiations.push(item);
					}
				});
			}else{
				negotiationList?.filter(n => n.type === "request" && n.seller?.id === user._id).forEach((item) => {
					let index = newArrayNegotiations.findIndex(object => {
						return object.id === item.id;
					})
					if(index===-1){
						newArrayNegotiations.push(item);
					}
				});
			}
			/* SET NEW PROVIDER DATA TO DATAGRID */
			setRowsData(newArrayNegotiations?.map((item) => ({
				id: item.id,
				name: item.name,
				customer: item.customer ? item.customer?.email : "ERROR DE CLIENTE",
				seller: item.seller ? item.seller?.email : "NO ASIGNADO",
				event_types: item.event_types?.name,
				event_formats: item.event_formats?.name,
				inactive: item.status === 'inactive',
				status: item.status,
				createdAt: moment.parseZone(item.createdAt).format("DD/MM/YYYY"),
				date_start: moment.parseZone(item.date_start).format("DD/MM/YYYY"),
				updating: false
			})));
		}
		
	}, [negotiationList]);

	const statusNegotiation = [
		{value:"nuevas", label:"NUEVAS"},
		{value:"asignadas", label:"ASIGNADAS"},
		{value:"contactadas", label:"CONTACTADAS"},
		{value:"cotizando", label:"COTIZANDO"},
		{value:"cerradas", label:"CERRADAS"},
		{value:"ejecutandose", label:"EN EJECUCIÓN"},
		{value:"exitosas", label:"EXITOSAS"},
		{value:"fallidas", label:"FALLIDAS"}
	];

	const showBtnNegotiation = (btn,status)=>{
		switch (btn){
			case "next" :
				if (status != "nuevas" && status != "exitosas" && status != "fallidas"){
					return true;
				}else{
					return false;
				}
			case "before" :
				if (status != "nuevas" && status != "asignadas" && status != "fallidas"){
					return true;
				}else{
					return false;
				}
			case "last" :
				if (status != "fallidas"){
					return true;
				}else{
					return false;
				}
			case "restore" :
				if (status === "fallidas"){
					return true;
				}else{
					return false;
				}
			default: return true;
		}
		 

		
	}

	const columns = [
		{ 
			field: 'actions', 
			headerName: 'Acciones', 
			type: 'actions',
			width: 200,
			description: 'Acciones a realizar por cada fila',
			getActions: (params) => {
				if(!params.row.updating){
					return [
						<GridActionsCellItem
							icon={<Edit color="primary" />}
							label="Edit"
							onClick={() => goTo(`/admin/negotiations/${params.id}`)}
						/>,
						<GridActionsCellItem
							icon={<Delete color="secondary" />}
							label="Delete"
							sx={{display: rol==="administrator"  ? "" : "none"}}
							disabled = {rol==="administrator"  ? false : true}
							onClick={() => setItemToDelete(params)}
						/>,
						<GridActionsCellItem
							icon={<ManageAccounts color="primary" />}
							label="Asignar"
							sx={{display: rol==="administrator" || rol==="head_seller" ? "" : "none"}}
							disabled = {rol==="administrator" || rol==="head_seller" ? false : true}
							onClick={() => handleOpenDSS(params.id , params.row.status)}
						/>,
						<GridActionsCellItem
							icon={<ArrowCircleLeft color="warning" />}
							label="Retroceder"
							sx={{display: showBtnNegotiation("before",params.row.status) ? "" : "none"}}
							disabled = {showBtnNegotiation("before",params.row.status) ? false : true}
							onClick={() => changeStatus("before",params.row.status,params.id)}
						/>,
						<GridActionsCellItem
							icon={<ArrowCircleRight color="success" />}
							label="Avanzar"
							sx={{display: showBtnNegotiation("next",params.row.status) ? "" : "none"}}
							disabled = {showBtnNegotiation("next",params.row.status) ? false : true}
							onClick={() => changeStatus("next",params.row.status,params.id)}
						/>,
						<GridActionsCellItem
							icon={<Cancel color="danger" />}
							label="Fallida"
							sx={{display: showBtnNegotiation("last",params.row.status) ? "" : "none"}}
							disabled = {showBtnNegotiation("last",params.row.status) ? false : true}
							onClick={() => changeStatus("last",params.row.status,params.id)}
						/>,
						<GridActionsCellItem
							icon={<Restore color="success" />}
							label="Recuperar"
							sx={{display: showBtnNegotiation("restore",params.row.status) ? "" : "none"}}
							disabled = {showBtnNegotiation("restore",params.row.status) ? false : true}
							onClick={() => changeStatus(params.row.seller !== "NO ASIGNADO" ? "asignadas" : "nuevas",params.row.status,params.id)}
						/>,]
				}else{
					return [
						<img
							src={loading}
							width="30px"
							style={{ margin: 'auto', display: 'block' }}
							height="20px"
							alt="Cargando ..."
				  		/>]
				}
			},
		},
		{ field: 'createdAt', type:'date', headerName: 'Fecha ingreso',description: 'Indica la fecha en que se generó la solicitud', width: 120 },
		{ field: 'date_start', type:'date', headerName: 'Fecha evento',description: 'Indica la fecha en que se realizará el evento', width: 120 },
		{ field: 'name', headerName: 'Nombre', width: 150 },
		{ field: 'customer', headerName: 'Cliente', width: 150},
		{ field: 'event_types', headerName: 'Tipo evento', width: 150},
		{ field: 'event_formats', headerName: 'Formato evento', width: 120},
		{ field: 'seller', headerName: 'Vendedor', width: 150},
	];

	const changeStatus = (action,status,id) => {
		let rowsDataEditing = [];
		Object.assign(rowsDataEditing, rowsData);
		let indexRow = rowsDataEditing.findIndex(element => element.id === id);
		rowsDataEditing[indexRow].updating = true;
		const posStatus = statusNegotiation.findIndex((element) => element.value === status);
		if(action==="next"){
			const nextPos = posStatus+1;
			if(nextPos<statusNegotiation.length-1){
				setRowsData(rowsDataEditing);
				updateNegotiation(id, {status:statusNegotiation[nextPos].value}).then(() => {
				});
			}
		}else if(action==="before"){
			const beforePos = posStatus-1;
			if(beforePos>=0){
				setRowsData(rowsDataEditing);
				updateNegotiation(id, {status:statusNegotiation[beforePos].value}).then(() => {
				});
			}
		}else if(action==="last"){
			const lastPos = statusNegotiation.length-1;
			if(lastPos!==posStatus){
				setRowsData(rowsDataEditing);
				updateNegotiation(id, {status:statusNegotiation[lastPos].value}).then(() => {
				});
			}
		}else if(action==="nuevas"){
				setRowsData(rowsDataEditing);
				updateNegotiation(id, {status:"nuevas"}).then(() => {
				});
		}else if(action==="asignadas"){
				setRowsData(rowsDataEditing);
				updateNegotiation(id, {status:"asignadas"}).then(() => {
				});
		}
	}

	return (
		<>
		<DialogSetSeller 
			onClose={handleCloseSS}
			open={openDSS}
			users={userListF}
			negotiation={negotiationDSS}
			updateNegotiation={updateNegotiation}
		/>
		<Container className="negotiation-list" maxWidth="xl" sx={{ mb: 4 }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" component="h1">
						Lista de negociaciones
					</Typography>
					<Divider sx={{ mt: 2 }} />
				</Grid>
				<Box sx={{ maxWidth: { xs: 350, sm: "100%" }, bgcolor: 'background.paper' }}>
					<Tabs
						value={valueTab}
						onChange={handleChangeTab}
						variant="scrollable"
						scrollButtons
						allowScrollButtonsMobile
						aria-label="EMBUDO NEGOCIACIONES"
					>
						{statusNegotiation.map((item) => {
							if(rol==="administrator" || rol==="head_seller"){
								return (<Tab value={item.value} label={item.label+" ("+rowsData.filter(n => n.status===item.value).length+")"} />)
							}else{
								if(item.value!=="nuevas"){
									return (<Tab value={item.value} label={item.label+" ("+rowsData.filter(n => n.status===item.value).length+")"} />)
								}
							}
						})}
					</Tabs>
				</Box>
				<Grid item xs={12} sx={{height:"520px"}}>
				<DataGrid
					components={{
						NoRowsOverlay: CustomNoRowsOverlay,
				  	}}
					disableSelectionOnClick 
					rows={rowsData?.filter(r => r.status === valueTab)}
					columns={columns}
					pageSize={7}
					rowsPerPageOptions={[7]}
				/>
				</Grid>
			</Grid>
			<SpeedDial
				ariaLabel="Agregar negociación"
				sx={{ position: 'fixed', bottom: 16, right: 16 }}
				icon={<AddOutlined />}
				onClick={() => goTo('/admin/negotiations/new')}
			></SpeedDial>

			<AlertDialog
				title={`Eliminar registro`}
				msg={
					alertMessage
						? `${itemToDelete?.row?.name}: ${alertMessage}`
						: `Debes confirmar que deseas eliminar: "${itemToDelete?.row?.name}" de la lista.`
				}
				fnToDo={{
					action: () => {
						const id = itemToDelete?.row?.id;
						deleteNegotiation(id);
						setItemToDelete(null);
					},
					closeWindow: () => {
						/* Se reinicia todo */
						setItemToDelete(null);
					},
				}}
				active={itemToDelete?.row?.id ? true : false}
			/>
		</Container>
		</>
	);
};

export default NegotiationList;
