import api from '../../plugins/api';
import { mat, asyncMac } from '../utils';

/* Normas del instituto */
const asyncConfigurations = mat('configurations');
const [setPending, setFulfilled, setError] = asyncMac(asyncConfigurations);

/* Datos a los que tiene acceso el usuario */
const availableInstances = role => {
	let instance = '';

	/* Los que pueden ver los usuarios 
	if (['administrator'].includes(role))*/
		instance =
			instance +
			`
			eventFormats(sort:"id:asc"){
			  id
			  name
			  description
				default
			  status
			}
			 
			eventTypes(sort:"order:asc"){
			  id
			  name
			  description
			  default
			  status
			  order
			}
			 
			providerCategories(sort:"name:asc"){
			  id
			  name
			  description
			  status
				provider_types {
					id
					name
					status
				}
			}
			 
			providerTypes(sort:"name:asc"){
			  id
			  name
			  description
			  status
				in_person
			}
			 
			serviceTypes(sort:"order:asc"){
			  id
			  name
			  description
			  status
			  default
			  price_type
			  order
			}
			 
			overallRatingTypes(sort:"name:asc"){
			  id
			  name
			  status
			}

		  	roles{
			  id
			  name
			  type
			}

			countries{
				name
				data
				tax
			}

			globals{
				id
				name
				vars
			}
		`;

	return instance;
};

const fetchGetConfigurations = role => async (dispatch, getState) => {
	const state = getState();
	dispatch(setPending());
	const optionalQuery = availableInstances(role);
	const query = `
	query{

		${optionalQuery}
			
	  }
	`;

	/* Roles que se usaran en el sistema */
	const availableRoles = ['administrator', 'customer', 'provider', 'seller','head_seller'];

	return api()
		.post(`/graphql`, { query })
		.then(({ data }) => {
			const configurations = {
				...state.configurations.entity,
				...data.data,
			};

			/* Solo el administrador requiere los roles */
				configurations.roles = configurations.roles.filter(item =>
					availableRoles.includes(item.type)
				);

			dispatch(setFulfilled(configurations));
		})
		.catch(error => dispatch(setError(error)));
};

const configurations = {
	fetchGetConfigurations,
};

export default configurations;
