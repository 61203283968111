import {  useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sortData } from '../../plugins/utils';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { useProvider } from '../../hooks';
import { useDispatch } from 'react-redux';
import api from '../../plugins/api';
import {Check, Close} from '@mui/icons-material';
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
    TextField,
    Grid,
    Box,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Popper,
    Card,
	CardContent,
    Modal,
    Typography,
    Stack,
    Alert
} from '@mui/material';
import { useDebugValue } from 'react';

const PQuotationForm = ({queryFind,setQueryFind,virtualEvent,setVirtualEvent,providerPage, providerList,
                         serviceSelected, setServiceSelected, setProviderResults,setProviderResultsCount,setShowLoader,
                         negotiationData, setNegotiationData, setNegotiationID, negotiationID, client, eventFormat, setEventFormat}) => {
    const [showhide, setShowhide] = useState(true);
    const [startDate, setStartDate] = useState(queryFind ? queryFind.date_start : null);
    const [modalServiceOpen, setModalServiceOpen] = useState(false);
	const handleMSOpen = () => setModalServiceOpen(true);
	const handleMSClose = () => setModalServiceOpen(false);

    useEffect(()=>{
            console.log(startDate);
        },[startDate]
    )

    const [dependencies, setDependencies] = useState({ 
        attendees: '',
        event_types: '',
        region: providerPage?.region ? providerPage?.region : (queryFind?.region  ? queryFind?.region : ""),
        commune: providerPage?.commune ? providerPage?.commune : (queryFind?.commune ? queryFind?.commune : ""),
        date_start: '',
        date_end: ''
    });

    const dependenceFilter = (dependencies, dependenceValue) => {
        /* Si el campo tiene valor de dependencia, realiza la comparación */
        if (dependenceValue) {
            const key = dependenceValue.name;
            const value = dependenceValue.value;
            return dependencies[key] === value;
        }
        return true;
    };

    useEffect(()=>{
        const regionDep = communes_r?.find(com => com.value === dependencies.commune)?.dependence?.value;
        if(regionDep!==dependencies.region){
            setValue("commune","");
        }
    },[dependencies])

    const boxModalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
  		maxWidth: '400px',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		pt:8
    };

    const [valuePopServices, setValuePopServices] = useState(null);
    const [lbInputPopServices, setLbInputPopServices] = useState("Cantidad");
    const [lbTitlePopServices, setLbTitlePopServices] = useState("Servicio");
    const [lbDescPopServices, setLbDescPopServices] = useState("Ingrese el total requerido de este servicio para todo el evento");

    const serviceToggle = (e, value) => {
        var currentValue = null;
        setValuePopServices(value);
        if(value==="virtualDefault"){
            currentValue = virtualServiceType;
        }else{
            currentValue = serviceTypes.find(object => object.value === value);
        }
		const newChecked = [...serviceSelected];
        const currentIndex = serviceSelected.findIndex(object => object.value === value);
		if (currentIndex===-1) {
            handleMSOpen();
            currentValue.quantity=0;
			newChecked.push(currentValue);
            setLbTitlePopServices(currentValue.name);
            if(currentValue.price_type==="day"){
                setLbInputPopServices("Días");
            }else{
                setLbInputPopServices("Cantidad");
            }
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setServiceSelected(newChecked);
	};

    const {register:registerPQuantity, handleSubmit:handleSPQuantity, reset:resetPQuantity} = useForm();
    const submiteQuantity = (dataform) => {
        const currentIndex = serviceSelected.findIndex(object => {
            return object.value === valuePopServices;
        });
		const newChecked = [...serviceSelected];
		newChecked[currentIndex].quantity=dataform.quantity;
		setServiceSelected(newChecked);
        handleMSClose();
        resetPQuantity();
    }

    const cancelSubmiteQuantity = () => {
        const currentIndex = serviceSelected.findIndex(object => {
            return object.value === valuePopServices;
        });
		const newChecked = [...serviceSelected];
		newChecked.splice(currentIndex, 1);
		setServiceSelected(newChecked);
        handleMSClose();
        resetPQuantity();
    }
   
    const [regions,setRegions] = useState([]);
    const [communes,setCommunes] = useState([]);

    /* Lista de REGIONES Y COMUNAS */
    const regions_r = useSelector(({ configurations }) => {
        if (configurations.entity?.countries?.[0]) {
            let list = configurations?.entity?.countries[0]?.data?.map(
                regions => ({
                    name: regions.name,
                    value: regions.id,
                })
            );
            list = JSON.parse(JSON.stringify(list));
            return list;
        }

        return [];
    });

    const communes_r = useSelector(({ configurations }) => {
        if (configurations.entity?.countries?.[0]) {
            let list = configurations.entity.countries?.[0].data.reduce(
                (communesOptions, region) => {
                    const options = region.communes.map(commune => ({
                        name: commune.name,
                        value: commune.id,
                        dependence: {
                            name: 'region',
                            value: region.id,
                        }, // Este campo tiene un padre y los valores dependen de ese padre
                    }));

                    return [...communesOptions, ...options];
                },
                []
            );
            list = JSON.parse(JSON.stringify(list));
            /* Ordena por name */
            sortData(list, 'name', 'asc');
            return list;
        }
        return [];
    });

    useEffect(()=>{
        setShowLoader(true)
        if(providerList.length>0 && regions_r.length>0 && communes_r.length>0){
            let regions_p = [];
            let communes_p = [];
            providerList.filter(p => p.status==="active" && p.provider_type?.id === "625645b11a26e90029eb5458").forEach((pv) => {
                if(regions_p.indexOf(pv.region) === -1){
                    regions_p.push(pv.region);
                }
                if(communes_p.indexOf(pv.commune) === -1){
                    communes_p.push(pv.commune);
                }
            })
            console.log(regions_p);
            console.log(communes_p);

            /* Lista de REGIONES Y COMUNAS */
            setRegions(regions_r.filter(reg => regions_p.indexOf(reg.value)!== -1));

            setCommunes(communes_r.filter(com => communes_p.indexOf(com.value)!== -1));

            setShowLoader(false);
            /* Fin lista */
        }
    },[providerList])
    

	const eswaper = [
		{
			id: 'eswaper',
			name: 'eswaper',
			workspace: '',
			services: '',
			total: '',
			inactive: false,
			onClick: () => window.open('https://www.e-swaper.com', '_blank'),
		},
	];

	const eventTypes = useSelector(({ configurations }) =>
		configurations?.entity?.eventTypes
			?.map(type => ({
				name: `${type.name}`,
				value: type.id,
				default: type.default,
			}))
			.filter(type => type.default === 'active')
	);

	const eventFormats = useSelector(({ configurations }) =>
		configurations?.entity?.eventFormats
        ?.map(type => ({
			name: type.name,
			value: type.id,
            default: type.default
		}))
        .filter(type => type.default === 'active')
	);

    const virtualServiceType = {
        name: "Streaming del evento",
        value: "virtualDefault",
        default: "active",
        price_type: "day"
    }

    const serviceTypes = useSelector(({ configurations }) =>
		configurations?.entity.serviceTypes
			?.map(type => ({
				name: type.name,
				value: type.id,
				default: type.default,
                quantity: 0,
                price_type: type.price_type,
			}))
			.filter(type => type.default === 'active')
	);


    const eventFormatsChange = (value) => {
		const valueSelected = value;
		setVirtualEvent(valueSelected === 'Virtual');
		setEventFormat(valueSelected);
	};

    const {register, setValue, handleSubmit, reset} = useForm({defaultValues:queryFind});
    const submite = (dataForm) => {
        setShowLoader(true);
        dataForm.name = "Evento "+moment.parseZone(dataForm.date_start).format('DD-MM-YYYY');
        dataForm.services_selected = serviceSelected;
        dataForm.type = "query";
        dataForm.customer = client.user.id;
        setNegotiationData(dataForm);
        if(negotiationID){
            api()
			.put(`/negotiations/${negotiationID}`, dataForm)
			.then(async ({ data }) => {
                console.log("Consulta actualizada con ID "+negotiationID);
            });
        }else{
            api()
            .post(`/negotiations`, dataForm)
            .then(({ data })  =>  {
                setNegotiationID(data.id);
                console.log("Consulta agregada con ID "+data.id);
            });
        }
        
        const query = `?attendees=${dataForm.attendees}&event_types=${dataForm.event_types}&region=${dataForm.region}&commune=${dataForm.commune}`;
        api()
		.get(`/providers/available${query}`)
		.then(( {data} ) => {
            setShowLoader(false);
            setProviderResults(data);
            setQueryFind(dataForm);
		});
    };

    /*if(queryFind){
        setDependencies({
            ...dependencies,
            ["region"]:
            queryFind.region,
        });
    }*/

    useEffect(()=>{
        if(providerPage){
            let queryFindCopy = {};
            Object.assign(queryFindCopy,queryFind);
            queryFindCopy.region = providerPage.region;
            queryFindCopy.commune = providerPage.commune;
            reset(queryFindCopy)
        }
    },[providerPage])

    return (
        <>
            <Modal
                disableAutoFocus
                disableEnforceFocus
                disableEscapeKeyDown
                open={modalServiceOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={boxModalStyle}>
                    <Typography color="primary" variant='h5'>
                        {lbTitlePopServices}
                    </Typography>
                    <Typography variant='body1' sx={{mb:2}}>
                        {lbDescPopServices}
                    </Typography>
                    <Card><CardContent>
                        <form
                            onSubmit={handleSPQuantity(submiteQuantity)}
                            sx={{width:"100%"}}
                        >
                            <FormControl fullWidth variant="standard">
                                <TextField
                                    type="number"
                                    label={lbInputPopServices}
                                    id="popQuantity"
                                    name="popQuantity"
                                    variant="standard"
                                    required
                                    {...registerPQuantity("quantity")}
                                    {...{
                                        inputProps: { min: 1 }
                                    }}
                                ></TextField>
                            </FormControl>
                            <Stack direction="row" spacing={3} sx={{ mt: 3 }}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Close />}
                                    onClick={cancelSubmiteQuantity}
                                >
                                </Button>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                </Button>
                            </Stack>
                        </form>
                    </CardContent></Card>
                </Box>
            </Modal>
            <Card sx={{boxShadow: 3, p:"10px !important"}}>
                <CardContent>
                <form
                    className="form-quotation"
                    onSubmit={handleSubmit(submite)}
                    style={{ width: '100%' }}
                >
                <Grid container spacing={3}>
                    { providerPage &&
                        <Grid item xs={12}>
                            <Alert severity="info" sx={{mb:1}}>
                                Hemos fijado la región y comuna de "{providerPage?.name}", para entregarte su cotización y la de proveedores cercanos
                            </Alert>
                        </Grid>
                    }
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="select-event-format">
                                Formato del evento
                            </InputLabel>
                            <Select
                                labelId="select-event-format"
                                id="event-format-select"
                                name="event_formats"
                                label="Formato del evento"
                                width="150px"
                                required
                                defaultValue={queryFind ? queryFind.event_formats : ""}
                                {...register("event_formats")}
                                onChange={e => {console.log(e.target.value); eventFormatsChange(e.target.value)}}
                            >
                                {eventFormats?.map((option, index) => (
                                    <MenuItem
                                        key={`select-event-format-${index}`}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {!virtualEvent &&
                    <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="select-event-type">
                            Tipo de evento
                        </InputLabel>
                        <Select
                            labelId="select-event-type"
                            id="event_types"
                            name="event_types"
                            defaultValue={queryFind ? queryFind.event_types : ""}
                            required
                            {...register("event_types")}
                        >
                            {eventTypes?.map((option, index) => (
                                <MenuItem
                                    key={`event_types-${index}`}
                                    value={option.value}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </Grid>}
                    {!virtualEvent &&
                    <Grid item xs={12} sm={3} >
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="region_lb">
                            Región
                        </InputLabel>
                        <Select
                            labelId="region_lb"
                            id="region"
                            name="region"
                            required
                            defaultValue={providerPage?.region ? providerPage?.region : (queryFind ? queryFind?.region : "")}
                            {...register("region")}
                            onChange={e => {
                                setDependencies({
                                    ...dependencies,
                                    ["region"]:
                                        e.target.value,
                                });
                            }}
                        >
                            {regions?.map((option, index) => (
                                <MenuItem
                                    key={`region-${index}`}
                                    value={option.value}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </Grid>}
                    {!virtualEvent &&
                    <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="comuna_lb">
                            Comuna
                        </InputLabel>
                        <Select
                            labelId="comuna_lb"
                            id="commune"
                            name="commune"
                            required
                            defaultValue={providerPage?.commune ? providerPage?.commune : (queryFind ? queryFind?.commune : "")}
                            {...register("commune")}
                        >
                            {communes
                                .filter(option =>
                                    dependenceFilter(
                                        dependencies,
                                        option.dependence
                                    )
                                )
                                .map((option, index) => (
                                <MenuItem
                                    key={`commune-${index}`}
                                    value={option.value}
                                >
                                    {option.name}
                                </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    </Grid>}
                    {!virtualEvent &&
                    <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="standard">
                        <TextField
                            type="number"
                            label="Asistentes"
                            id="attendees"
                            name="attendees"
                            defaultValue={queryFind ? queryFind.attendees : ""}
                            variant="standard"
                            required
                            {...register("attendees")}
                            {...{
                                inputProps: { min: 5 }
                            }}
                        ></TextField>
                    </FormControl>
                    </Grid>}
                    {!virtualEvent &&
                    <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="standard">
                        <TextField
                            type = "date"
                            label="Desde"
                            id="date_start"
                            name="date_start"
                            variant="standard"
                            defaultValue={queryFind ? queryFind.date_start : ""}
                            required
                            
                            {...register("date_start")}
                            {...{
                                InputLabelProps: { shrink: true }
                            }}
                            {...{
                                inputProps: { min: moment().format('YYYY-MM-DD') }
                            }}
                            onChange={e => {setStartDate(e.target.value);console.log(e.target.value)}}
                        ></TextField>
                            
                    </FormControl>
                    </Grid>}
                    {!virtualEvent &&
                    <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="standard">
                        <TextField
                            type = "date"
                            label="Hasta"
                            id="date_end"
                            name="date_end"
                            variant="standard"
                            defaultValue={queryFind ? queryFind.date_end : ""}
                            required
                            {...{
                                InputLabelProps: { shrink: true }
                            }}
                            {...{
                                inputProps: { min: startDate ? moment.parseZone(startDate).format('YYYY-MM-DD') :  moment().format('YYYY-MM-DD') }
                            }}
                            {...register("date_end")}
                        ></TextField>
                            
                    </FormControl>
                    </Grid>}
                    {!virtualEvent &&
                    <Grid item xs={12}>
                        <FormGroup row>
                            {eventFormat==="6298245aa541f40140d8fb9b" && (
                                <FormControlLabel
                                key={`service-option-virtual`}
                                control={
                                    <Checkbox
                                        value={virtualServiceType.value}
                                        aria-describedby={virtualServiceType.value}
                                        checked={serviceSelected.findIndex(object => {
                                            return object.value === virtualServiceType.value;
                                        }) !== -1 ? true : false}
                                        onClick={e =>
                                            serviceToggle(e,
                                                e.target.value
                                            )
                                        }
                                    />
                                }
                                label={virtualServiceType.name + (serviceSelected.findIndex(object => {return object.value === virtualServiceType.value}) !== -1 ?
                                    " ("+serviceSelected.find((object) => object.value === virtualServiceType.value)?.quantity+")": "")}
                                />
                            )}
                            {serviceTypes?.map((service, index) => (
                                        <FormControlLabel
                                            key={`service-option-${index}`}
                                            control={
                                                <Checkbox
                                                    value={service.value}
                                                    aria-describedby={service.value}
                                                    checked={serviceSelected.findIndex(object => {
                                                        return object.value === service.value;
                                                    }) !== -1 ? true : false}
                                                    onClick={e =>
                                                        serviceToggle(e,
                                                            e.target.value
                                                        )

                                                    }
                                                />
                                            }
                                            label={service.name + (serviceSelected.findIndex(object => {return object.value === service.value}) !== -1 ?
                                                " ("+serviceSelected.find((object) => object.value === service.value)?.quantity+")": "")}
                                        />
                            ))}
                        </FormGroup>
                    </Grid>}
                </Grid>
                {!virtualEvent &&
                <Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3 }}
                        color="secondary"
                    >
                        {"COTIZAR"}
                    </Button>
                </Box>}
                </form>
                </CardContent>
            </Card>
        </>
        );
};

export default PQuotationForm;