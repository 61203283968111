import { useDispatch, useSelector } from 'react-redux';
import {ArrowBack, ArrowForward, Close, Logout, Edit, Person, RequestQuote, Info} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import api from '../../plugins/api';
import loading from '../../assets/loading-pulse.svg';
import { useForm, Controller } from 'react-hook-form';
import { useUser } from "../../hooks";
import * as React from 'react';
import {
	Container,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Typography,
	Button,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Divider,
	Box,
	LinearProgress,
	TextField,
	Stack,
	Card,
	CardContent,
	CardActions,
	CardActionArea,
	IconButton,
	Modal,
	Alert,
	Fab,
	Popper,
	Fade,
	Paper,
	Tooltip
} from '@mui/material';
import { printMoney } from '../../plugins/utils';
import moment from 'moment';
import FormDynamic from '../../components/FormDynamic';
import DataListDynamic from '../../components/DataListDynamic';
import { sortData } from '../../plugins/utils';
import { useEffect, useState, useRef  } from 'react';
import { useProvider } from '../../hooks';

import NegotiationResultSelected from '../../components/NegotiationResultSelected';
import PNavBar from '../../components/public/PNavBar';
import PQuotationForm from '../../components/public/PQuotationForm';
import PQuotationCardsProviders from '../../components/public/PQuotationCardsProviders';
import Loader from '../../components/Loader';
import actions from '../../redux/action';
import configurations from '../../redux/actions/configurations';
import usePNavBar from '../../hooks/usePNavBar';

const PClientRequests = () => {
	/*CONCEDERLE ACCESO A LA API A LOS CLIENTES*/

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [showLoader, setShowLoader] = useState(true);
	const [emailClient, setEmailClient] = useState("");
	const [client, setClient] = useState(null);
	const [negotiationData,setNegotiationData] = useState({});
	const [modalAOpen, setModalAOpen] = useState(false);
	const handleMAOpen = () => setModalAOpen(true);
	const handleMAClose = () => setModalAOpen(false);
	const [modalBOpen, setModalBOpen] = useState(false);
	const handleMBOpen = () => setModalBOpen(true);
	const handleMBClose = () => setModalBOpen(false);
	const [negotiationID,setNegotiationID] = useState(null);
	const [negotiations,setNegotiations] = useState([]);

	const {providerList} = useProvider({});

	const {goTo} = usePNavBar();

	
	const localClient= JSON.parse(localStorage.getItem("client"));

	useEffect(() => {
		if(!localClient){
			window.location.assign("/acceso");
		}else{
			setClient(localClient);
			setShowLoader(true);
			api()
				.get(`/negotiations/customer/${localClient.user.id}`)
				.then( ( {data} ) => {
					setNegotiations(data);
					setShowLoader(false);
			});
		}
		
	}, []);

	/* Lista de REGIONES Y COMUNAS */
	const regions = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations?.entity?.countries[0]?.data?.map(
				regions => ({
					name: regions.name,
					value: regions.id,
				})
			);
			list = JSON.parse(JSON.stringify(list));
			/* Ordena por name */
			sortData(list, 'name', 'asc');
			return list;
		}

		return [];
	});

	const communes = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations.entity.countries?.[0].data.reduce(
				(communesOptions, region) => {
					const options = region.communes.map(commune => ({
						name: commune.name,
						value: commune.id,
						dependence: {
							name: 'region',
							value: region.id,
						}, // Este campo tiene un padre y los valores dependen de ese padre
					}));

					return [...communesOptions, ...options];
				},
				[]
			);
			list = JSON.parse(JSON.stringify(list));
			/* Ordena por name */
			sortData(list, 'name', 'asc');
			return list;
		}
		return [];
	});
	/* Fin lista */

	const boxModalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
  		maxWidth: '700px',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		pt:8
	  };

	const clearUser = () =>{
		localStorage.removeItem("client");
		setClient(null);
		goTo("/acceso");
	}

	//FORM CONTROLLERS
	const {register: registerFClientEdit, handleSubmit: handleSubmitFClientEdit} = useForm();
	const [clientConfirmEdit,setClientConfirmEdit] = useState(false);

	const editClient = () => {
		handleMAOpen();
	};

	const submiteFCE = (dataForm) => {
		setShowLoader(true);
		api()
		.put(`/user-infos/${client.id}`, dataForm)
		.then(async ()  =>  {
			client.name = dataForm.name;
			client.telephone = dataForm.telephone;
			client.lastname = dataForm.lastname;
			client.company = dataForm.company;
			setClient(client);
			localStorage.setItem("client", JSON.stringify(client));
			setClientConfirmEdit(true);
			setShowLoader(false);
		});
    };

	return (
		<Container>
			<Loader showLoader={showLoader}/>
		<PNavBar/>
		<Container className="clientQuotation" maxWidth="xl" component="main" sx={{ mb: 4}}>
		<Modal
				open={modalAOpen}
				onClose={handleMAClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxModalStyle}>
				<IconButton color="primary" sx={{position:"absolute",top:"10px",right:"10px"}} aria-label="Cerrar" onClick={handleMAClose}>
					<Close />
				</IconButton>
				<form
					className="form-client-edit"
					onSubmit={handleSubmitFClientEdit(submiteFCE)}
					style={{ width: '100%' }}
				>
				<Stack
					spacing={{ xs: 1, sm: 2, md: 4 }}
				>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Email"
							id="email"
							name="email"
							variant="standard"
							disabled
							defaultValue={client?.user ? client.user.email : ""}
						></TextField>
					</FormControl>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Nombre"
							id="name"
							name="name"
							variant="standard"
							required
							defaultValue={client ? client.name : ""}
							{...registerFClientEdit("name")}
						></TextField>
					</FormControl>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Apellido"
							id="lastname"
							name="lastname"
							variant="standard"
							required
							defaultValue={client ? client.lastname : ""}
							{...registerFClientEdit("lastname")}
						></TextField>
					</FormControl>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Teléfono"
							id="phone"
							name="phone"
							variant="standard"
							required
							defaultValue={client ? client.telephone : ""}
							{...registerFClientEdit("telephone")}
						></TextField>
					</FormControl>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Compañia o Institución"
							id="company"
							name="company"
							variant="standard"
							
							defaultValue={client ? client.company : ""}
							{...registerFClientEdit("company")}
						></TextField>
					</FormControl>
					{clientConfirmEdit && <Alert sx={{mt:2}} severity="success">Se actualizó correctamente su información</Alert>}
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						sx={{mt:2}}
					>
						{"ACTUALIZAR"}
					</Button>
				</Stack>
				</form>
				</Box>
			</Modal>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box sx={{ width: '100%', mb: "20px" }}>
						{ client &&
						<>
							<Card sx={{mb:2, boxShadow: 3}}>
								<CardContent sx={{pt:"10px !important",pb:"10px !important"}}>
									<Stack direction="row" spacing={1} >
										<Person color="primary" sx={{mt:"7px"}}/>
										<Typography variant="h7" gutterBottom component="div" sx={{pt:"7px"}}>
											{client?.name+" "+client?.lastname} 
										</Typography>
										<>
											<Stack direction="row" spacing={1}>
												<Tooltip title="Editar información">
													<IconButton color="secondary" onClick={()=>  editClient()}>
														<Edit />
													</IconButton>
												</Tooltip>
												<Tooltip title="Salir">
													<IconButton color="secondary" onClick={() => clearUser()}>
														<Logout />
													</IconButton>
												</Tooltip>
											</Stack>
										</>
									</Stack>
								</CardContent>
							</Card>
						</>
						}
						<Typography variant="h4" gutterBottom component="div" color="primary">
							Eventos
						</Typography>
						<LinearProgress sx={{height:"2px"}} variant="determinate" value={100} color="secondary" />
					</Box>
					<Grid container justifyContent={"flex-start"} spacing={2}>
						{ negotiations?.length>0 && 
							negotiations.map((item) => {return(
								<Grid item xs={12} md={6} lg={4}>
									<Card>
										<CardActionArea onClick={()=>goTo("/cuenta/eventos/"+item._id)}>
										<CardContent>
											<Typography variant="h6" color="primary">{item.name}</Typography>
											<Typography variant="body1">Comuna: {communes.find(element => element.value===item.commune)?.name}</Typography>
											<Typography variant="body1">Asistentes: {item.attendees}</Typography>
											<Typography variant="body1">Desde: {moment.parseZone(item.date_start).utc(true).format("DD/MM/YYYY")}</Typography>
											<Typography variant="body1">Hasta: {moment.parseZone(item.date_end).utc(true).format("DD/MM/YYYY")}</Typography>
										</CardContent>
										</CardActionArea>
									</Card>
								</Grid>
							)})
						}
						{negotiations?.length<=0 &&
							<Card sx={{mt:3,boxShadow: 3,backgroundColor:"rgb(0, 41, 132) !important",width:"100%"}}>
								<CardContent sx={{p:"25px !important"}}>
									<Stack direction="row" spacing={1}>
										<RequestQuote color="light" fontSize="large"/>
										<Typography variant="h5" color="#eee" sx={{fontWeight:"700 !important"}}>
											Aún no has solicitado un evento. Cotiza ahora tu evento en un par de minutos!
										</Typography>
									</Stack>
									<Stack sx={{mt:3,mb:3}} spacing={2}
											direction="column"
											alignItems="center"
											justifyContent="center"
										>
											<Button
												variant="contained"
												color="secondary"
												onClick={()=>goTo("/cotizar")}
											>
												{"Cotiza ahora!"}
											</Button>
										</Stack>
									<Stack direction="row" spacing={1}>
										<Info color="light" fontSize="medium" />
										<Typography variant="h6" color="#eee" sx={{fontWeight:"700 !important"}}>
											Cuando registres tus solicitudes de evento, aparecerán en esta sección.
										</Typography>
									</Stack>
								</CardContent>
							</Card>
						}
					</Grid>
				</Grid>
			</Grid>
		</Container>
		</Container>
		
	);

};

export default PClientRequests;