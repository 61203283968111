import { useNavigate, useLocation } from 'react-router-dom';

const usePNavBar = () => {
    const navigate = useNavigate();
	const location = useLocation();

    const goTo = (to) => {
        if(to==="https://salonesyeventos.cl"){
        window.location.assign("https://salonesyeventos.cl");
        }else{
        navigate(to, {
            state: {
            previous_key: location.key,
            previous_page: location.pathname,
            },
        });
        }
	};
    return {
		goTo
	};
};

export default usePNavBar;