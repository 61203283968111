import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

/* Datos para el formulario del usuario */
import inputsForm from './useUser/inputs';

import actions from '../redux/action';
const fetchUsers = actions.fetchUsers;
const deleteU = actions.deleteUser;
const clearUsers = actions.clearUsers;
const fetchTotalUser = actions.fetchTotalUser;
const readUser = actions.readUser;
const pushUser = actions.pushUser;
const putUser = actions.putUser;

const useUser = () => {
	const location = useLocation();
	const params = useParams();
	/* ID pasado por URL del usuario */
	const id = params.id;

	const dispatch = useDispatch();

	/* Roles cargando en el sistema */
	const roles = useSelector(({ configurations }) =>
		configurations.entity.roles
			? configurations.entity.roles.map(x => ({
					name: x.name,
					value: x.type,
					id: x.id,
			  }))
			: []
	);
	const totalRoles = roles.length;

	/* Rol especifico por ID */
	const roleNameById = id =>
		roles.reduce((name, role) => (role.id === id ? role.name : name), '');
	const roleValueById = id =>
		roles.reduce(
			(value, role) => (role.id === id ? role.value : value),
			''
		);

	/* Lista de usuarios */
	const userList = useSelector(
		({ configurations }) =>
			configurations?.entity && configurations.entity.userInfos.list
	);
	const totalUsers = useSelector(
		({ configurations }) =>
			configurations?.entity && configurations.entity.userInfos.total
	);

	/* Estatus de la solicitud */
	const requestStatus = useSelector(
		({ configurations }) => configurations?.status
	);

	/* Cargar lista de usuarios */
	useEffect(() => {
		/* Para que solo se ejecute solo si se cumple la condicion */
		if (totalRoles && totalUsers === 0 && !id) {
			dispatch(fetchUsers()).then(() => {
				dispatch(fetchTotalUser());
			});
		}

		/* Si tiene ID distinto a new */
		if (totalUsers === 0 && id && id !== 'new') dispatch(readUser(id));

		return () => {
			if (totalUsers === 0 && id && id !== 'new') dispatch(clearUsers());
		};
	}, [dispatch, totalUsers, id, location, totalRoles]);

	/* Leer un usuario */
	let userData =
		typeof userList === 'object'
			? userList.reduce(
					(data, item) => (item.id === id ? item : data),
					{}
			  )
			: {};
	let userDataForForm = userList.reduce(
		(data, item) =>
			item.id === id
				? {
						...item,
						role: roleValueById(item.user?.role),
						email: item.user?.email,
				  }
				: data,
		{}
	);
	delete userDataForForm.user;
	/* Limpiar el state */
	const resetList = () => {
		dispatch(clearUsers());
	};

	/* Crear nuevo usuario */
	const createUser = data => dispatch(pushUser(data));

	const updateUser = (id, data) => dispatch(putUser(id, data));

	/* Eliminar usuarios */
	const deleteUser = id => dispatch(deleteU(id));

	/* Cargar más */
	const loadMore = () => {
		const count = userList.length;
		if (count < totalUsers) fetchUsers({ start: count });
	};

	const inputs = inputsForm({ roles });

	return {
		userList,
		totalUsers,
		resetList,
		loadMore,
		createUser,
		updateUser,
		deleteUser,
		requestStatus,
		userData,
		userDataForForm,
		inputs,
		roleNameById,
	};
};

export default useUser;
