import { useForm, Controller } from 'react-hook-form';
import {
	Box,
	TextField,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
} from '@mui/material';
import { useState } from 'react';

/* 
Atributos de los inputs
inputs [{
	type,
	label,
	name,
	placeholder
	required
	autoFocus
	multiple => cualdo un campo acepta multiples valores, su valor por defecto debe ser un array vacio o con 1 o más valores
	options: [{name,value,dependence}] // (Optional) dependence: {name,value} (name del input padre que debe ser llenado para filtra a los hijos)
}]
*/

const errorMessage = (error, input) => {
	if (error[input.name]?.type === 'required')
		return `${input.label} es requerido.`;
	return null;
};

const dependenceFilter = (dependencies, dependenceValue) => {
	/* Si el campo tiene valor de dependencia, realiza la comparación */
	if (dependenceValue) {
		const key = dependenceValue.name;
		const value = dependenceValue.value;
		return dependencies[key] === value;
	}
	return true;
};

const FormDynamic = ({
	validateFunction,
	submitFunction,
	defaultValues,
	inputs,
	buttonText,
	cancelFunction,
	...props
}) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues,
	});

	/* Por el momento con los selects: Guardar en key el nombre del campo con su valor, para que sea usado para filtrar los elementos del input dependiente */
	const [dependencies, setDependencies] = useState({ ...defaultValues });

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				...props.sx,
			}}
		>
			<form
				className="form-dynamic"
				onSubmit={handleSubmit(submitFunction)}
				style={{ width: '100%' }}
			>
				{inputs.map((input, index) => {
					if (input.type === 'select') {
						return (
							<Controller
								key={`input-${index}`}
								control={control}
								rules={{
									required: input.required || false,
								}}
								name={input.name}
								render={({ field }) => (
									<FormControl
										fullWidth
										error={
											errors[input.name] ? true : false
										}
										sx={{ pt: '8px', mt: '16px' }}
									>
										<InputLabel
											id={`id-${input.name}-label`}
										>
											{input.label}
										</InputLabel>
										<Select
											labelId={`id-${input.name}-label`}
											id={`id-${input.name}`}
											label={input.label}
											{...field}
											onChange={e => {
												field.onChange(e);
												setDependencies({
													...dependencies,
													[input.name]:
														e.target.value,
												});
											}}
											multiple={
												input.multiple ? true : false
											}
										>
											{input.options &&
												input.options
													.filter(option =>
														dependenceFilter(
															dependencies,
															option.dependence
														)
													)
													.map((option, index) => (
														<MenuItem
															key={`option-${index}`}
															value={option.value}
														>
															{option.name ||
																option.value}
														</MenuItem>
													))}
										</Select>
										{errors[input.name] && (
											<FormHelperText>
												{errorMessage(errors, input)}
											</FormHelperText>
										)}
									</FormControl>
								)}
							/>
						);
					}

					return (
						<Controller
							key={`input-${index}`}
							control={control}
							rules={{
								required: input.required || false,
							}}
							name={input.name}
							render={({ field }) => (
								<TextField
									type={input.type || 'text'}
									multiline={input.multiline ? true : false}
									fullWidth
									margin="normal"
									label={input.label}
									placeholder={input.placeholder}
									autoFocus={input.autoFocus || false}
									id={`id-${input.name}`}
									error={errors[input.name] ? true : false}
									helperText={errorMessage(errors, input)}
									/* Si es un campo del tipo date, la etiqueta se queda arriba y no como un placeholder */
									{...{
										InputLabelProps: ['date'].includes(
											input.type
										)
											? { shrink: true }
											: {},
									}}
									{...{
										inputProps: ['date'].includes(
											input.type
										)
											? { min: input.min || '' }
											: {},
									}}
									{...field}
								/>
							)}
						/>
					);
				})}
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 3 }}
				>
					{buttonText}
				</Button>
				{cancelFunction && (
					<Button
						type="button"
						fullWidth
						variant="outlined"
						sx={{ mt: '16px', mb: 2 }}
						onClick={cancelFunction}
					>
						Cancelar
					</Button>
				)}
			</form>
		</Box>
	);
};

export default FormDynamic;
