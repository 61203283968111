import { useSelector } from 'react-redux';
import { useState } from 'react';
import lodash from 'lodash';
import { Container, Grid, Typography, Divider } from '@mui/material';
import FormCreateAndUpdate from '../components/FormCreateAndUpdate';
import ConfigDataList from '../components/ConfigDataList';
import { useConfiguration } from '../hooks';
import actions from '../redux/action';

const putGeneralData = actions.putGeneralData;
const pushGeneralData = actions.pushGeneralData;
const deleteGeneralData = actions.deleteGeneralData;

const endpoint = 'provider-categories';
const nameKey = lodash.camelCase(endpoint);

const ConfigProviderCategory = () => {
	const [dataItem, setDataItem] = useState({
		name: '',
		description: '',
		status: '',
		provider_types: '',
	});

	const { inputsProviderCategory: inputs } = useConfiguration();

	const data = useSelector(
		({ configurations }) =>
			configurations?.entity && configurations.entity[nameKey]
	);
	const status = useSelector(({ configurations }) => configurations?.status);

	const formProps = {
		validateFunction: () => {},
		inputs,
	};

	const changeItem = item => {
		setDataItem(null);
		const resetObject = {
			name: '',
			description: '',
			status: '',
			provider_types: '',
		};
		/* Se mapea el item para enviar el ID de provider_types  */
		setTimeout(
			() =>
				setDataItem(
					item
						? { ...item, provider_types: item?.provider_types?.id }
						: resetObject
				),
			100
		);
	};

	return (
		<Container
			className="config-service-type-list"
			maxWidth="xl"
			sx={{ mb: 4 }}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" component="h1">
						Categorías de proveedor
					</Typography>
					<Typography variant="body2" component="p" color="secondary">
						Administrar el listado de opciones y el estatus de los
						mismos para que esten disponibles para su uso.
					</Typography>
					<Divider
						sx={{
							mt: 2,
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<FormCreateAndUpdate
						defaultValues={dataItem}
						changeItem={changeItem}
						endpoint={endpoint}
						pushFunction={pushGeneralData}
						putFunction={putGeneralData}
						formProps={formProps}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={8}>
					{data?.length > 0 && (
						<ConfigDataList
							status={status}
							list={{ data }}
							endpoint={endpoint}
							getDataItem={changeItem}
							deleteFunction={deleteGeneralData}
							title="Listado"
							subtitle="Categorías de proveedor cargados al sistema. Los item rojos representan los inactivos."
						/>
					)}
				</Grid>
			</Grid>
		</Container>
	);
};

export default ConfigProviderCategory;
