import { useSelector } from 'react-redux';
import { Container, Grid, Typography, Divider, SpeedDial } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';

import DataListDynamic from '../components/DataListDynamic';

import { useUser, useMenu } from '../hooks';

const rowTitle = {
	name: (
		<Typography variant="subtitle2" component="div">
			Nombre
		</Typography>
	),
	email: (
		<Typography variant="subtitle2" component="div">
			Email
		</Typography>
	),
	telephone: (
		<Typography variant="subtitle2" component="div">
			Teléfono
		</Typography>
	),
	company: (
		<Typography variant="subtitle2" component="div">
			Empresa
		</Typography>
	),
	role: (
		<Typography variant="subtitle2" component="div">
			Rol
		</Typography>
	),
};

const widthCells = {
	name: { xs: 12, sm: 6, md: 2, lg: 3 },
	email: { xs: 12, sm: 6, md: 2, lg: 2 },
	telephone: { xs: 12, sm: 6, md: 2, lg: 2 },
	company: { xs: 12, sm: 6, md: 2, lg: 2 },
	role: { xs: 12, sm: 6, md: 2, lg: 2 },
	delete: { xs: 12, sm: 6, md: 2, lg: 1 },
};

const UsersList = () => {
	const {
		userList,
		roleNameById,
		totalUsers,
		deleteUser,
		requestStatus,
		loadMore,
	} = useUser();
	const { goTo } = useMenu();
	const email = useSelector(({ user }) => user?.entity?.email);

	const rowsData = userList.map(item => ({
		id: item.user?.email === email ? null : item.id,
		name: `${item.name} ${item.lastname}`,
		email: item.user?.email,
		telephone: item.telephone,
		company: item.company,
		role: roleNameById(item.user?.role),
		onClick: () => goTo(`/admin/users/${item.id}`),
	}));

	return (
		<Container
			className="config-service-type-list"
			maxWidth="xl"
			sx={{ mb: 4 }}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" component="h1">
						Usuarios del sistema
					</Typography>
					<Typography variant="body2" component="p" color="secondary">
						En este listado tendrá información sobre los usuarios
						del sistema, registro de sus sesiones y acceso para
						administrarlos.
					</Typography>
					<Divider
						sx={{
							mt: 2,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					{rowsData?.length > 0 && (
						<DataListDynamic
							status={requestStatus}
							{...{ rowTitle, rowsData, widthCells }}
							deleteFunction={deleteUser}
							title="Lista de usuarios"
							subtitle="Usuarios registrados en el sistema."
						/>
					)}
				</Grid>
				{rowsData.length < totalUsers && (
					<p onClick={() => loadMore()}>Cargar más</p>
				)}
			</Grid>
			<SpeedDial
				ariaLabel="Agregar usuario"
				sx={{ position: 'fixed', bottom: 16, right: 16 }}
				icon={<AddOutlined />}
				onClick={() => goTo('/admin/users/new')}
			></SpeedDial>
		</Container>
	);
};

export default UsersList;
