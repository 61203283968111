import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';

const ConfigProvideType = () => {
	return (
		<Container
			className="config-provider-type-list"
			maxWidth="xl"
			sx={{ mb: 4 }}
		>
			<Outlet />
		</Container>
	);
};

export default ConfigProvideType;
