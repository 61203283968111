const inputs = [
	{
		type: 'text',
		label: 'Buscar',
		name: 'keyword',
		placeholder: 'Ej: Buscar por nombre, descripcion',
	},
	{
		type: 'select',
		label: 'Estatus',
		name: 'status',
		required: true,
		options: [
			{ name: 'Activo', value: 'active' },
			{ name: 'Inactivo', value: 'inactive' },
		],
	},
];

export default inputs;
