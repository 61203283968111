import useList from './useList';
import { useSelector, useDispatch } from 'react-redux';

import { sortData } from '../plugins/utils';

/* Definicion de los campos para el formulario del proveedor */
import inputsFn from './useProvider/inputs';

/* Definicion de los campos para el filtro de busqueda */
import inputsFindFn from './useProvider/inputsFind';

import actions from '../redux/action';
const fetchProviders = actions.fetchProviders;
const removeProvider = actions.deleteProvider;
const clearProviders = actions.clearProviders;
const fetchTotalProvider = actions.fetchTotalProvider;
const getProvider = actions.readProvider;
const pushProvider = actions.pushProvider;
const putProvider = actions.putProvider;
const availableProvider = actions.availableProvider;
const availableProviderTotal = actions.availableProviderTotal;
const fetchProvidersAll = actions.fetchProvidersAll;

const useProvider = ({ ID }) => {
	/* Armar el query que se ejecutara segun el filtro aplicado */
	const queryFilter = filter => {
		let queryString = '';

		/* Setea el query para ser usado para cargar más */
		if (filter?.keyword) {
			const text = filter.keyword;
			queryString += `&_where[_or][0][name_contains]=${text}&_where[_or][1][address_contains]=${text}&_where[_or][2][description_contains]=${text}&_where[_or][3][telephone_contains]=${text}`;
		}
		if (filter?.commune) {
			const commune = filter.commune;
			queryString += `&_where[_or][4][commune_contains]=${commune}`;
		}
		/*
		if (filter?.provider_categories) {
			const provider_categories = filter.provider_categories;
			queryString += `&provider_categories=${provider_categories}`;
		}

		if (filter?.provider_type) {
			const provider_type = filter.provider_type;
			queryString += `&provider_type=${provider_type}`;
		}*/

		if (filter?.status) {
			const status = filter.status;
			queryString += `&status=${status}`;
		}

		return queryString;
	};

	const {
		entityList: providerList,
		customEntityList: customProviderList,
		totalEntities: totalProviders,
		resetList,
		loadMore,
		readEntity: readProvider,
		createEntity: createProvider,
		updateEntity: updateProvider,
		deleteEntity: deleteProvider,
		requestStatus,
		entityData: providerData,
	} = useList({
		statusFn: ({ providers }) => providers?.status,
		entitiesFn: ({ providers }) => providers?.entities,
		totalFn: ({ providers }) => providers.total,
		queryFilter,
		clear: clearProviders,
		fetchTotal: fetchTotalProvider,
		fetch: fetchProviders,
		read: getProvider,
		push: pushProvider,
		put: putProvider,
		remove: removeProvider,
		ID,
	});

	/* Lista de TIPOS general filtra las categorias */
	const providerTypes = useSelector(({ configurations }) =>
		configurations?.entity?.providerTypes?.map(type => ({
			name: type.name,
			value: type.id,
			status: type.status,
		}))
	);

	/* Lista de CATEGORÍAS general */
	const providerCategories = useSelector(({ configurations }) =>
		configurations?.entity?.providerCategories?.map(category => ({
			name: category.name,
			value: category.id,
			status: category.status,
			dependence: {
				name: 'provider_type',
				value: category.provider_types?.id,
			}, // Este campo tiene un padre y los valores dependen de ese padre
		}))
	);

	/* Lista de tipos de eventos */
	const eventTypes = useSelector(({ configurations }) =>
		configurations?.entity?.eventTypes?.map(type => ({
			name: type.name,
			value: type.id,
			status: type.status,
		}))
	);

	const categoriesForType = providerCategories?.reduce((types, category) => {
		if (category.dependence) {
			const categories = types[category.dependence?.value] || [];
			categories.push(category.value);
			return { ...types, [category.dependence.value]: categories };
		}
		return types;
	}, {});

	/* Lista de REGIONES Y COMUNAS */
	const regions = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations?.entity?.countries[0]?.data?.map(
				regions => ({
					name: regions.name,
					value: regions.id,
				})
			);
			list = JSON.parse(JSON.stringify(list));
			/* Ordena por name */
			sortData(list, 'name', 'asc');
			return list;
		}

		return [];
	});

	const communes = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations.entity.countries?.[0].data.reduce(
				(communesOptions, region) => {
					const options = region.communes.map(commune => ({
						name: commune.name,
						value: commune.id,
						dependence: {
							name: 'region',
							value: region.id,
						}, // Este campo tiene un padre y los valores dependen de ese padre
					}));

					return [...communesOptions, ...options];
				},
				[]
			);
			list = JSON.parse(JSON.stringify(list));
			/* Ordena por name */
			sortData(list, 'name', 'asc');
			return list;
		}
		return [];
	});

	/* Campos para el filtro */
	const filterInputs = inputsFindFn({ providerTypes, providerCategories });

	/* Campos del formulario para crear o editar, con las opciones activas de categorias y tipos */
	const formInputs = inputsFn({
		eventTypes: eventTypes?.filter(type => type.status === 'active'),
		providerCategories: providerCategories?.filter(
			category => category.status === 'active'
		),
		providerTypes: providerTypes?.filter(type => type.status === 'active'),
		regions,
		communes,
	});

	const dispatch = useDispatch();

	return {
		providerList,
		customProviderList,
		totalProviders,
		resetList,
		loadMore,
		readProvider,
		createProvider,
		updateProvider,
		deleteProvider,
		requestStatus,
		providerData,
		formInputs,
		filterInputs,
		categoriesForType,
		availableProvider: a => dispatch(availableProvider(a)),
		availableProviderTotal: a => dispatch(availableProviderTotal(a)),
		fetchProvidersAll
	};
};

export default useProvider;
