import api from '../../plugins/api';
import { mat, asyncMac } from '../utils';

const asyncUser = mat('workspaces');
const [setPending, setFulfilled, setError] = asyncMac(asyncUser);

/* Listar a los salones */
const fetchWorkspaces = params => (dispatch, getState) => {
	dispatch(setPending());
	const state = getState();
	const limit = 0;/*params?.limit || process.env.REACT_APP_PER_PAGE;*/

	let query = `?_limit=${limit}&_start=${params?.start || 0}`;

	if (params?.query?.length) query += params.query;

	return api()
		.get(`/workspaces${query}`)
		.then(({ data }) => {
			const entities = state.workspaces.entities;
			dispatch(setFulfilled([...entities, ...data]));
		})
		.catch(error => dispatch(setError(error)));
};

/* Total salones */
const fetchTotalWorkspace = params => dispatch => {
	/* Filtros para contar */
	let query = `?`;
	if (params?.query?.length) query += params.query;

	return api()
		.get(`/workspaces/count${query}`)
		.then(({ data }) => {
			dispatch({ type: 'workspace-total/set', payload: data });
		})
		.catch(error => dispatch(setError(error)));
};

/* Agregando un nuevo salon */
const pushWorkspace = form => async dispatch => {
	dispatch(setPending());
	return api()
		.post(`/workspaces`, form)
		.then(({ data }) => {
			dispatch({ type: 'workspaces/add', payload: data });
			dispatch({ type: 'workspace-total/add' });
		})
		.catch(error => dispatch(setError(error)));
};

/* Actualizando un salon */
const putWorkspace = (id, form) => async dispatch => {
	dispatch(setPending());
	return api()
		.put(`/workspaces/${id}`, form)
		.then(({ data }) => {
			dispatch({ type: 'workspaces/update', payload: data });
		})
		.catch(error => dispatch(setError(error)));
};

/* Limpiar la lista de salones */
const clearWorkspaces = () => dispatch => {
	dispatch({ type: 'workspaces/clear' });
	dispatch({ type: 'workspace-total/clear' });
};

/* INDIVIDUAL */
/* Esta instancia se ejecuta cuando el usuario ingresa directo a la URL y no existe lista */
const readWorkspace = id => dispatch => {
	dispatch(setPending());

	return api()
		.get(`/workspaces/${id}`)
		.then(({ data }) => {
			dispatch(setFulfilled([data]));
		})
		.catch(error => dispatch(setError(error)));
};

/* Elimando un salon */
const deleteWorkspace = id => async dispatch => {
	dispatch(setPending());
	return api()
		.delete(`/workspaces/${id}`)
		.then(() => {
			dispatch({ type: 'workspaces/remove', payload: id });
			dispatch({ type: 'workspace-total/remove' });
		})
		.catch(error => dispatch(setError(error)));
};

const workspaces = {
	fetchWorkspaces,
	readWorkspace,
	fetchTotalWorkspace,
	pushWorkspace,
	putWorkspace,
	deleteWorkspace,
	clearWorkspaces,
};

export default workspaces;
