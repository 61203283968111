const inputs = ({ roles }) => {
	return [
		{
			type: 'text',
			label: 'Nombre',
			name: 'name',
			placeholder: 'Ej: Eduardo',
			required: true,
			autoFocus: true,
		},
		{
			type: 'text',
			label: 'Apellido',
			name: 'lastname',
			placeholder: 'Ej: Zambrano',
		},
		{
			type: 'text',
			label: 'Teléfono',
			name: 'telephone',
			placeholder: 'Ej: 999888666',
		},
		{
			type: 'text',
			label: 'Compañía',
			name: 'company',
			placeholder: 'Ej: Apple',
		},
		{
			type: 'select',
			label: 'Rol',
			name: 'role',
			required: true,
			options: roles,
		},
		{
			type: 'text',
			label: 'Email',
			name: 'email',
			placeholder: 'Ej: eduardo@apple.com',
			required: true,
		},
		{
			type: 'password',
			label: 'Contraseña',
			name: 'password',
			placeholder: 'Ej: +(Nuca-Se-me-Olvida)',
			required: true,
		},
	];
};

export default inputs;
