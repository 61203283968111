import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import {
	Logout,
	GroupsOutlined,
	DashboardOutlined,
	StorefrontOutlined,
	LiquorOutlined,
	RoomServiceOutlined,
	SettingsOutlined,
	WorkspacesOutlined,
	MoveToInboxOutlined,
} from '@mui/icons-material';

import { useSelector } from 'react-redux';

const headerMenuAll = [
	{
		path: '/admin',
		name: 'Dashboard',
		icon: <DashboardOutlined />,
		rol: ['administrator'],
	},
	{
		path: '/admin/negotiations',
		name: 'Negociaciones',
		icon: <MoveToInboxOutlined />,
		rol: ['administrator','seller','head_seller'],
	},
	{
		path: '/admin/requestRedirected',
		name: 'Negociaciones redirigidas',
		icon: <MoveToInboxOutlined />,
		rol: ['administrator'],
	},
	{
		path: '/admin/queries',
		name: 'Consultas',
		icon: <MoveToInboxOutlined />,
		rol: ['administrator'],
	},
	{
		path: '/admin/providers',
		name: 'Proveedores',
		icon: <StorefrontOutlined />,
		rol: ['administrator','seller','head_seller'],
	},
	{
		path: '/admin/services',
		name: 'Servicios',
		icon: <RoomServiceOutlined />,
		rol: ['administrator'],
	},
	{
		path: '/admin/workspaces',
		name: 'Salones',
		icon: <WorkspacesOutlined />,
		rol: ['administrator'],
	},
	{
		path: '/admin/users',
		name: 'Usuarios',
		icon: <GroupsOutlined />,
		rol: ['administrator'],
	},
	{
		path: '/admin/configuration',
		name: 'Configuración',
		icon: <SettingsOutlined />,
		children: [
			{
				path: '/admin/configuration/provider-category',
				name: 'Categorías de proveedor',
				icon: <StorefrontOutlined />,
				rol: ['administrator'],
			},
			{
				path: '/admin/configuration/provider-type',
				name: 'Tipos de proveedor',
				icon: <StorefrontOutlined />,
				rol: ['administrator'],
			},
			{
				path: '/admin/configuration/event-format',
				name: 'Formato de evento',
				icon: <LiquorOutlined />,
				rol: ['administrator'],
			},
			{
				path: '/admin/configuration/event-type',
				name: 'Tipos de evento',
				icon: <LiquorOutlined />,
				rol: ['administrator'],
			},
			{
				path: '/admin/configuration/service-type',
				name: 'Tipos de servicios',
				icon: <RoomServiceOutlined />,
				rol: ['administrator'],
			},
		],
		rol: ['administrator'],
	},
];

const footerMenu = [
	{
		path: '/logout',
		name: 'Salir',
		icon: <Logout />,
	},
];

const closeSession = fnNavigate => {
	localStorage.clear();
	fnNavigate('/admin');
	/* Refresca la pantalla para limpiar el estado/redux de a aplicacion */
	window.location.reload();
};

/* Encuentra el nombre de la vista en la que se encuentra segun el menu pasado */
const findItemMenu = (arraySource, pathname) => {
	const parts = pathname.split('/').filter(x => x !== '');
	parts.splice(0,1);
	return arraySource.reduce((itemMenu, item) => {
		/* Si coincide la ruta con la buscada se retorna el objeto */
		if (item.path === pathname) {
			return item;
		}

		/* Si tiene hijos, se busca en los hijos */
		if (item.children && item.children.length) {
			let itemSelected = findItemMenu(item.children, pathname);
			/* Si se encontro un registro, se retorno el resultado */
			if (itemSelected?.name?.length) return itemSelected;
		}

		/* Si parte de la URL tiene informacion del menú */
		if (parts.length && parts.filter(x => item.path.includes(x)).length) {
			return item;
		}

		/* Se retorno el valor anterior */
		return itemMenu;
	}, {});
};

const useMenu = () => {
	const navigate = useNavigate();
	const location = useLocation();

	/* Carga inicial */
	const firtsItem = findItemMenu(headerMenuAll, location.pathname);
	/* Encuentra elemento del menu segun url */
	const [itemBylocation, setItemByLocation] = useState(firtsItem);

	/* Si se actualiza la URL se actualiza el objeto del menú */
	useEffect(() => {
		setItemByLocation(findItemMenu(headerMenuAll, location.pathname));
	}, [location.pathname]);

	const rol = useSelector(({ user }) => {
		const text = user?.entity?.role?.type;
		return text;
	});

	const headerMenu = headerMenuAll.filter(item => item.rol.includes(rol));

	const goTo = to => {
		/* Cerrar sesion */
		if (to === '/logout') {
			closeSession(navigate);
		} else {
			navigate(to, {
				state: {
					previous_key: location.key,
					previous_page: location.pathname,
				},
			});
		}
	};

	return {
		itemBylocation,
		headerMenu,
		footerMenu,
		goTo,
	};
};

export default useMenu;
