import { useState } from 'react';

import { Box, IconButton, Typography, Grid } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import AlertDialog from './AlertDialog';
import loading from '../assets/loading-pulse.svg';

const INACTIVE = {
	borderLeftColor: 'rgb(244 67 54)',
	borderLeftWidth: '5px',
	borderLeftStyle: 'solid',
};

const NORMAL = {
	mb: '8px',
	pl: '16px',
	borderRadius: '3px',
	'&:hover': {
		backgroundColor: 'secondary.lightover',
	},
	cursor: 'pointer',
	borderLeftColor: 'rgb(240 245 240)',
	borderLeftWidth: '5px',
	borderLeftStyle: 'solid',
};

const widthObject = (widthCells, key) => {
	if (widthCells && widthCells[key]) return widthCells[key];
	return {};
};

const RowList = ({ data, widthCells }) => {
	const onClick = data.onClick;

	/* Para ignorar las key del objeto que no son datos para mostrar en la fila */
	const SPECIAL_KEYS = ['onClick', 'delete', 'id', 'inactive'];
	/* Se convierte en arreglo las key del objeto para crea la fila, filtrando las key especiales */
	const cellKeys = Object.keys(data).filter(
		key => !SPECIAL_KEYS.includes(key)
	);
	return (
		<Grid container alignItems="center">
			{cellKeys.map((key, index) => (
				<Grid
					key={`cell-${key}-${index}`}
					item
					sx={{ p: '8px' }}
					{...widthObject(widthCells, key)}
					{...{ onClick }}
				>
					<Typography
						noWrap
						component={'span'}
						sx={{ display: 'block' }}
					>
						{data[key]}
					</Typography>
				</Grid>
			))}
			{data.delete && (
				<Grid item sx={{ p: '8px' }} xs={1}>
					{data.delete}
				</Grid>
			)}
		</Grid>
	);
};

const DataListDynamic = ({
	rowTitle,
	rowsData,
	widthCells,
	deleteFunction,
	status,
	title,
	subtitle,
	alertMessage,
}) => {
	const [itemToDelete, setItemToDelete] = useState(null);

	return (
		<Grid container>
			{title && (
				<Grid item xs={12}>
					<Typography variant="subtitle1" component="h2">
						{title}
					</Typography>
					<Typography variant="body2" component="p" color="secondary">
						{subtitle}
					</Typography>
				</Grid>
			)}

			{rowTitle && (
				<Grid item xs={12}>
					<RowList {...{ widthCells }} data={rowTitle} />
				</Grid>
			)}

			{status?.loading === 'pending' && (
				<img
					src={loading}
					width="50px"
					style={{ margin: 'auto', display: 'block' }}
					height="50px"
					alt="Cargando ..."
				/>
			)}
			<Grid item xs={12}>
				{rowsData.map((element, index) => (
					<Box
						key={index}
						sx={
							element.inactive
								? { ...NORMAL, ...INACTIVE }
								: NORMAL
						}
					>
						<RowList
							{...{ widthCells }}
							data={{
								...element,
								delete: deleteFunction && (
									<IconButton
										onClick={() => setItemToDelete(element)}
										disabled={element.id ? false : true}
									>
										<DeleteOutlined />
									</IconButton>
								),
							}}
						/>
					</Box>
				))}
			</Grid>

			<AlertDialog
				title={`Eliminar registro`}
				msg={
					alertMessage
						? `${itemToDelete?.name}: ${alertMessage}`
						: `Debes confirmar que deseas eliminar: "${itemToDelete?.name}" de la lista.`
				}
				fnToDo={{
					action: () => {
						const id = itemToDelete.id;
						deleteFunction(id);
						setItemToDelete(null);
					},
					closeWindow: () => {
						/* Se reinicia todo */
						setItemToDelete(null);
					},
				}}
				active={itemToDelete?.id ? true : false}
			/>
		</Grid>
	);
};

export default DataListDynamic;
