import {
	Container,
	Grid,
	Typography,
	Divider,
	SpeedDial,
	Button,
} from '@mui/material';
import { AddOutlined } from '@mui/icons-material';

import DataListDynamic from '../components/DataListDynamic';
import ListFilter from '../components/ListFilter';

import { useWorkspace, useMenu } from '../hooks';

const rowTitle = {
	name: (
		<Typography variant="subtitle2" component="div">
			Nombre
		</Typography>
	),
	description: (
		<Typography variant="subtitle2" component="div">
			Descripción
		</Typography>
	),
	price_type: (
		<Typography variant="subtitle2" component="div">
			Tipo p.
		</Typography>
	),
	price: (
		<Typography variant="subtitle2" component="div">
			Precio
		</Typography>
	),
	status: (
		<Typography variant="subtitle2" component="div">
			Estatus
		</Typography>
	),
};

/* Configurando responsividad de la tabla. Siempre trabajar en base a 11 porque 1 está destinado al boton eliminar. */
const widthCells = {
	name: {
		sx: { display: { xs: 'block', sm: 'block', md: 'block', lg: 'block' } },
		xs: 11,
		sm: 11,
		md: 4,
		lg: 3,
	},
	description: {
		sx: { display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } },
		lg: 3,
	},
	price_type: {
		sx: { display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } },
		lg: 2,
	},
	price: {
		sx: { display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } },
		md: 4,
		lg: 2,
	},
	status: {
		sx: { display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } },
		md: 3,
		lg: 1,
	},
};

const priceType = {
	hour: 'Hora',
	day: 'Día',
	halfDay: 'Medio día',
};

const WorkspaceList = () => {
	const {
		workspaceList,
		totalWorkspaces,
		deleteWorkspace,
		requestStatus,
		loadMore,
		filterInputs,
		customWorkspaceList,
	} = useWorkspace({});
	const { goTo } = useMenu();

	const status = {
		active: 'Activo',
		inactive: 'Inactivo',
	};

	const rowsData = workspaceList.map(item => ({
		id: item.id,
		name: item.name,
		description: item.description,
		price_type: priceType[item.price_type],
		price: item.price,
		status: status[item.status],
		inactive: item.status === 'inactive',
		onClick: () => goTo(`/workspaces/${item.id}`),
	}));

	return (
		<Container className="workspace-list" maxWidth="xl" sx={{ mb: 4 }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" component="h1">
						Lista de salones
					</Typography>
					<Typography variant="body2" component="p" color="secondary">
						Lorem ipsum dolor sit, amet consectetur adipisicing
						elit. Quisquam minus voluptas facere voluptatum sit
						neque laboriosam quam magni nemo optio delectus
						perspiciatis iure quod dolore in consequuntur, itaque
						iusto mollitia.
					</Typography>
					<Divider
						sx={{
							mt: 2,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<ListFilter
						inputs={filterInputs}
						submit={customWorkspaceList}
					/>
					{rowsData?.length > 0 && (
						<DataListDynamic
							status={requestStatus}
							{...{ rowTitle, rowsData, widthCells }}
							deleteFunction={deleteWorkspace}
						/>
					)}
				</Grid>
				{rowsData.length < totalWorkspaces ? (
					<Button
						onClick={loadMore}
						variant="outlined"
						sx={{ margin: 'auto' }}
					>
						Cargar más
					</Button>
				) : (
					<Button disabled variant="outlined" sx={{ margin: 'auto' }}>
						Fin de la lista
					</Button>
				)}
			</Grid>
			<SpeedDial
				ariaLabel="Agregar salón"
				sx={{ position: 'fixed', bottom: 16, right: 16 }}
				icon={<AddOutlined />}
				onClick={() => goTo('/admin/workspaces/new')}
			></SpeedDial>
		</Container>
	);
};

export default WorkspaceList;
