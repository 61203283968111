import { useMenu } from '../hooks';
import { useSelector } from 'react-redux';
import {
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
} from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

const status = {
	active: 'Activo',
	inactive: 'Inactivo',
};

const categoriesName = categories => {
	return categories.reduce(
		(names, category, index) =>
			!index ? category.name : `${names}, ${category.name}`,
		''
	);
};

const ProviderDetails = ({ providerData, edit }) => {
	const { goTo } = useMenu();

	const rol = useSelector(({ user }) => {
		const text = user?.entity?.role?.type;
		return text;
	});
	
	return (
		<List>
			<ListItem>
				<ListItemText
					primary={
						<Typography variant="h6" component="h2">
							Detalles del proveedor
						</Typography>
					}
				/>
				{rol==="administrator" && edit && (
					<ListItemIcon>
						<IconButton
							onClick={() =>
								goTo(`/admin/providers/${providerData.id}/edit`)
							}
						>
							<EditOutlined />
						</IconButton>
					</ListItemIcon>
				)}
			</ListItem>
			<ListItem>Nombre: {providerData.name}</ListItem>
			<ListItem>Slug: {providerData.slug}</ListItem>
			<ListItem>URL Iframe: {providerData.url_iframe}</ListItem>
			<ListItem>Email: {providerData.email}</ListItem>
			<ListItem>Región: {providerData.region}</ListItem>
			<ListItem>Comuna: {providerData.commune}</ListItem>
			<ListItem>Dirección: {providerData.address}</ListItem>
			<ListItem>Descripción: {providerData.description}</ListItem>
			<ListItem>
				Tipo de proveedor: {providerData.provider_type?.name}
			</ListItem>

			<ListItem>
				Tipos de eventos:{' '}
				{providerData.event_types?.length
					? categoriesName(providerData.event_types)
					: 'Ninguno'}
			</ListItem>

			<ListItem>
				Categoría:{' '}
				{providerData.provider_categories?.length
					? categoriesName(providerData.provider_categories)
					: 'Ninguno'}
			</ListItem>
			<ListItem>Estatus: {status[providerData.status]}</ListItem>
			<ListItem>Comentarios: {providerData.comments}</ListItem>
		</List>
	);
};

export default ProviderDetails;
