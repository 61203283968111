import { combineReducers } from 'redux';

import { reduceReducer } from '../utils';

const makeCrudReducer =
	actions =>
	(state = [], action) => {
		switch (action.type) {
			case actions[0]: {
				return { ...state, ...action.payload };
			}
			default:
				return state;
		}
	};

const crudReducer = makeCrudReducer(['menu-options/set']);
const menuOptionsReducer = reduceReducer(crudReducer);

const reducer = combineReducers({
	options: menuOptionsReducer,
});

export default reducer;
