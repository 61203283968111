const inputs = [
	{
		type: 'text',
		label: 'Nombre',
		name: 'name',
		placeholder: 'Sebastian M...',
		required: true,
	},
	{
		type: 'text',
		label: 'Cargo',
		name: 'position',
		placeholder: 'Vendedor',
	},
	{
		type: 'email',
		label: 'Email',
		name: 'email',
		placeholder: 'vendedor@hotel.cl',
	},
	{
		type: 'text',
		label: 'Teléfono(s)',
		name: 'telephon',
		placeholder: '+56912345678 - +56221234567',
	},
	{
		type: 'text',
		multiline: true,
		label: 'Comentarios',
		name: 'comments',
		placeholder: 'Contesta más por email...',
	},
];

export default inputs;
