import api from '../../plugins/api';
import { mat, asyncMac } from '../utils';

const asyncUser = mat('services');
const [setPending, setFulfilled, setError] = asyncMac(asyncUser);

/* Listar a los servicios */
const fetchServices = params => (dispatch, getState) => {
	dispatch(setPending());
	const state = getState();
	const limit = 0;/*params?.limit || process.env.REACT_APP_PER_PAGE;*/

	let query = `?_limit=${limit}&_start=${params?.start || 0}`;

	if (params?.query?.length) query += params.query;

	return api()
		.get(`/services${query}`)
		.then(({ data }) => {
			const entities = state.services.entities;
			dispatch(setFulfilled([...entities, ...data]));
		})
		.catch(error => dispatch(setError(error)));
};

/* Total servicios */
const fetchTotalService = params => dispatch => {
	/* Filtros para contar */
	let query = `?`;
	if (params?.query?.length) query += params.query;

	return api()
		.get(`/services/count${query}`)
		.then(({ data }) => {
			dispatch({ type: 'service-total/set', payload: data });
		})
		.catch(error => dispatch(setError(error)));
};

/* Agregando un nuevo servicio */
const pushService = form => async dispatch => {
	dispatch(setPending());
	return api()
		.post(`/services`, form)
		.then(({ data }) => {
			dispatch({ type: 'services/add', payload: data });
			dispatch({ type: 'service-total/add' });
		})
		.catch(error => dispatch(setError(error)));
};

/* Actualizando un servicio */
const putService = (id, form) => async dispatch => {
	dispatch(setPending());
	return api()
		.put(`/services/${id}`, form)
		.then(({ data }) => {
			dispatch({ type: 'services/update', payload: data });
		})
		.catch(error => dispatch(setError(error)));
};

/* Limpiar la lista de servicios */
const clearServices = () => dispatch => {
	dispatch({ type: 'services/clear' });
	dispatch({ type: 'service-total/clear' });
};

/* INDIVIDUAL */
/* Esta instancia se ejecuta cuando el usuario ingresa directo a la URL y no existe lista */
const readService = id => dispatch => {
	dispatch(setPending());

	return api()
		.get(`/services/${id}`)
		.then(({ data }) => {
			dispatch(setFulfilled([data]));
		})
		.catch(error => dispatch(setError(error)));
};

/* Elimando un servicio */
const deleteService = id => async dispatch => {
	dispatch(setPending());
	return api()
		.delete(`/services/${id}`)
		.then(() => {
			dispatch({ type: 'services/remove', payload: id });
			dispatch({ type: 'service-total/remove' });
		})
		.catch(error => dispatch(setError(error)));
};

const services = {
	fetchServices,
	readService,
	fetchTotalService,
	pushService,
	putService,
	deleteService,
	clearServices,
};

export default services;
