import { useMenu } from '../hooks';
import {
	Paper,
	Typography,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	Button,
} from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

const UserDetailsPersonal = ({ userData }) => {
	const { goTo } = useMenu();
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12}>
				<Typography variant="h2" component="h1">
					{`${userData.name} ${userData.lastname}`}{' '}
				</Typography>
			</Grid>
			<Grid item xs={12} sm={4}>
				<Paper variant="outlined">
					<List>
						<ListItem>
							<ListItemText
								primary={
									<Typography variant="h6" component="h2">
										Detalles del usuario
									</Typography>
								}
							/>
							<ListItemIcon>
								<IconButton
									onClick={() =>
										goTo(`/admin/users/${userData.id}/edit`)
									}
								>
									<EditOutlined />
								</IconButton>
							</ListItemIcon>
						</ListItem>
						<ListItem>Nombre: {userData.name}</ListItem>
						<ListItem>Apellido: {userData.lastname}</ListItem>
						<ListItem>Correo: {userData.user?.email}</ListItem>
						<ListItem>Compañía: {userData.company}</ListItem>
						<ListItem>
							<Button
								variant="outlined"
								fullWidth
								onClick={() =>
									goTo(`/admin/users/${userData.id}/reset`)
								}
							>
								Actualizar contraseña
							</Button>
						</ListItem>
					</List>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={4}>
				<Paper variant="outlined">
					<List>
						<ListItem>
							<Typography variant="h6" component="h2">
								Inicios de sesión
							</Typography>
						</ListItem>
						<ListItem>2021-12-01 14:32</ListItem>
						<ListItem>2022-01-01 14:32</ListItem>
						<ListItem>2022-01-01 14:32</ListItem>
						<ListItem>2022-01-01 14:32</ListItem>
					</List>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default UserDetailsPersonal;
