const inputs = (eventTypes = [], eventFormats = [], regions = [], communes = []) => [
	{
		type: 'text',
		label: 'Nombre',
		name: 'name',
		placeholder: 'Ej: ',
		required: true,
		autoFocus: true,
	},
	{
		type: 'select',
		label: 'Formato de evento',
		name: 'event_formats',
		options: eventFormats,
	},
	{
		type: 'select',
		label: 'Tipo de evento',
		name: 'event_types',
		options: eventTypes,
	},
	{
		type: 'select',
		label: 'Región',
		name: 'region',
		options: regions,
	},
	{
		type: 'select',
		label: 'Comuna',
		name: 'commune',
		options: communes,
	},
	{
		type: 'number',
		label: 'Asistentes',
		name: 'attendees',
		placeholder: '',
	},
	{
		type: 'date',
		label: 'Inicio',
		name: 'date_start',
		placeholder: '',
	},
	{
		type: 'date',
		label: 'Término',
		name: 'date_end',
		placeholder: '',
	},
	{
		type: 'text',
		multiline: true,
		label: 'Detalles',
		name: 'requirements',
		placeholder: 'Ej: ',
	},
	{
		type: 'text',
		multiline: true,
		label: 'Comentarios',
		name: 'comments',
		placeholder: 'Ej: ',
	},
];

export default inputs;
