import {
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Grid,
} from '@mui/material';
import { useState } from 'react';

const columnNumber = {
	'1-column': [12],
	'2-column': [6, 6],
	'3-column': [4, 4, 4],
	'4-column': [3, 3, 3, 3],
	'5-column': [4, 2, 2, 2, 2],
	'6-column': [2, 2, 2, 2, 2, 2],
};

const columnNumberMd = {
	'1-column': [12],
	'2-column': [6, 6],
	'3-column': [4, 4, 4],
	'4-column': [6, 6, 6, 6],
	'5-column': [6, 6, 4, 4, 4],
	'6-column': [6, 6, 6, 6, 6, 6],
};

const ListFilter = ({ inputs, submit }) => {
	const length = inputs.length;
	const widthColumns = columnNumber[`${length}-column`];
	const widthColumnsMd = columnNumberMd[`${length}-column`];

	const [query, setQuery] = useState({});

	const onChange = field => {
		const newQuery = { ...query, ...field };
		setQuery(newQuery);
		submit(newQuery);
	};

	return (
		<Grid container spacing={2}>
			{inputs.map((input, index) => {
				if (input.type === 'select') {
					return (
						<Grid
							item
							key={`filter-${index}`}
							xs={12}
							md={widthColumnsMd[index]}
							lg={widthColumns[index]}
						>
							<FormControl fullWidth sx={{ pt: 2 }}>
								<InputLabel id={`id-${input.name}-label`}>
									{input.label}
								</InputLabel>
								<Select
									labelId={`id-${input.name}-label`}
									id={`id-${input.name}`}
									label={input.label}
									defaultValue=""
									onChange={(e, v) =>
										onChange({
											[input.name]: v.props.value,
										})
									}
								>
									<MenuItem value="">Todos</MenuItem>
									{input.options &&
										input.options.map((option, index) => (
											<MenuItem
												key={`option-${index}`}
												value={option.value}
											>
												{option.name || option.value}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
					);
				}

				return (
					<Grid
						item
						key={`filter-${index}`}
						xs={12}
						md={widthColumnsMd[index]}
						lg={widthColumns[index]}
					>
						<TextField
							defaultValue=""
							type={input.type || 'text'}
							fullWidth
							margin="normal"
							label={input.label}
							placeholder={input.placeholder}
							id={`id-${input.name}`}
							onChange={e =>
								onChange({ [input.name]: e.target.value })
							}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default ListFilter;
