import { useSelector } from 'react-redux';
import useList from './useList';

/* Definicion de los campos para el formulario */
import formInputsFn from './useNegotiation/inputs';

/* Definicion de los campos para el filtro de busqueda */
import filterInputs from './useNegotiation/inputsFind';

import actions from '../redux/action';
const fetchNegotiations = actions.fetchNegotiations;
const removeNegotiation = actions.deleteNegotiation;
const clearNegotiations = actions.clearNegotiations;
const fetchTotalNegotiation = actions.fetchTotalNegotiation;
const readNegotiation = actions.readNegotiation;
const pushNegotiation = actions.pushNegotiation;
const putNegotiation = actions.putNegotiation;

const useNegotiation = ({ ID }) => {
	/* Funciones para la administracion de negociaciones */
	/* Armar el query que se ejecutara segun el filtro aplicado */
	const queryFilter = filter => {
		let queryString = '';

		/* Setea el query para ser usado para cargar más */
		if (filter?.keyword) {
			const text = filter.keyword;
			queryString += `&_where[_or][0][name_contains]=${text}&_where[_or][1][requirements_contains]=${text}`;
		}

		if (filter?.status) {
			const status = filter.status;
			queryString += `&status=${status}`;
		}

		return queryString;
	};

	const {
		entityList: negotiationList,
		customEntityList: customNegotiationList,
		totalEntities: totalNegotiations,
		readEntity: getNegotiation,
		resetList,
		loadMore,
		createEntity: createNegotiation,
		updateEntity: updateNegotiation,
		deleteEntity: deleteNegotiation,
		requestStatus,
		entityData: negotiationData,
	} = useList({
		statusFn: ({ negotiations }) => negotiations?.status,
		entitiesFn: ({ negotiations }) => negotiations?.entities,
		totalFn: ({ negotiations }) => negotiations.total,
		queryFilter,
		clear: clearNegotiations,
		fetchTotal: fetchTotalNegotiation,
		fetch: fetchNegotiations,
		read: readNegotiation,
		push: pushNegotiation,
		put: putNegotiation,
		remove: removeNegotiation,
		ID,
	});

	/* Lista de REGIONES Y COMUNAS */
	const regions = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations?.entity?.countries[0]?.data?.map(
				regions => ({
					name: regions.name,
					value: regions.id,
				})
			);
			list = JSON.parse(JSON.stringify(list));
			return list;
		}

		return [];
	});

	const communes = useSelector(({ configurations }) => {
		if (configurations.entity?.countries?.[0]) {
			let list = configurations.entity.countries?.[0].data.reduce(
				(communesOptions, region) => {
					const options = region.communes.map(commune => ({
						name: commune.name,
						value: commune.id,
						dependence: {
							name: 'region',
							value: region.id,
						}, // Este campo tiene un padre y los valores dependen de ese padre
					}));

					return [...communesOptions, ...options];
				},
				[]
			);
			list = JSON.parse(JSON.stringify(list));
			return list;
		}
		return [];
	});

	/* Lista de TIPOS general filtra las categorias */
	const eventTypes = useSelector(({ configurations }) =>
		configurations?.entity?.eventTypes?.map(type => ({
			name: type.name,
			value: type.id,
			status: type.status,
		}))
	);
	const eventFormats = useSelector(({ configurations }) =>
		configurations?.entity?.eventFormats?.map(type => ({
			name: type.name,
			value: type.id,
			status: type.status,
		}))
	);

	const formInputs = formInputsFn(eventTypes, eventFormats, regions, communes);

	return {
		negotiationList,
		customNegotiationList,
		totalNegotiations,
		resetList,
		getNegotiation,
		loadMore,
		createNegotiation,
		updateNegotiation,
		deleteNegotiation,
		requestStatus,
		negotiationData,
		formInputs,
		filterInputs,
	};
};

export default useNegotiation;
