import api from '../../plugins/api';
import { mat, asyncMac } from '../utils';

const asyncUser = mat('providers');
const [setPending, setFulfilled, setError] = asyncMac(asyncUser);

/* Listar a los proveedores */
const fetchProviders = params => (dispatch, getState) => {
	dispatch(setPending());
	const state = getState();
	const limit = 0;/*params?.limit || process.env.REACT_APP_PER_PAGE;*/

	let query = `?_limit=${limit}&_start=${params?.start || 0}`;

	if (params?.query?.length) query += params.query;

	return api()
		.get(`/providers${query}`)
		.then(({ data }) => {
			const entities = state.providers.entities;
			dispatch(setFulfilled([...entities, ...data]));
		})
		.catch(error => dispatch(setError(error)));
};

/* Listar a los proveedores */
const fetchProvidersAll = () => (dispatch, getState) => {
	dispatch(setPending());
	const state = getState();
	return api()
		.get(`/providers`)
		.then(({ data }) => {
			return data;
		})
		.catch(error => dispatch(setError(error)));
};

/* Total proveedores */
const fetchTotalProvider = params => dispatch => {
	/* Filtros para contar */
	let query = `?`;
	if (params?.query?.length) query += params.query;

	return api()
		.get(`/providers/count${query}`)
		.then(({ data }) => {
			dispatch({ type: 'provider-total/set', payload: data });
		})
		.catch(error => dispatch(setError(error)));
};

/* Agregando un nuevo proveedor */
const pushProvider = form => async dispatch => {
	dispatch(setPending());
	return api()
		.post(`/providers`, form)
		.then(({ data }) => {
			dispatch({ type: 'providers/add', payload: data });
			dispatch({ type: 'provider-total/add' });
		})
		.catch(error => dispatch(setError(error)));
};

/* Actualizando un proveedor */
const putProvider = (id, form) => async dispatch => {
	dispatch(setPending());
	return api()
		.put(`/providers/${id}`, form)
		.then(({ data }) => {
			dispatch({ type: 'providers/update', payload: data });
		})
		.catch(error => dispatch(setError(error)));
};

/* Limpiar la lista de proveedores */
const clearProviders = () => dispatch => {
	dispatch({ type: 'providers/clear' });
	dispatch({ type: 'provider-total/clear' });
};

/* INDIVIDUAL */
/* Esta instancia se ejecuta cuando el usuario ingresa directo a la URL y no existe lista */
const readProvider = id => dispatch => {
	dispatch(setPending());

	return api()
		.get(`/providers/${id}`)
		.then(({ data }) => {
			dispatch(setFulfilled([data]));
		})
		.catch(error => dispatch(setError(error)));
};

/* Elimando un proveedor */
const deleteProvider = id => async dispatch => {
	dispatch(setPending());
	return api()
		.delete(`/providers/${id}`)
		.then(() => {
			dispatch({ type: 'providers/remove', payload: id });
			dispatch({ type: 'provider-total/remove' });
		})
		.catch(error => dispatch(setError(error)));
};

/* Busqueda para la negociación */
const availableProvider = params => (dispatch, getState) => {
	dispatch(setPending());
	const state = getState();
	const limit = params?.limit || process.env.REACT_APP_PER_PAGE;
	const start = params?.start || 0;

	let query = `?_limit=${limit}&_start=${start}`;

	if (params?.query?.length) query += params.query;

	return api()
		.get(`/providers/available${query}`)
		.then(({ data }) => {
			const entities = start > 0 ? state.providers.entities : [];
			dispatch(setFulfilled([...entities, ...data]));
		})
		.catch(error => dispatch(setError(error)));
};
/* Busqueda para la negociación: Total proveedores */
const availableProviderTotal = params => dispatch => {
	/* Filtros para contar */
	let query = `?`;
	if (params?.query?.length) query += params.query;

	return api()
		.get(`/providers/available/count${query}`)
		.then(({ data }) => {
			dispatch({ type: 'provider-total/set', payload: data });
		})
		.catch(error => dispatch(setError(error)));
};

const providers = {
	fetchProviders,
	readProvider,
	fetchTotalProvider,
	pushProvider,
	putProvider,
	deleteProvider,
	clearProviders,
	availableProvider,
	availableProviderTotal,
	fetchProvidersAll
};

export default providers;
