import { useMenu } from '../hooks';

import {
	Box,
	List,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
} from '@mui/material';

const SubMenu = ({ children, activeMenu, father, isActive }) => {
	return (
		<Collapse in={isActive(father)} timeout="auto" unmountOnExit>
			{children.map((element, index) => (
				<ListItem
					button
					key={`sub-item-${index}`}
					onClick={() => activeMenu(element)}
					sx={
						isActive(element.path)
							? {
									backgroundColor: 'light:dark',
							  }
							: { backgroundColor: 'light:dark' }
					}
				>
					<ListItemIcon>{element.icon}</ListItemIcon>
					<ListItemText primary={element.name} />
				</ListItem>
			))}
		</Collapse>
	);
};

const MenuList = ({ menuClick }) => {
	const { goTo, headerMenu, footerMenu, itemBylocation } = useMenu();

	/* Cambiar de ruta */
	const activeMenu = element => {
		goTo(element.path);

		/* Cerrar el menu en el movil, al hacer clic en un item del menu */
		if (menuClick) menuClick();
	};

	/* Retorna boolean que indica si se encuentra en item pasado como parametro */
	const isActive = path => {
		return path === itemBylocation?.path
			? true
			: path.length > 6 && itemBylocation?.path.includes(path)
			? true
			: false;
	};

	return (
		<Box>
			<List>
				{headerMenu.map((element, index) => (
					<Box key={index}>
						<ListItem
							button
							onClick={() => activeMenu(element)}
							sx={
								isActive(element.path)
									? {
											borderLeftColor: 'secondary.dark',
											borderLeftStyle: 'solid',
											borderLeftWidth: '5px',
											backgroundColor:
												'light.dark',
									  }
									: {}
							}
						>
							<ListItemIcon>{element.icon}</ListItemIcon>
							<ListItemText primary={element.name} />
						</ListItem>
						{element.children && (
							<SubMenu
								{...{
									father: element.path,
									children: element.children,
									activeMenu,
									isActive,
								}}
							/>
						)}
					</Box>
				))}
			</List>
			<Divider />
			<List>
				{footerMenu.map((element, index) => (
					<ListItem
						button
						key={index}
						onClick={() => activeMenu(element)}
					>
						<ListItemIcon>{element.icon}</ListItemIcon>
						<ListItemText primary={element.name} />
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default MenuList;
