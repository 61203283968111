import {ArrowBack, ArrowForward, Close, Info, Person, Edit, Logout, Apartment, PanoramaSharp, AccessTimeFilled} from '@mui/icons-material';
import { useNavigate, Outlet, useParams } from 'react-router-dom';
import api from '../../plugins/api';
import { useForm, Controller } from 'react-hook-form';
import * as React from 'react';
import {
	Container,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Typography,
	Button,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Divider,
	Box,
	LinearProgress,
	TextField,
	Stack,
	Card,
	CardContent,
	CardActions,
	CardActionArea,
	IconButton,
	Modal,
	Alert,
	Fab,
	Popper,
	Fade,
	Paper,
	Tooltip,
	Link,
	getToolbarUtilityClass
} from '@mui/material';

import { useEffect, useState, useRef  } from 'react';
import PNavBar from '../../components/public/PNavBar';
import Loader from '../../components/Loader';
import LoaderSingle from '../../components/LoaderSingle';
import usePNavBar from '../../hooks/usePNavBar';
import useProvider from '../../hooks/useProvider';

const PClientQuotation = () => {
	
	const {goTo} = usePNavBar();

	const [showLoader, setShowLoader] = useState(false);
	const [providerSelected, setProviderSelected] = useState(null);
	const [providerPage, setProviderPage] = useState(null);
	const [providerPageId, setProviderPageId] = useState("");
	const [client, setClient] = useState(null);
	const [negotiationData,setNegotiationData] = useState({});
	const [modalAOpen, setModalAOpen] = useState(false);
	const handleMAOpen = () => setModalAOpen(true);
	const handleMAClose = () => setModalAOpen(false);
	const [modalBOpen, setModalBOpen] = useState(false);
	const handleMBOpen = () => {setModalBOpen(true); setLoaderIframe(true);};
	const handleMBClose = () => setModalBOpen(false);
	const [loaderIframe,setLoaderIframe] = useState(false);
	const [negotiationID,setNegotiationID] = useState(null);
	const [eventFormat, setEventFormat] = useState('');
	const refProviderOptions = useRef(null);
	const refProviderSelected = useRef(null);
	const refScrollTop= useRef(null);

	const params = useParams();

	const {
		providerList
	} = useProvider({});

	const localClient = JSON.parse(localStorage.getItem("client"));
	const localProviderID = localStorage.getItem("providerID");

	useEffect(() => {
		if(!params.provider){
			if(localProviderID){
				setShowLoader(true);
				setProviderPageId(localProviderID);
				console.log(localProviderID);
			}
		}
		if(localClient){
			setClient(localClient);
		}
	},[]);


	useEffect(() => {
		if(providerList.length>0 && providerPageId){
			setShowLoader(false);
			setProviderPage(providerList.find(element => element._id===providerPageId));
		}
		console.log(providerList);
	},[providerList,providerPageId]);

	useEffect(() => {
		refProviderSelected?.current?.scrollIntoView({block: "start", behavior: "smooth"});
	}, [providerSelected]);

	useEffect(()=>{
		if(loaderIframe){
			setTimeout(()=>{
				setLoaderIframe(false);
			},3000);
		}
	},[loaderIframe])

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const scrollToBottom = () => {
		window.scrollTo({
		  top: document.documentElement.scrollHeight,
		  behavior: 'smooth',
		});
	  };

	const boxModalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
  		maxWidth: '700px',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		pt:8
	};

	const boxModalIframeStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '90%',
        height: '80%',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: "5px",
		pt:"20px"
    };

	const clearUser = () =>{
		localStorage.removeItem("client");
		setClient(null);
		goTo("/cotizar");
	}

	const clearProvider = () =>{
		localStorage.removeItem("providerID");
		setProviderPage(null);
		setProviderPageId(null);
	}

	//FORM CONTROLLERS
	const {register: registerFClientEdit, handleSubmit: handleSubmitFClientEdit} = useForm();
	const [clientConfirmEdit,setClientConfirmEdit] = useState(false);

	const editClient = () => {
		handleMAOpen();
	};

	const submiteFCE = (dataForm) => {
		setShowLoader(true);
		api()
		.put(`/user-infos/${client.id}`, dataForm)
		.then(async ()  =>  {
			client.name = dataForm.name;
			client.telephone = dataForm.telephone;
			client.lastname = dataForm.lastname;
			client.company = dataForm.company;
			setClient(client);
			localStorage.setItem("client", JSON.stringify(client));
			setClientConfirmEdit(true);
			setShowLoader(false);
		});
    };

	return (
			
		<Container className="clientQuotation" maxWidth="xl" component="main" sx={{ mb: 4}}>
			<Loader showLoader={showLoader}/>
			<PNavBar/>
			<Modal
				open={modalAOpen}
				onClose={handleMAClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxModalStyle}>
				<IconButton color="primary" sx={{position:"absolute",top:"10px",right:"10px"}} aria-label="Cerrar" onClick={handleMAClose}>
					<Close />
				</IconButton>
				<form
					className="form-client-edit"
					onSubmit={handleSubmitFClientEdit(submiteFCE)}
					style={{ width: '100%' }}
				>
				<Stack
					spacing={{ xs: 1, sm: 2, md: 4 }}
				>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Email"
							id="email"
							name="email"
							variant="standard"
							disabled
							defaultValue={client?.user ? client.user.email : ""}
						></TextField>
					</FormControl>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Nombre"
							id="name"
							name="name"
							variant="standard"
							required
							defaultValue={client ? client.name : ""}
							{...registerFClientEdit("name")}
						></TextField>
					</FormControl>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Apellido"
							id="lastname"
							name="lastname"
							variant="standard"
							required
							defaultValue={client ? client.lastname : ""}
							{...registerFClientEdit("lastname")}
						></TextField>
					</FormControl>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Teléfono"
							id="phone"
							name="phone"
							variant="standard"
							required
							defaultValue={client ? client.telephone : ""}
							{...registerFClientEdit("telephone")}
						></TextField>
					</FormControl>
					<FormControl fullWidth variant="standard" sx={{alignContent:"center"}}>
						<TextField
							label="Compañia o Institución"
							id="company"
							name="company"
							variant="standard"
							
							defaultValue={client ? client.company : ""}
							{...registerFClientEdit("company")}
						></TextField>
					</FormControl>
					{clientConfirmEdit && <Alert sx={{mt:2}} severity="success">Se actualizó correctamente su información</Alert>}
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						sx={{mt:2}}
					>
						{"ACTUALIZAR"}
					</Button>
				</Stack>
				</form>
				</Box>
			</Modal>
			<Modal
				open={modalBOpen}
				onClose={handleMBClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
                keepMounted
			>
				<Box sx={boxModalIframeStyle}>
                    <Stack 
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Button
                            endIcon={<Close />}
                            variant="contained"
                            color="secondary"
                            onClick={handleMBClose}
                        >
                            {"Cerrar ficha"}
                        </Button>
                    </Stack>
                    <Box sx={{display:"contents"}}>
						<div style={{width:"96%", height:"90%",margin:"2%",border:"solid 3px",display: loaderIframe ? "block":"none"}}>
							<LoaderSingle />
						</div>
						<iframe src={providerPage?.url_iframe} style={{width:"96%", height:"90%",margin:"2%",border:"solid 3px",display: !loaderIframe ? "block":"none"}}></iframe>
                    </Box>
				</Box>
			</Modal>
			<Grid container spacing={2} justifyContent="center">
				<Grid item xs={12} lg={10} xl={8}>
					<Box sx={{ width: '100%'}} ref={refScrollTop}>
						{ client &&
						<>
						<Card sx={{mb:2, boxShadow: 3}}>
							<CardContent sx={{pt:"10px !important",pb:"10px !important"}}>
								<Stack direction="row" spacing={1} >
									<Person color="primary" sx={{mt:"7px"}}/>
									<Typography variant="h7" gutterBottom component="div" sx={{pt:"7px"}}>
										{client?.name+" "+client?.lastname} 
									</Typography>
									<>
										<Stack direction="row" spacing={1}>
											<Tooltip title="Editar información">
												<IconButton color="secondary" onClick={()=>  editClient()}>
													<Edit />
												</IconButton>
											</Tooltip>
											<Tooltip title="Salir">
												<IconButton color="secondary" onClick={() => clearUser()}>
													<Logout />
												</IconButton>
											</Tooltip>
										</Stack>
									</>
								</Stack>
							</CardContent>
						</Card>
						</>
						}
						{ providerPage &&
						<>
						<Card sx={{mb:2, boxShadow: 3}}>
							<CardContent sx={{pt:"10px !important",pb:"10px !important"}}>
								<Grid container>
									<Grid item xs={11}>
										<Stack direction="row" spacing={1} >
											<Apartment color="primary" sx={{mt:"7px"}} fontSize="large"/>
											<Typography variant="h6" gutterBottom component="div" sx={{pt:"7px",fontWeight:"500"}}>
												{providerPage?.name} 
											</Typography>
										</Stack>	
										<Link
												component="button"
												variant="h6"
												underline="none"
												color="secondary"
												sx={{mt:"5px !important"}}
												onClick={handleMBOpen}
										>
												Ver ficha
										</Link>

									</Grid>
									<Grid item xs={1} >
										<Tooltip title="Descartar proveedor">
											<IconButton color="secondary" onClick={clearProvider} sx={{float:"right"}}>
												<Close />
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
								
							</CardContent>
						</Card>
						</>
						}

						{!client &&
							<Card sx={{mb:3,boxShadow: 3,backgroundColor:"rgb(0, 41, 132) !important"}}>
								<CardContent sx={{p:"25px !important"}}>
									<Stack direction="row" spacing={1}>
										<AccessTimeFilled color="light" fontSize="large"/>
										<Typography variant="h5" color="#eee" sx={{fontWeight:"700 !important"}}>
											Sigue los siguientes pasos y cotiza en minutos tu evento!
										</Typography>
									</Stack>
								</CardContent>
							</Card>

						}
						
						{/*
						<Typography variant="h4" gutterBottom component="div" color="primary">
							<Typography variant="h4" sx={{ fontWeight: "bold", pr: "10px"}} display="inline-block" color="secondary">
								{numberStepQuotation} 
							</Typography>
							{textStepQuotation} 
						</Typography>
					<LinearProgress variant="determinate" value={valStepQuotation} color="secondary" />*/}
					</Box>
					<Outlet context={{client:client,setClient:setClient,showLoader:showLoader,setShowLoader:setShowLoader,
									providerPage:providerPage,providerPageId:providerPageId,setProviderPageId:setProviderPageId}}/>
				</Grid>
			</Grid>
		</Container>
	);

};

export default PClientQuotation;