import useList from './useList';

/* Definicion de los campos para el formulario de datos bancarios */
import formInputs from './useProviderContact/inputs';

import actions from '../redux/action';
const removeProviderContacts = actions.deleteProviderContacts;
const pushProviderContacts = actions.pushProviderContacts;
const putProviderContacts = actions.putProviderContacts;

const useProviderContact = ({ ID }) => {
	const {
		createEntity: createProviderContacts,
		updateEntity: updateProviderContacts,
		deleteEntity: deleteProviderContacts,
	} = useList({
		statusFn: () => {},
		entitiesFn: () => {},
		totalFn: () => {},
		push: pushProviderContacts,
		put: putProviderContacts,
		remove: removeProviderContacts,
		ID,
	});

	return {
		createProviderContacts,
		updateProviderContacts,
		deleteProviderContacts,
		formInputs,
	};
};

export default useProviderContact;
