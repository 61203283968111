const inputs = [
	{
		type: 'text',
		label: 'Nombre',
		name: 'name',
		placeholder: 'Ej: ',
		required: true,
		autoFocus: true,
	},
	{
		type: 'text',
		label: 'Descripción',
		name: 'description',
		placeholder: 'Ej: ',
	},
	{
		type: 'number',
		label: 'Área',
		name: 'area',
		placeholder: 'Ej: ',
		required: true,
	},
	{
		type: 'select',
		label: 'Tipo de precio',
		name: 'price_type',
		options: [
			{ name: 'Hora', value: 'Hora' },
			{ name: 'Medio día', value: 'Medio día' },
			{ name: 'Día', value: 'Día' },
		],
	},
	{
		type: 'number',
		label: 'Precio cliente',
		name: 'price',
		placeholder: 'Ej: ',
		required: true,
	},
	{
		type: 'number',
		label: 'Precio S&E',
		name: 'price_provider',
		placeholder: 'Ej: ',
		required: true,
	},
	{
		type: 'number',
		label: 'Hora miníma',
		name: 'hours_minimum',
		placeholder: '',
		required: true,
	},
	{
		type: 'number',
		label: 'Asistentes minímos',
		name: 'attendees_minimum',
		placeholder: '',
		required: true,
	},
	{
		type: 'number',
		label: 'Asistentes máximos',
		name: 'attendees_maximum',
		placeholder: '',
		required: true,
	},
	{
		type: 'select',
		label: 'Estado',
		name: 'status',
		required: true,
		options: [
			{ name: 'Activo', value: 'active' },
			{ name: 'Inactivo', value: 'inactive' },
		],
	},
];

export default inputs;
