import { useSelector } from 'react-redux';
import { useState } from 'react';
import lodash from 'lodash';
import { Container, Grid, Typography, Divider } from '@mui/material';
import FormCreateAndUpdate from '../components/FormCreateAndUpdate';
import ConfigDataList from '../components/ConfigDataList';
import actions from '../redux/action';

const putGeneralData = actions.putGeneralData;
const pushGeneralData = actions.pushGeneralData;
const deleteGeneralData = actions.deleteGeneralData;

const endpoint = 'provider-types';
const nameKey = lodash.camelCase(endpoint);

const inputs = [
	{
		type: 'text',
		label: 'Nombre',
		name: 'name',
		placeholder: 'Nombre para identificar ...',
		required: true,
		autoFocus: true,
	},
	{
		type: 'select',
		label: 'Provee Espacios y Servicios',
		name: 'in_person',
		required: true,
		options: [
			{ name: 'Si', value: 'active' },
			{ name: 'No', value: 'inactive' },
		],
	},
	{
		type: 'select',
		label: 'Estado',
		name: 'status',
		required: true,
		options: [
			{ name: 'Activo', value: 'active' },
			{ name: 'Inactivo', value: 'inactive' },
		],
	},
	{
		type: 'text',
		label: 'Descripción',
		name: 'description',
		placeholder: 'Texto que describa el uso ...',
	},
];

const ConfigProviderType = () => {
	const [dataItem, setDataItem] = useState({
		name: '',
		description: '',
		status: '',
	});

	const data = useSelector(
		({ configurations }) =>
			configurations?.entity && configurations.entity[nameKey]
	);
	const status = useSelector(({ configurations }) => configurations?.status);

	const formProps = {
		validateFunction: () => {},
		inputs,
	};

	const changeItem = item => {
		setDataItem(null);
		const resetObject = {
			name: '',
			description: '',
			status: '',
		};
		setTimeout(() => setDataItem(item ? item : resetObject), 100);
	};

	return (
		<Container
			className="config-service-type-list"
			maxWidth="xl"
			sx={{ mb: 4 }}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" component="h1">
						Tipos de proveedor
					</Typography>
					<Typography variant="body2" component="p" color="secondary">
						Administrar el listado de opciones y el estatus de los
						mismos para que esten disponibles para su uso.
					</Typography>
					<Divider
						sx={{
							mt: 2,
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<FormCreateAndUpdate
						defaultValues={dataItem}
						changeItem={changeItem}
						endpoint={endpoint}
						pushFunction={pushGeneralData}
						putFunction={putGeneralData}
						formProps={formProps}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={8}>
					{data?.length > 0 && (
						<ConfigDataList
							status={status}
							list={{ data }}
							endpoint={endpoint}
							getDataItem={changeItem}
							deleteFunction={deleteGeneralData}
							title="Listado"
							subtitle="Tipos de proveedor cargados al sistema. Los item rojos representan los inactivos."
						/>
					)}
				</Grid>
			</Grid>
		</Container>
	);
};

export default ConfigProviderType;
