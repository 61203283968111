export const reduceReducer =
	(...reducers) =>
	(state, action) =>
		reducers.reduce((acc, el) => {
			return el(acc, action);
		}, state);

const initialFetching = {
	loading: 'idle',
	error: null,
};

export const makeFetchingReducer =
	actions =>
	(state = initialFetching, action) => {
		const succeded = { ...state, loading: 'succeded' };
		switch (action.type) {
			case actions[0]: {
				return { ...state, loading: 'pending' };
			}
			case actions[1]: {
				return succeded;
			}
			case actions[2]: {
				return {
					error: action.error.response.data,
					loading: 'rejected',
				};
			}
			case actions[3]: {
				return succeded;
			}
			case actions[4]: {
				return succeded;
			}
			case actions[5]: {
				return succeded;
			}
			default:
				return state;
		}
	};

export const makeSetReducer =
	actions =>
	(state = 'all', action) => {
		switch (action.type) {
			case actions[0]: {
				return action.payload;
			}
			default:
				return state;
		}
	};

/* mac es una funcion que retorna funciones que reciben cualquier cantidad de argumentas que con usados para armal el action osea {type='xxx', payload='yyy', error='zzzz'}, siendo payload y error pasados como argNames para crear el objeto y asignar el argumento que le corresponde */
/* Make Action Creator */
export const mac =
	(type, ...argNames) =>
	(...args) => {
		const action = { type };

		argNames.forEach(
			(arg, index) => (action[argNames[index]] = args[index])
		);

		return action;
	};

/* Make Async Type  */
export const mat = entity => [
	`${entity}/pending`,
	`${entity}/fulfilled`,
	`${entity}/error`,
	`${entity}/add`,
	`${entity}/remove`,
	`${entity}/update`,
];

export const asyncMac = asyncTypes => {
	return [
		mac(asyncTypes[0]),
		mac(asyncTypes[1], 'payload'),
		mac(asyncTypes[2], 'error'),
	];
};
