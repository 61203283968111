import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/* Imagenes de la vista */
import logo from '../assets/logo.png';
import AlertNotifications from '../components/AlertNotifications';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
	Box,
	Avatar,
	Container,
	TextField,
	Button,
	InputAdornment,
	IconButton,
} from '@mui/material';

import actions from '../redux/action';
const fetchUserLogin = actions.fetchUserLogin;
const menuOptionsSet = actions.menuOptionsSet;

const errorMessage = (error, input) => {
	if (error[input.name]?.type === 'required')
		return `${input.label} es requerido.`;
	return null;
};

const LoginForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const user = useSelector(({ user }) => user);
	const [typeField, setTypeField] = useState('password');

	const login = ({ identifier, password }) => {
		
		dispatch(fetchUserLogin({ identifier, password }));
	};

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			identifier: '',
			password: '',
		},
	});

	useEffect(() => {
		/* Si el estatus del usuario cambia se ejecuta la validacion */
		if (user?.status?.loading !== 'idle') {
			if (user?.status?.loading === 'succeded') {
				/* Si el usuario es paciente lo saca. */
				if (user.entity.role.type === 'customer') {
					alert('No puedes ingresar a este sistema.');
					window.localStorage.clear();
					navigate('/admin');
				} else {
					dispatch(menuOptionsSet({ name: 'Dashboard' }));
					navigate('/admin');
				}
			}
		}
	}, [user, navigate, dispatch]);

	const showHidePassword = () => {
		typeField === 'password'
			? setTypeField('text')
			: setTypeField('password');
	};

	return (
		<Container component="main" maxWidth="xs">
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Avatar
					id="form-login-logo"
					sx={{ width: 300, height: 'auto' }}
					variant="square"
					alt="Logo de Ser Familia"
					src={logo}
				/>

				<form className="form-login" onSubmit={handleSubmit(login)}>
					<Controller
						control={control}
						rules={{ required: true }}
						name="identifier"
						render={({ field }) => (
							<TextField
								autoFocus
								fullWidth
								margin="normal"
								label="Correo electronico"
								placeholder="ejemplo@correo.com"
								id="email"
								type="email"
								error={errors['identifier'] ? true : false}
								helperText={errorMessage(errors, {
									label: 'Correo electronico',
									name: 'identifier',
								})}
								{...field}
							/>
						)}
					/>

					<Controller
						control={control}
						rules={{ required: true }}
						name="password"
						render={({ field }) => (
							<TextField
								margin="normal"
								fullWidth
								id="password"
								label="Password"
								type={typeField}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={showHidePassword}
												onMouseDown={showHidePassword}
												edge="end"
											>
												{typeField === 'text' ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
								error={errors['password'] ? true : false}
								helperText={errorMessage(errors, {
									label: 'Password',
									name: 'password',
								})}
								{...field}
							/>
						)}
					/>

					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						Iniciar sesión
					</Button>
				</form>
			</Box>
			{user?.status?.loading === 'rejected' && (
				<AlertNotifications message={user.status.error.data} />
			)}
		</Container>
	);
};

export default LoginForm;
